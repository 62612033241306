// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CalibrationRegcal02SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CalibrationRegcal02SvgIcon(
  props: CalibrationRegcal02SvgIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 94.037 85.221"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g opacity={".7"}>
        <path
          d={
            "M85.221 58.773H8.816a2.942 2.942 0 00-2.939 2.938v14.693a2.942 2.942 0 002.939 2.938h76.405a2.942 2.942 0 002.939-2.938V61.712a2.942 2.942 0 00-2.939-2.939zM61.712 73.467a4.408 4.408 0 110-8.816 4.408 4.408 0 010 8.816zm11.755 0a4.409 4.409 0 11.001-8.817 4.409 4.409 0 01-.001 8.817zm11.754-41.142H8.816a2.942 2.942 0 00-2.939 2.938v14.693a2.942 2.942 0 002.939 2.938h76.405a2.942 2.942 0 002.939-2.938V35.264a2.942 2.942 0 00-2.939-2.939zM61.712 47.019a4.408 4.408 0 110-8.816 4.408 4.408 0 010 8.816zm11.755 0a4.409 4.409 0 11.001-8.817 4.409 4.409 0 01-.001 8.817zM85.221 5.878H8.816a2.942 2.942 0 00-2.939 2.938v14.693a2.942 2.942 0 002.939 2.938h76.405a2.942 2.942 0 002.939-2.938V8.816a2.942 2.942 0 00-2.939-2.938zM61.712 20.571a4.408 4.408 0 110-8.816 4.408 4.408 0 010 8.816zm11.755 0a4.409 4.409 0 11.001-8.817 4.409 4.409 0 01-.001 8.817z"
          }
        ></path>

        <path
          d={
            "M94.037 23.509a8.78 8.78 0 01-2.247 5.877 8.785 8.785 0 012.247 5.877v14.693a8.78 8.78 0 01-2.247 5.877 8.785 8.785 0 012.247 5.877v14.693a8.816 8.816 0 01-8.816 8.816H8.816A8.813 8.813 0 010 76.405V61.712a8.78 8.78 0 012.247-5.877A8.78 8.78 0 010 49.957V35.264a8.78 8.78 0 012.247-5.877A8.78 8.78 0 010 23.509V8.816A8.816 8.816 0 018.816 0h76.405a8.816 8.816 0 018.816 8.816v14.693zm-88.16 0a2.942 2.942 0 002.939 2.939h76.405a2.942 2.942 0 002.939-2.939V8.816a2.942 2.942 0 00-2.939-2.939H8.816a2.942 2.942 0 00-2.939 2.939v14.693zm79.344 8.816H8.816a2.942 2.942 0 00-2.939 2.939v14.693a2.942 2.942 0 002.939 2.939h76.405a2.942 2.942 0 002.939-2.939V35.264a2.942 2.942 0 00-2.939-2.939zm2.939 29.387a2.942 2.942 0 00-2.939-2.939H8.816a2.942 2.942 0 00-2.939 2.939v14.693a2.942 2.942 0 002.939 2.939h76.405a2.942 2.942 0 002.939-2.939V61.712z"
          }
          opacity={".5"}
        ></path>
      </g>
    </svg>
  );
}

export default CalibrationRegcal02SvgIcon;
/* prettier-ignore-end */
