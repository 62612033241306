// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LinkExternalsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LinkExternalsvgIcon(props: LinkExternalsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 14 14.001"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M13 1.707L7.354 7.353a.5.5 0 01-.707-.707L12.293 1H8.5a.5.5 0 010-1h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V1.707zM4 2H1.499C.671 2 0 2.669 0 3.496v9.009c0 .825.679 1.496 1.502 1.496h8.995a1.5 1.5 0 001.502-1.498v-2.502a.5.5 0 00-1 0v2.498a.5.5 0 01-.498.502H1.502A.504.504 0 011 12.505V3.496C1 3.222 1.223 3 1.499 3H4a.5.5 0 000-1z"
        }
      ></path>
    </svg>
  );
}

export default LinkExternalsvgIcon;
/* prettier-ignore-end */
