import InputField from 'components/InputField'
import { modalStyle } from 'components/Modal/Modal'
import ModalEditBox from 'components/ModalEditBox'
import React from 'react'
import ReactModal from 'react-modal'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/tools'
import { IEditBoxData } from 'modules/packaging/Packaging'
import { useIntl } from 'react-intl'


interface IEditBoxModalProps {
  isOpen?: boolean
  onClose: () => void
  editBoxData: IEditBoxData
  setEditBoxData: (data: IEditBoxData) => void
}

export default function EditBoxModal({isOpen = false, onClose, editBoxData, setEditBoxData}: IEditBoxModalProps) {
  const intl = useIntl()
  const t = intl.formatMessage
  
  const [patchBox] = GQL.usePatchBox({
    notifyOnNetworkStatusChange: true,
    refetchQueries: ['AllBoxes'],
    onError: (err: any) => {
      console.log(err)
      displayToast(t({ id: 'common.error_editing_box_data' }))
    },
    onCompleted: ({ patchBox }: any) => {
      if (patchBox === null) {
        displayToast(t({ id: 'common.denied_try_again' }), 'success', { toastId: null, autoClose: false })
        return
      }
      const { ok, error } = patchBox
      if (!ok) {
        error ? displayToast(error) : displayToast(t({ id: 'common.server_error' }))
        return
      }
      onClose()
      displayToast(t({ id: 'common.box_edited_successfully' }), 'success')
    },
  })



  return (
    <ReactModal isOpen={isOpen} onRequestClose={onClose} style={modalStyle()}>
      <ModalEditBox
        closeModalCross={{ onClick: () => onClose() }}
        closeModal={{ onClick: () => onClose() }}
        closeModalText={t({ id: 'common.cancel' })}
        boxId={editBoxData.boxName}
        sensorIDs={editBoxData.sensors?.map((sensor: string, i: number) => (
          <InputField
            green={sensor ? true : false}
            sensorId
            value={sensor}
            onChange={(e: any) => {
              const temp = [...editBoxData.sensors]
              temp[i] = e.target.value
              setEditBoxData({ ...editBoxData, sensors: temp })
            }}
          />
        ))}
        btnSave={{
          title: t({ id: 'common.save' }),
          onClick: () => {
            if (editBoxData.boxId) {
              patchBox({
                variables: {
                  boxId: editBoxData.boxId,
                  input: {
                    sensors: editBoxData.sensors
                      .filter((e: any) => e !== '')
                      .map((sensor: any) => {
                        return { id: sensor }
                      }),
                  },
                },
              })
            }
          },
        }}
      />
    </ReactModal>
  )
}
