import ModalSensorInfo from 'components/ModalSensorInfo'
import StatusRow from 'components/StatusRow'
import { format } from 'date-fns'
import React from 'react'
import * as GQL from 'generated/graphql'
import InfoRow from 'components/InfoRow'
import ResetSensor from 'components/ResetSensor'
import WreckSensor from 'components/WreckSensor'
import { displayToast } from 'util/tools'
import { useLocation, useNavigate } from 'react-router-dom'
import Loader from 'components/Loader/Loader'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { locale } from 'locale/locale'

const StatusStyled = styled.div<{ $color: string }>`
  background-color: ${props => props.$color};
  padding: 1px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`
const CheckboxChildren = styled.div`
  font-size: 13px;
  color: #ffffffb3;
`
const HideSensorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  gap: 20px;
`
const HideSensorButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  gap: 20px;
`
const TextInput = styled.textarea<{readOnly?: boolean}>`
  border: 2px solid rgb(152, 154, 165);
  border-radius: 10px;
  padding: 5px 10px;
  width: 80%;
  height: 40px;
  background-color: ${props => props.readOnly ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 0.9)'};
`
const HideButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(152, 154, 165);
  background-color: rgba(152, 154, 165, 0.486);
  border-radius: 10px;
  width: 150px;
  height: 35px;
  color: white;
  cursor: pointer;
`

export default function SensorModalInside({ sensor }: { sensor: GQL.SensorNode }) {
  const navigate = useNavigate()
  const intl = useIntl()
  const t = intl.formatMessage
  const location = useLocation()

  const [sensorStatusBar, setSensorStatusBar] = React.useState(false)
  const [infoBar, setInfoBar] = React.useState(false)
  const [resetSensorBar, setResetSensorBar] = React.useState(true)
  const [hideSensor, setHideSensor] = React.useState(true)
  const [wreckSensorBar, setWreckSensorBar] = React.useState(true)
  const [wreckComment, setWreckComment] = React.useState<{ other: boolean; text: string }>({ other: false, text: '' })
  const [hideSensorValue, setHideSensorValue] = React.useState<string>(sensor.flags?.find(e => e?.flag === 'HIDDEN')?.comment || '')
  const [wreckCategory, setWreckCategory] = React.useState<string[]>([])
  const [confirmWreck, setConfirmWreck] = React.useState(false)

  const [createSensorFlag, { loading: createSensorFlagLoading }] = GQL.useCreateSensorFlag({
    notifyOnNetworkStatusChange: true,
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify({ __typename: 'SensorNode', id: sensor.id }),
        fields: {
          flags(existing, { readField }) {
            if (existing.some((ref: any) => readField('id', ref) === data?.createSensorFlag?.sensorFlag?.id)) {
              console.log(existing)
              return [...existing]
            }
            return [...existing, data?.createSensorFlag?.sensorFlag]
          },
        },
      })
    },
  })
  const [deleteSensorFlag] = GQL.useDeleteSensorFlag({
    notifyOnNetworkStatusChange: true,
    update: (cache, { data }, { variables }) => {
      cache.modify({
        id: cache.identify({ __typename: 'SensorNode', id: sensor.id }),
        fields: {
          flags(existing, { readField }) {
            if (existing.some((ref: any) => readField('flag', ref) === variables?.flag)) {
              return [...existing.filter((ref: any) => readField('flag', ref) !== variables?.flag)]
            }
            return [...existing]
          },
        },
      })
    },
  })

  return (
    <ModalSensorInfo
      closeModal={{ onClick: () => navigate(location.pathname) }}
      key={sensor.imei}
      sensorId={{ sensor: sensor }}
      headerFreeSpace={
        <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', paddingLeft: '25px' }}>
          {sensor.flags?.some(e => e?.flag === 'HIDDEN') && 'this sensor has hidden flag'}
        </div>
      }
      sensorStatus={{
        closed: sensorStatusBar,
        title: <FormattedMessage id='sensor_modal.sensor_status' />,
        titleBar: { onClick: () => setSensorStatusBar(!sensorStatusBar) },
        content: (
          <>
            <StatusRow
              title={
                <div>
                  <FormattedMessage id='sensor_status.lasered' />
                </div>
              }
              date={
                sensor.flags?.some(e => e?.flag === 'LASERED')
                  ? format(new Date(sensor.flags?.find(e => e?.flag === 'LASERED')?.createdAt), 'dd MMM yyyy', { locale: locale })
                  : null
              }
              time={
                sensor.flags?.some(e => e?.flag === 'LASERED')
                  ? format(new Date(sensor.flags?.find(e => e?.flag === 'LASERED')?.createdAt), 'HH:mm', { locale: locale })
                  : null
              }
              icon={sensor.flags?.some(e => e?.flag === 'LASERED') ? undefined : false}
            />
            <StatusRow
              title={
                <div>
                  <FormattedMessage id='sensor_status.calibrating' />
                </div>
              }
              date={
                sensor.flags?.some(e => e?.flag === 'CALIBRATION_STARTED')
                  ? format(new Date(sensor.flags?.find(e => e?.flag === 'CALIBRATION_STARTED')?.createdAt), 'dd MMM yyyy', { locale: locale })
                  : null
              }
              time={
                sensor.flags?.some(e => e?.flag === 'CALIBRATION_STARTED')
                  ? format(new Date(sensor.flags?.find(e => e?.flag === 'CALIBRATION_STARTED')?.createdAt), 'HH:mm')
                  : null
              }
              icon={sensor.flags?.some(e => e?.flag === 'CALIBRATION_STARTED') ? undefined : false}
            />
            <StatusRow
              title={
                <div>
                  <FormattedMessage id='sensor_status.calibrated' />
                </div>
              }
              date={
                sensor.flags?.some(e => e?.flag === 'CALIBRATED')
                  ? format(new Date(sensor.flags?.find(e => e?.flag === 'CALIBRATED')?.createdAt), 'dd MMM yyyy', { locale: locale })
                  : null
              }
              time={
                sensor.flags?.some(e => e?.flag === 'CALIBRATED')
                  ? format(new Date(sensor.flags?.find(e => e?.flag === 'CALIBRATED')?.createdAt), 'HH:mm', { locale: locale })
                  : null
              }
              icon={sensor.flags?.some(e => e?.flag === 'CALIBRATED') ? undefined : false}
            />
            <StatusRow
              title={
                <div>
                  <FormattedMessage id='sensor_status.final_test_done' />
                </div>
              }
              date={
                sensor.flags?.some(e => e?.flag === 'END_TEST_SUCCEEDED')
                  ? format(new Date(sensor.flags?.find(e => e?.flag === 'END_TEST_SUCCEEDED')?.createdAt), 'dd MMM yyyy', { locale: locale })
                  : null
              }
              time={
                sensor.flags?.some(e => e?.flag === 'END_TEST_SUCCEEDED')
                  ? format(new Date(sensor.flags?.find(e => e?.flag === 'END_TEST_SUCCEEDED')?.createdAt), 'HH:mm')
                  : null
              }
              icon={sensor.flags?.some(e => e?.flag === 'END_TEST_SUCCEEDED') ? undefined : false}
            />
            {sensor.flags?.some(e => e?.flag === 'WRECKED') && (
              <StatusRow
                title={
                  <div>
                    <FormattedMessage id='sensor_status.wrecked' />
                  </div>
                }
                date={format(new Date(sensor.flags?.find(e => e?.flag === 'WRECKED')?.createdAt), 'dd MMM yyyy', { locale: locale })}
                time={format(new Date(sensor.flags?.find(e => e?.flag === 'WRECKED')?.createdAt), 'HH:mm', { locale: locale })}
                icon={undefined}
              />
            )}
          </>
        ),
      }}
      info={{
        closed: infoBar,
        titleBar: { onClick: () => setInfoBar(!infoBar) },
        title: <FormattedMessage id='sensor_modal.info' />,
        content: (
          <>
            <InfoRow
              title={
                <div>
                  <FormattedMessage id='sensor_modal.production_status' />
                </div>
              }
              value={
                sensor.flags?.some(e => e?.flag === 'WRECKED') ? (
                  <StatusStyled style={{ display: 'inline' }} $color='red'>
                    <FormattedMessage id='sensor_status.wrecked' />
                  </StatusStyled>
                ) : sensor.flags?.some(e => e?.flag === 'END_TEST_SUCCEEDED') ? (
                  <StatusStyled $color='green'>
                    <FormattedMessage id='sensor_status.ready' />
                  </StatusStyled>
                ) : (
                  <StatusStyled $color='orange'>
                    <FormattedMessage id='sensor_status.in_production' />
                  </StatusStyled>
                )
              }
            />
            {sensor.flags?.some(e => e?.flag === 'WRECKED' && e.comment) && (
              <InfoRow
                title={
                  <div>
                    <FormattedMessage id='sensor_modal.wrecked_comment' />
                  </div>
                }
                value={sensor.flags?.find(e => e?.flag === 'WRECKED')?.comment}
              />
            )}
            <InfoRow title='IMEI' value={sensor.imei} />
            <InfoRow
              title={
                <div>
                  <FormattedMessage id='sensor_modal.calibration_value' />
                </div>
              }
              value={sensor.calibrationValue || t({ id: 'common.no_value' })}
            />
            <InfoRow title={t({ id: 'sensor_modal.calibration_zero' })} value={sensor.calibrationZero || t({ id: 'common.no_value' })} />
            <InfoRow title='Model' value={sensor.model?.name || t({ id: 'common.unknown' })} />
            <InfoRow
              title={t({ id: 'sensor_modal.production_date' })}
              value={format(new Date(sensor.createdAt), 'dd MMM yyyy, HH:mm', { locale: locale }) || t({ id: 'common.unknown' })}
            />
            {sensor.distributor?.fullName && <InfoRow title={t({ id: 'common.distributor' })} value={sensor.distributor?.fullName} />}
            {sensor.cgSide?.id && <InfoRow title='Customer' value={sensor.cgSide.cylinderGroup?.customer.name} />}
          </>
        ),
      }}
      resetSensorBar={{
        closed: resetSensorBar,
        titleBar: { onClick: () => setResetSensorBar(!resetSensorBar) },
        content: <ResetSensor />,
      }}
      hideSensor={{
        closed: hideSensor,
        title: t({ id: 'common.hide_sensor' }),
        titleBar: { onClick: () => setHideSensor(!hideSensor) },
        content: (
          <HideSensorWrapper>
            <TextInput readOnly={sensor.flags?.some(e => e?.flag === 'HIDDEN')} value={hideSensorValue} onChange={e => setHideSensorValue(e.target.value)} />
            <HideSensorButtonsWrapper>
              {sensor.flags?.some(e => e?.flag === 'HIDDEN') ? (
                <HideButton
                  onClick={() =>
                    deleteSensorFlag({
                      variables: {
                        flag: 'HIDDEN' as GQL.SensorFlagEnum,
                        serialNumber: sensor.serialNumber,
                      },
                      onCompleted: () => {
                        setHideSensorValue('')
                        displayToast('HIDDEN flag has been removed from sensor', 'success')
                      },
                    })
                  }
                >
                  {t({ id: 'common.unhide_sensor' })}
                </HideButton>
              ) : (
                <HideButton
                  onClick={() =>
                    createSensorFlag({
                      variables: {
                        flag: 'HIDDEN' as GQL.SensorFlagEnum,
                        comment: hideSensorValue && hideSensorValue.length > 0 ? hideSensorValue : undefined,
                        serialNumber: sensor.serialNumber,
                      },
                      onCompleted: () => {
                        displayToast('Sensor marked hidden successfully', 'success')
                      },
                    })
                  }
                >
                  {t({ id: 'common.hide_sensor' })}
                </HideButton>
              )}
            </HideSensorButtonsWrapper>
          </HideSensorWrapper>
        ),
      }}
      // uploadFilesBar={{
      //   closed: uploadFilesBar,
      //   titleBar: { onClick: () => setUploadFilesBar(!uploadFilesBar) },
      //   content: <UploadFiles />,
      // }}
      wreckSensorBar={{
        closed: wreckSensorBar,
        titleBar: {
          onClick: () => setWreckSensorBar(!wreckSensorBar),
        },
        title: t({ id: 'sensor_modal.wreck_sensor' }),
        content: (
          <WreckSensor
            label={t({ id: 'sensor_modal.choose_category' }) + ':'}
            checkLaser={{
              children: <CheckboxChildren>{t({ id: 'sensor_modal.laser_markings' })}</CheckboxChildren>,
              onChange: (e: any) => {
                if (e) {
                  setWreckCategory(old => [...old, t({ id: 'sensor_modal.laser_markings' })])
                } else {
                  setWreckCategory(old => old.filter(e => e !== t({ id: 'sensor_modal.laser_markings' })))
                }
              },
              isChecked: wreckCategory.some(e => e === t({ id: 'sensor_modal.laser_markings' })),
            }}
            checkDamaged={{
              children: <CheckboxChildren>{t({ id: 'sensor_modal.damaged_bottom' })}</CheckboxChildren>,
              onChange: (e: any) => {
                if (e) {
                  setWreckCategory(old => [...old, t({ id: 'sensor_modal.damaged_bottom' })])
                } else {
                  setWreckCategory(old => old.filter(e => e !== t({ id: 'sensor_modal.damaged_bottom' })))
                }
              },
              isChecked: wreckCategory.some(e => e === t({ id: 'sensor_modal.damaged_bottom' })),
            }}
            checkPotting={{
              children: <CheckboxChildren>{t({ id: 'sensor_modal.potting_failure' })}</CheckboxChildren>,

              onChange: (e: any) => {
                if (e) {
                  setWreckCategory(old => [...old, t({ id: 'sensor_modal.potting_failure' })])
                } else {
                  setWreckCategory(old => old.filter(e => e !== t({ id: 'sensor_modal.potting_failure' })))
                }
              },
              isChecked: wreckCategory.some(e => e === t({ id: 'sensor_modal.potting_failure' })),
            }}
            checkCalibration={{
              children: <CheckboxChildren>{t({ id: 'sensor_modal.calibration_failure' })}</CheckboxChildren>,

              onChange: (e: any) => {
                if (e) {
                  setWreckCategory(old => [...old, t({ id: 'sensor_modal.calibration_failure' })])
                } else {
                  setWreckCategory(old => old.filter(e => e !== t({ id: 'sensor_modal.calibration_failure' })))
                }
              },
              isChecked: wreckCategory.some(e => e === t({ id: 'sensor_modal.calibration_failure' })),
            }}
            checkAssembly={{
              children: <CheckboxChildren>{t({ id: 'sensor_modal.damaged_during_assembly' })}</CheckboxChildren>,

              onChange: (e: any) => {
                if (e) {
                  setWreckCategory(old => [...old, t({ id: 'sensor_modal.damaged_during_assembly' })])
                } else {
                  setWreckCategory(old => old.filter(e => e !== t({ id: 'sensor_modal.damaged_during_assembly' })))
                }
              },
              isChecked: wreckCategory.some(e => e === t({ id: 'sensor_modal.damaged_during_assembly' })),
            }}
            checkEsthetics={{
              children: <CheckboxChildren>{t({ id: 'sensor_modal.esthetics' })}</CheckboxChildren>,

              onChange: (e: any) => {
                if (e) {
                  setWreckCategory(old => [...old, t({ id: 'sensor_modal.esthetics' })])
                } else {
                  setWreckCategory(old => old.filter(e => e !== t({ id: 'sensor_modal.esthetics' })))
                }
              },
              isChecked: wreckCategory.some(e => e === t({ id: 'sensor_modal.esthetics' })),
            }}
            checkOther={{
              children: <CheckboxChildren>{t({ id: 'sensor_modal.other' })}</CheckboxChildren>,
              onChange: (e: any) => {
                if (e) {
                  setWreckComment(old => {
                    return { ...old, other: true }
                  })
                } else {
                  setWreckComment({ other: false, text: '' })
                }
              },
              isChecked: wreckComment.other,
            }}
            textComment={{
              placeholder: t({ id: 'sensor_modal.comment' }),
              value: wreckComment.text,
              disabled: !wreckComment.other,
              onChange: (e: any) => setWreckComment(old => ({ ...old, text: e.target.value })),
            }}
            confirmCheckbox={{
              onChange: (e: any) => {
                setConfirmWreck(e)
              },
              isChecked: confirmWreck,
              text: 'das',
            }}
            confirmText={t({ id: 'sensor_modal.wreck_confirm_text' })}
            btnWreckSensor={{
              color: !confirmWreck ? 'disabled' : 'red',
              title: createSensorFlagLoading ? <Loader size={25} color='white' opacity={1} /> : t({ id: 'common.wreck_sensor' }),
              onClick: () => {
                if (!createSensorFlagLoading && confirmWreck && sensor) {
                  if (wreckComment.other && wreckComment.text === '') {
                    displayToast('You have selected "other" reason, but you have not provided any comment', 'error')
                    return
                  }
                  if (wreckCategory.length === 0 && !wreckComment.other) {
                    displayToast('You have not selected any reason', 'error')
                    return
                  }

                  createSensorFlag({
                    variables: {
                      flag: 'WRECKED' as GQL.SensorFlagEnum,
                      comment: wreckCategory.join(', ') + (wreckComment.other ? ', Other: ' + wreckComment.text : ''),
                      serialNumber: sensor.serialNumber,
                    },
                    onCompleted: () => {
                      displayToast('Sensor has been marked as WRECKED successfully', 'success')
                      setWreckSensorBar(true)
                      setWreckCategory([])
                      setConfirmWreck(false)
                      setWreckComment({ other: false, text: '' })
                      // myRef.current?.scrollIntoView({ behavior: 'smooth' })
                    },
                  })
                }
              },
            }}
          />
        ),
      }}
    />
  )
}
