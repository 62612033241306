import * as React from 'react';
import {
  PlasmicSelect,
  DefaultSelectProps,
} from './plasmic/reg_cal/PlasmicSelect';
import {SelectRef} from '@plasmicapp/react-web';
import Option from './Select__Option';
import OptionGroup from './Select__OptionGroup';

interface SelectProps extends DefaultSelectProps {
  props?: any
}

function Select_(props: SelectProps, ref: SelectRef) {
  const {plasmicProps} = PlasmicSelect.useBehavior(props, ref);
  return <PlasmicSelect {...plasmicProps} />;
}

const Select = React.forwardRef(Select_);

export default Object.assign(Select, {
  Option,
  OptionGroup,
  __plumeType: 'select',
});
