// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicNavbar, DefaultNavbarProps } from './plasmic/reg_cal/PlasmicNavbar'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface NavbarProps extends Omit<DefaultNavbarProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultNavbarProps altogether and have
// total control over the props for your component.
interface NavbarProps extends DefaultNavbarProps {}

function Navbar_(props: NavbarProps, ref: HTMLElementRefOf<'nav'>) {
  const location = useLocation()
  const navigate = useNavigate()
  let currentPage = 'sensorStatus'

  switch (location.pathname.split('/')[1]) {
    case 'sensor-status':
      currentPage = 'sensorStatus'
      break
    case 'all-sensors':
      currentPage = 'allSensors'
      break
    case 'packaging':
      currentPage = 'packaging'
      break
    case 'orders':
      currentPage = 'orders'
      break
    case 'inventory':
      currentPage = 'inventory'
      break
    case 'customers':
      currentPage = 'customers'
      break
    case 'sensor-insight':
      currentPage = 'sensors'
      break
  }

  return (
    <PlasmicNavbar
      root={{ ref }}
      logo={{ onClick: () => navigate('/sensor-status') }}
      sensorStatus={{
        onClick: () => navigate('/sensor-status'),
        title: (
          <div>
            <FormattedMessage id='header.sensor_status' />
          </div>
        ),
      }}
      allSensors={{
        onClick: () => navigate('/all-sensors'),
        title: (
          <div>
            <FormattedMessage id='header.all_sensors' />
          </div>
        ),
      }}
      packaging={{
        onClick: () => navigate('/packaging'),
        title: (
          <div>
            <FormattedMessage id='header.packaging' />
          </div>
        ),
      }}
      orders={{
        onClick: () => navigate('/orders'),
        title: (
          <div>
            <FormattedMessage id='common.orders' />
          </div>
        ),
      }}
      inventory={{
        onClick: () => navigate('/inventory'),
        title: (
          <div>
            <FormattedMessage id='header.inventory' />
          </div>
        ),
      }}
      customers={{
        onClick: () => navigate('/customers'),
        title: (
          <div>
            <FormattedMessage id='common.customers' />
          </div>
        ),
      }}
      sensorInsight={{
        onClick: () => navigate('/sensor-insight'),
        title: (
          <div>
            <FormattedMessage id='header.sensor_insight' />
          </div>
        ),
      }}
      currentPage={currentPage as 'sensorStatus' | 'packaging' | 'orders' | 'inventory' | 'customers' | 'sensors' | 'allSensors'}
      {...props}
    />
  )
}

const Navbar = React.forwardRef(Navbar_)
export default Navbar
