import React, { useEffect, useState } from 'react'
import * as GQL from 'generated/graphql'
import PlasmicSensors from 'components/SensorInsight'
import TableRow from 'components/TableRow'
import CellSensor from 'components/CellSensor'
import FullLoader from 'components/Loader/FullLoader'
import CellHealth from 'components/CellHealth'
import CellCustomer from 'components/CellCustomer'
import CellBoxId from 'components/CellBoxId'
import CellModel from 'components/CellModel'
import CellStatus from 'components/CellStatus'
import CellLocation from 'components/CellLocation'
import CellActions from 'components/CellActions'
import { displayToast, modelFlag } from 'util/tools'
import CellSelectRow from 'components/CellSelectRow'
import Checkbox from 'components/Checkbox'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

export function SensorInsight() {
  const [search, setSearch] = useState('')
  const [sensors, setSensors] = useState<GQL.SensorNode[]>([])
  const [filteredSensors, setFilteredSensors] = useState<GQL.SensorNode[]>([])

  const [pagination, setPagination] = useState({
    itemsPerPage: 25,
    currentPage: 1,
    maxPages: 1,
  })
  const navigate = useNavigate()

  const intl = useIntl()
  const t = intl.formatMessage

  const { loading } = GQL.useAllSensors({
    fetchPolicy: 'cache-and-network',
    variables: {
      orderBy: '-createdAt',
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: response => {
      if (response.allSensors?.edges) {
        const fetchedSensors = response.allSensors.edges.map(sensor => sensor?.node as GQL.SensorNode)

        setSensors(fetchedSensors)
        setFilteredSensors(fetchedSensors)
        setPagination({ ...pagination, maxPages: Math.ceil(response.allSensors.edges.length / pagination.itemsPerPage) })
      }
    },
  })

  useEffect(() => {
    if (search) {
      setFilteredSensors(sensors.filter((sensor: GQL.SensorNode) => sensor.serialNumber.toLowerCase().includes(search.toLowerCase())))
      return
    }
    setFilteredSensors(sensors)
  }, [search, sensors])

  return (
    <PlasmicSensors
      searchBlock={{ notEmpty: !!search, clear: { onClick: () => setSearch('') } }}
      searchField={{
        value: search,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
        placeholder: t({ id: 'common.search' }),
      }}
      headCustomer={t({ id: 'common.customer' })}
      headLocation={t({ id: 'common.location' })}
      headProduction={t({ id: 'common.production' })}
      checkAll={{
        props: {
          'aria-label': 'Select all boxes',
          isChecked: false,
        },
      }}
      rows={
        <>
          {loading ? (
            <FullLoader color='white' />
          ) : (
            [
              filteredSensors
                .slice(pagination.currentPage * pagination.itemsPerPage - pagination.itemsPerPage, pagination.currentPage * pagination.itemsPerPage)
                .map((sensor: GQL.SensorNode) => (
                  <TableRow
                    key={sensor.id}
                    select={<CellSelectRow aria-label='select' selectRow={<Checkbox aria-label='checkbox' isChecked={false} children={null} />} />}
                    sensor={<CellSensor sensor={{ props: { sensor: sensor } }} />}
                    health={<CellHealth transmissionRate={sensor.latestTransmissionInterval}></CellHealth>}
                    customer={
                      <CellCustomer
                        onClick={() => {
                          if (sensor.distributor?.id) navigate(`/customers/${sensor.distributor?.id}`)
                        }}
                      >
                        {sensor.distributor?.fullName ?? 'not allocated'}
                      </CellCustomer>
                    }
                    boxId={
                      sensor.box?.name ? (
                        <CellBoxId color={sensor.box?.isShipped ? 'shipped' : sensor.box?.allocatedDate ? 'allocated' : undefined}>
                          {sensor.box?.name}
                        </CellBoxId>
                      ) : (
                        <CellBoxId blank />
                      )
                    }
                    model={<CellModel symbol={modelFlag(sensor.model?.name)}>{sensor.model?.name ?? 'no data'}</CellModel>}
                    status={<CellStatus></CellStatus>}
                    location={
                      <CellLocation
                        selectLocation={{
                          props: {
                            'aria-label': 'Select location',
                            onChange: (e: any) => console.log(e),
                            value: sensor.box?.isShipped ? 'value3' : sensor.box?.boxOrder?.id ? 'value1' : 'value2',
                          },
                        }}
                      />
                    }
                    // production={<CellProduction>{displayFormattedDate(new Date(sensor.createdAt))}</CellProduction>}
                    actions={<CellActions></CellActions>}
                    visibleColumns={['boxId', 'location', 'production', 'actions', 'customer', 'status', 'select', 'sensor', 'health', 'model']}
                  />
                )),
              <TableRow
                key='fgbdfr545'
                sensor={
                  <>
                    <CellSensor
                      sensor={{
                        props: {
                          color: pagination.currentPage > 1 ? 'green' : 'red',
                          id: `${t({ id: 'sensor_insight.previous' })}`,
                          onClick: () =>
                            pagination.currentPage > 1
                              ? setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })
                              : displayToast(t({ id: 'common.no_previous_page' })),
                        },
                      }}
                    />
                    <CellSensor
                      sensor={{
                        props: {
                          color:
                            filteredSensors.length > pagination.currentPage * pagination.maxPages && pagination.currentPage < pagination.maxPages
                              ? 'green'
                              : 'red',
                          id: `${t({ id: 'sensor_insight.next' })}`,
                          onClick: () =>
                            filteredSensors.length > pagination.currentPage * pagination.maxPages && pagination.currentPage < pagination.maxPages
                              ? setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })
                              : displayToast(t({ id: 'common.no_next_page' })),
                        },
                      }}
                    />
                  </>
                }
                status={`${t({ id: 'common.page' })} ${pagination.currentPage}`}
                visibleColumns={['sensor', 'status']}
              />,
            ]
          )}
        </>
      }
    />
  )
}

export default SensorInsight
