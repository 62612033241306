import * as React from 'react';
import {
  PlasmicCheckbox,
  DefaultCheckboxProps,
} from './plasmic/reg_cal/PlasmicCheckbox';
import {CheckboxRef} from '@plasmicapp/react-web';

interface CheckboxProps extends DefaultCheckboxProps {
  checkboxRow?: any
  onClick?: any
  noLabel?: any
}

function Checkbox_(props: CheckboxProps, ref: CheckboxRef) {
  const {plasmicProps} = PlasmicCheckbox.useBehavior<CheckboxProps>(
      props,
      ref,
  );
  return <PlasmicCheckbox {...plasmicProps}/>;
}

const Checkbox = React.forwardRef(Checkbox_);

export default Object.assign(Checkbox, {
  __plumeType: 'checkbox',
});
