import React from 'react'
import styled from 'styled-components'
import Loader from './Loader'

interface ICornerLoaderProps {
  position?: string
  size?: number
  text?: string
  topAdjust?: string
  color?: string
  opacity?: number
}

const CornerPosition = styled.div<ICornerLoaderProps>`
  position: ${props => props.position};
  display: flex;
  flex-direction:row ;
  font: 100 12px 'Lineto', sans-serif;
  align-items: center;
  gap: 5px ;
  justify-content: center;
  right: 12em;
  z-index: 9999;
  top: ${props => `calc(0em - ${props.topAdjust || "0px"})`};
`

const CornerLoader: React.FC<ICornerLoaderProps> = ({
  size,
  position,
  topAdjust,
  text,
  color,
  opacity,
  ...props
}) => {
  return (
    <CornerPosition position={position} topAdjust={topAdjust} >
      <span style={{color: color || 'white', opacity: opacity ? opacity :'0.5'}}>{text ? text : ''}</span><Loader size={size} color={color ? color : 'white'} opacity={opacity} {...props} />
    </CornerPosition>
  )
}

CornerLoader.defaultProps = {
  position: 'absolute',
  size: 65,
}

export default CornerLoader
