import React from 'react'

import * as GQL from 'generated/graphql'
import PlasmicCustomers from 'components/Customers'
import TableRow from 'components/TableRow'
import FullLoader from 'components/Loader/FullLoader'
import CellCustomer from 'components/CellCustomer'
import CellModel from 'components/CellModel'
import { displayFormatedDateTime, displayToast, modelFlag } from 'util/tools'
import CellBoxes from 'components/CellBoxes'
import CellCreated from 'components/CellCreated'
import { useNavigate } from 'react-router-dom'
import CornerLoader from 'components/Loader/CornerLoader'
import { useIntl } from 'react-intl'

export function Customers() {
  const navigate = useNavigate()
  const { data, loading } = GQL.useAllDistributors({ onError: err => displayToast(err.message) })

  const intl = useIntl()
  const t = intl.formatMessage

  const allDistributors = data?.allDistributors?.edges
    ?.map((distr: any) => {
      return distr.node
    })
    .sort((a, b) => (a.fullName?.toLowerCase() > b.fullName?.toLowerCase() ? 1 : b.fullName?.toLowerCase() > a.fullName?.toLowerCase() ? -1 : 0))

  const boxesGenerator = (delivered: number = 0, allocated: number) => {
    const temp = []
    for (let i = 0; i < allocated - delivered; i++) {
      temp.push(
        <div key={'style2' + i}>
          <span className='icon-box-font allocated' />
          <span className='icon-box-font allocated2' />
        </div>
      )
    }
    for (let i = 0; i < delivered; i++) {
      temp.push(
        <div key={'style1' + i}>
          <span className='icon-box-font solid' />
          <span className='icon-box-font solid2' />
        </div>
      )
    }
    return temp
  }

  return (
    <>
      {loading && <CornerLoader size={32} topAdjust='-15px' />}
      <PlasmicCustomers
        text={t({ id: 'common.customers' })}
        headCustomer={t({ id: 'common.customer' })}
        headBoxesDelivered={t({ id: 'customers.boxes_delivered' })}
        headCreated={t({ id: 'common.created_at' })}
        rows={
          <>
            {loading && !data ? (
              <FullLoader color='white' />
            ) : (
              allDistributors?.map((distributor, i) => (
                <TableRow
                  customer={<CellCustomer onClick={() => navigate(`/customers/${distributor.id}`)}>{distributor?.fullName}</CellCustomer>}
                  model={
                    <CellModel symbol={modelFlag(distributor?.boxOrders.edges.find((e: any) => true)?.node?.requestedModel?.name ?? undefined)}>
                      {distributor?.boxOrders.edges.find((e: any) => true)?.node?.requestedModel?.name ?? 'ND'}
                    </CellModel>
                  }
                  boxes={
                    <CellBoxes count={distributor?.boxOrders?.edges.reduce((acc: any, curr: any) => acc + curr.node?.boxesAllocated, 0) || '0'}>
                      {distributor?.boxOrders.edges.map((order: any) => {
                        return boxesGenerator(order?.node?.boxesDelivered, order?.node?.boxesAllocated)
                      })}
                    </CellBoxes>
                  }
                  created={<CellCreated>{displayFormatedDateTime(distributor?.createdAt || null)}</CellCreated>}
                  key={distributor?.id}
                  visibleColumns={['model', 'actions', 'boxes', 'created', 'customer']}
                />
              ))
            )}
          </>
        }
      />
    </>
  )
}

export default Customers
