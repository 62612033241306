// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */

import * as React from 'react'
import * as p from '@plasmicapp/react-web'

export type VisiblePanesValue = 'all' | 'factory'
export const VisiblePanesContext = React.createContext<VisiblePanesValue | undefined>('PLEASE_RENDER_INSIDE_PROVIDER' as any)

export function useVisiblePanes() {
  return React.useContext(VisiblePanesContext)
}

export default VisiblePanesContext
/* prettier-ignore-end */
