// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type AlertsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function AlertsvgIcon(props: AlertsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 20 18"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M19.515 15.848L11.013 1.122c-.557-.965-1.468-.965-2.025 0L.485 15.848c-.556.965-.101 1.755 1.014 1.755h17.003c1.114 0 1.57-.79 1.013-1.755zm-9.489-.889h-.013c-.547 0-.953-.407-.953-1.005 0-.57.406-.99.966-.99.559 0 .965.419.965.99 0 .598-.406 1.005-.965 1.005zm.888-7.129a93.116 93.116 0 00-.622 4.192c-.038.153-.559.166-.585 0a91.374 91.374 0 00-.636-4.192c-.076-.484-.113-.713-.113-.929 0-.305.216-.547 1.042-.547h.013c.788 0 1.03.242 1.03.547-.001.216-.052.445-.129.929z"
        }
      ></path>
    </svg>
  );
}

export default AlertsvgIcon;
/* prettier-ignore-end */
