import React, { useEffect } from 'react'
import { default as PlasmicAllSensors } from 'components/AllSensors'
import * as GQL from 'generated/graphql'
import TableRow from 'components/TableRow'
import CellSensor from 'components/CellSensor'
import CellBoxId from 'components/CellBoxId'
import CellTimestamp from 'components/CellTimestamp'
import { currentSensorStatus, displayFormatedDateTime, useDebounce } from 'util/tools'
import CornerLoader from 'components/Loader/CornerLoader'
import styled from 'styled-components'
import CellSensorStatus from 'components/CellSensorStatus'
import { useIntl } from 'react-intl'
import FilterToggle from 'components/FilterToggle'

const FetchMoreButton = styled.div<{ disabled: boolean }>`
  border: 3px solid rgb(152, 154, 165);
  padding: 0.5em;
  border-radius: 15px;
  background-color: rgb(152, 154, 165);
  cursor: pointer;
  user-select: none;
  ${({ disabled }) =>
    !disabled &&
    `
  :hover {
    filter: brightness(1.5);
  }
  :active {
    filter: brightness(0.5);
  }
  `}
  ${({ disabled }) =>
    disabled &&
    `
  filter: brightness(0.5);
  `}
`

export default function AllSensors() {
  const [search, setSearch] = React.useState('')
  const [filter, setFilter] = React.useState<string[]>([])
  const [filterBoxed, setFilterBoxed] = React.useState<true | undefined>(undefined)
  const [filterHidden, setFilterHidden] = React.useState<boolean>(false)

  const intl = useIntl()
  const t = intl.formatMessage

  const debouncedSearch = useDebounce(search)

  useEffect(() => {
    if (filter.length > 0) {
      setFilterHidden(false)
    }
  }, [filter])

  const { data, loading, fetchMore } = GQL.useAllSensors({
    pollInterval: 15000,
    fetchPolicy: 'cache-and-network',
    variables: {
      orderBy: '-createdAt',
      offset: 0,
      first: 50,
      q: debouncedSearch,
      flags: filterHidden ? ['HIDDEN'] : undefined,
      boxed: filterBoxed,
      currentFlag: filter.length < 1 ? undefined : filter,
    },
    notifyOnNetworkStatusChange: true,
  })
  const sensors = data?.allSensors?.edges || []

  return (
    <>
      {loading && <CornerLoader size={32} topAdjust='-15px' />}
      <PlasmicAllSensors
        text={t({ id: 'common.filter' })}
        emptyFilterElement={
          <FilterToggle
            model={t({ id: 'common.hidden' })}
            number={''}
            active={filterHidden}
            onClick={() => {
              setFilter([])
              setFilterHidden(!filterHidden)
            }}
          />
        }
        searchBlock={{ notEmpty: !!search, clear: { onClick: () => setSearch('') } }}
        searchField={{ value: search, onChange: (e: any) => setSearch(e.target.value), placeholder: t({ id: 'common.search' }) }}
        filterLasered={{
          model: t({ id: 'sensor_status.lasered_short' }),
          onClick: () => {
            if (filter.includes('LASERED')) {
              setFilter(filter.filter(e => e !== 'LASERED'))
            } else {
              setFilter([...filter, 'LASERED'])
            }
          },
          number: filter.includes('LASERED') ? data?.allSensors?.totalCount?.toString() : null,
          active: filter.includes('LASERED'),
        }}
        filterCalibrating={{
          model: t({ id: 'sensor_status.calibrating' }),
          onClick: () => {
            if (filter.includes('CALIBRATION_STARTED')) {
              setFilter(filter.filter(e => e !== 'CALIBRATION_STARTED'))
            } else {
              setFilter([...filter, 'CALIBRATION_STARTED'])
            }
          },
          number: filter.includes('CALIBRATION_STARTED') ? data?.allSensors?.totalCount?.toString() : null,
          active: filter.includes('CALIBRATION_STARTED'),
        }}
        filterCalibrationComplete={{
          model: t({ id: 'sensor_status.calibrated' }),
          onClick: () => {
            if (filter.includes('CALIBRATED')) {
              setFilter(filter.filter(e => e !== 'CALIBRATED'))
            } else {
              setFilter([...filter, 'CALIBRATED'])
            }
          },
          number: filter.includes('CALIBRATED') ? data?.allSensors?.totalCount?.toString() : null,
          active: filter.includes('CALIBRATED'),
        }}
        filterReadyToPack={{
          model: t({ id: 'sensor_status.final_test_done' }),
          onClick: () => {
            if (filter.includes('END_TEST_SUCCEEDED')) {
              setFilter(filter.filter(e => e !== 'END_TEST_SUCCEEDED'))
            } else {
              setFilter([...filter, 'END_TEST_SUCCEEDED'])
            }
          },
          number: filter.includes('END_TEST_SUCCEEDED') ? data?.allSensors?.totalCount?.toString() : null,
          active: filter.includes('END_TEST_SUCCEEDED'),
        }}
        filterPackaged={{
          model: t({ id: 'sensor_status.packaged' }),
          onClick: () => {
            if (filterBoxed) {
              setFilterBoxed(undefined)
            } else {
              setFilterBoxed(true)
            }
          },
          number: filterBoxed ? data?.allSensors?.totalCount?.toString() : null,
          active: filterBoxed || false,
        }}
        filterError={{
          model: t({ id: 'common.error' }),
          onClick: () => {
            if (filter.includes('WRECKED')) {
              setFilter(filter.filter(e => e !== 'WRECKED'))
            } else {
              setFilter([...filter, 'WRECKED'])
            }
          },
          number: filter.includes('WRECKED') ? data?.allSensors?.totalCount?.toString() : null,
          active: filter.includes('WRECKED'),
        }}
        laseredHeader={t({ id: 'sensor_status.lasered_short' })}
        calibratingHeader={t({ id: 'sensor_status.calibrating' })}
        calibratedHeader={t({ id: 'sensor_status.calibrated_short' })}
        finalTestDoneHeader={t({ id: 'sensor_status.final_test_done_short' })}
        packagedHeader={t({ id: 'sensor_status.packaged' })}
        boxIdHeader={t({ id: 'common.box_id' })}
        currentStatusHeader={t({ id: 'sensor_status.current_status' })}
        rows={
          sensors.length === 0 && !loading ? (
            <span style={{ width: 'auto', alignSelf: 'center', textAlign: 'center', padding: '20px', fontSize: '2rem' }}>{t({ id: 'common.no_data' })}</span>
          ) : (
            sensors.map(sensor => (
              <TableRow
                key={sensor?.node?.id + 'dastjgyhukft'}
                sensor={<CellSensor sensor={{ sensor: sensor?.node }} />}
                statusTimestamps={
                  <>
                    <CellTimestamp aria-label='lasered'>
                      {sensor?.node?.flags?.some(e => e?.flag === 'LASERED')
                        ? displayFormatedDateTime(new Date(sensor?.node?.flags?.find(e => e?.flag === 'LASERED')?.createdAt))
                        : null}
                    </CellTimestamp>
                    <CellTimestamp aria-label='Calibrating'>
                      {sensor?.node?.flags?.some(e => e?.flag === 'CALIBRATION_STARTED')
                        ? displayFormatedDateTime(new Date(sensor?.node?.flags?.find(e => e?.flag === 'CALIBRATION_STARTED')?.createdAt))
                        : null}
                    </CellTimestamp>
                    <CellTimestamp aria-label='Calibration Done'>
                      {sensor?.node?.flags?.some(e => e?.flag === 'CALIBRATED')
                        ? displayFormatedDateTime(new Date(sensor?.node?.flags?.find(e => e?.flag === 'CALIBRATED')?.createdAt))
                        : null}
                    </CellTimestamp>
                    <CellTimestamp aria-label='Ready to pack'>
                      {sensor?.node?.flags?.some(e => e?.flag === 'END_TEST_SUCCEEDED')
                        ? displayFormatedDateTime(new Date(sensor?.node?.flags?.find(e => e?.flag === 'END_TEST_SUCCEEDED')?.createdAt))
                        : null}
                    </CellTimestamp>
                    <CellTimestamp aria-label='Packaged'>
                      {sensor?.node?.box?.id ? displayFormatedDateTime(new Date(sensor?.node.box.createdAt)) : null}
                    </CellTimestamp>
                  </>
                }
                boxId={
                  sensor?.node?.box?.name ? (
                    <CellBoxId color={sensor.node.box?.isShipped ? 'shipped' : sensor.node.box?.allocatedDate ? 'allocated' : undefined}>
                      {sensor.node.box?.name}
                    </CellBoxId>
                  ) : (
                    <CellBoxId blank />
                  )
                }
                status={<CellSensorStatus statuses={currentSensorStatus(sensor?.node as GQL.SensorNode)} polish={localStorage.getItem('language') === 'pl'} />}
                visibleColumns={['boxId', 'actions', 'status', 'sensor', 'satusTimestamps']}
              />
            ))
          )
        }
        underTable={
          sensors.length > 0 && (
            <FetchMoreButton
              disabled={!data?.allSensors?.pageInfo?.hasNextPage || loading || false}
              onClick={() => {
                if (data?.allSensors?.pageInfo?.hasNextPage && !loading) {
                  fetchMore({
                    variables: { offset: data?.allSensors?.edges.length },
                    updateQuery: (preiovus, { fetchMoreResult }) => {
                      return {
                        ...fetchMoreResult,
                        allSensors: {
                          ...(fetchMoreResult.allSensors as GQL.SensorNodeConnection),
                          edges: [...(preiovus?.allSensors?.edges || []), ...(fetchMoreResult?.allSensors?.edges || [])],
                        },
                      }
                    },
                  })
                }
              }}
            >
              {t({ id: 'common.fetch_more' })} ({(data?.allSensors?.edges.length || '0') + ' / ' + (data?.allSensors?.totalCount || '0')})
            </FetchMoreButton>
          )
        }
      />
    </>
  )
}
