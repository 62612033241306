// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CloseIconsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CloseIconsvgIcon(props: CloseIconsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 20.182 20.182"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.505 10.091l8.384-8.384A.999.999 0 1018.475.293l-8.384 8.384L1.707.293A.999.999 0 10.293 1.707l8.384 8.384-8.384 8.384a.999.999 0 101.414 1.414l8.384-8.384 8.384 8.384a.997.997 0 001.414 0 .999.999 0 000-1.414l-8.384-8.384z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default CloseIconsvgIcon;
/* prettier-ignore-end */
