// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type HealthR01SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function HealthR01SvgIcon(props: HealthR01SvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24.587 19.738"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <circle cx={"12.294"} cy={"8.895"} r={"3.316"}></circle>

        <path
          d={
            "M12.294 19.738a.688.688 0 01-.688-.688V8.895a.688.688 0 111.376 0V19.05a.687.687 0 01-.688.688zm4.689-5.466a.687.687 0 01-.487-1.175c1.122-1.122 1.741-2.614 1.741-4.202s-.618-3.081-1.741-4.203a.687.687 0 11.973-.973 7.268 7.268 0 012.144 5.176 7.27 7.27 0 01-2.144 5.175.686.686 0 01-.486.202z"
          }
        ></path>

        <path
          d={
            "M18.742 16.031a.687.687 0 01-.487-1.175 8.378 8.378 0 002.469-5.961 8.378 8.378 0 00-2.469-5.962.687.687 0 11.973-.973C21.08 3.814 22.1 6.277 22.1 8.896s-1.02 5.081-2.872 6.934a.685.685 0 01-.486.201z"
          }
        ></path>

        <path
          d={
            "M20.5 17.789a.687.687 0 01-.487-1.175 10.847 10.847 0 003.198-7.719c0-2.917-1.136-5.659-3.198-7.72a.687.687 0 11.973-.973c2.323 2.323 3.601 5.41 3.601 8.694s-1.278 6.37-3.601 8.692a.685.685 0 01-.486.201zM7.605 14.272a.686.686 0 01-.487-.202 7.267 7.267 0 01-2.144-5.175c0-1.956.761-3.794 2.144-5.176a.687.687 0 11.973.973c-1.122 1.123-1.74 2.616-1.74 4.203s.618 3.079 1.741 4.202a.687.687 0 01-.487 1.175z"
          }
        ></path>

        <path
          d={
            "M5.845 16.031a.686.686 0 01-.487-.202c-1.852-1.854-2.872-4.316-2.872-6.934s1.02-5.082 2.872-6.936a.687.687 0 11.973.973 8.386 8.386 0 00-2.468 5.963c0 2.25.876 4.367 2.469 5.961a.687.687 0 01-.487 1.175z"
          }
        ></path>

        <path
          d={
            "M4.087 17.789a.686.686 0 01-.487-.202C1.278 15.264 0 12.177 0 8.895S1.278 2.525 3.601.201a.687.687 0 11.973.973 10.855 10.855 0 00-3.198 7.721 10.85 10.85 0 003.198 7.719.687.687 0 01-.487 1.175z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default HealthR01SvgIcon;
/* prettier-ignore-end */
