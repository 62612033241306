// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicNavbarSettings, DefaultNavbarSettingsProps } from './plasmic/reg_cal/PlasmicNavbarSettings'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { removeLoginToken } from 'util/auth'
import styled from 'styled-components'

const LanguageSwitch = styled.div`
  display: flex;
  gap: 0.5em;
  font-size: 0.8rem;
`
const Country = styled.div<{ active?: boolean }>`
  color: ${props => (props.active ? 'white' : 'rgba(255, 255, 255, 0.2)')};
  cursor: pointer;
`
interface NavbarSettingsProps extends DefaultNavbarSettingsProps {}

function NavbarSettings_(props: NavbarSettingsProps, ref: HTMLElementRefOf<'div'>) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const language = localStorage.getItem('language') || 'en'

  const actionRef: any = React.useRef<any>(null)

  React.useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isOpen && actionRef.current && !actionRef.current.contains(e.target)) {
        e.preventDefault()
        e.stopPropagation()
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpen])

  return (
    <PlasmicNavbarSettings
      root={{ ref }}
      {...props}
      open={isOpen}
      navMoreActions={{
        onClick: () => {
          setIsOpen(!isOpen)
        },
      }}
      dropdownAction={{
        ref: actionRef,
        onClick: () => {
          removeLoginToken()
          window.location.replace('/')
        },
      }}
      languageSwitch={
        <LanguageSwitch>
          <Country
            active={language === 'pl'}
            onClick={() => {
              localStorage.setItem('language', 'pl')
              window.location.reload()
            }}
          >
            PL
          </Country>
          <Country
            active={language === 'en'}
            onClick={() => {
              localStorage.setItem('language', 'en')
              window.location.reload()
            }}
          >
            EN
          </Country>
        </LanguageSwitch>
      }
    />
  )
}

const NavbarSettings = React.forwardRef(NavbarSettings_)
export default NavbarSettings
