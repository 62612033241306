// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CalibrationRegcal01SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CalibrationRegcal01SvgIcon(
  props: CalibrationRegcal01SvgIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 105.558 120.057"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g opacity={".6"}>
        <path
          d={
            "M0 19.36c0-.608.103-1.168.315-1.649a3.75 3.75 0 01.861-1.255c.364-.339.774-.6 1.247-.798a4.21 4.21 0 012.96 0c.475.19.893.459 1.255.798.356.348.64.758.853 1.255.214.481.323 1.041.323 1.649 0 .624-.109 1.168-.323 1.651a3.785 3.785 0 01-.853 1.247c-.362.348-.78.616-1.255.79a4.16 4.16 0 01-1.491.275c-.513 0-1.01-.095-1.469-.275a3.576 3.576 0 01-1.247-.79 3.706 3.706 0 01-.861-1.247C.103 20.527 0 19.984 0 19.36zm1.523-.008c0 .426.079.798.214 1.121.133.315.315.584.535.798.236.196.481.354.758.457.285.111.584.15.869.15a2.4 2.4 0 00.875-.15c.277-.103.537-.261.766-.457.222-.214.396-.483.537-.798.133-.323.212-.695.212-1.121 0-.432-.079-.804-.212-1.119-.141-.323-.315-.592-.537-.79a2.27 2.27 0 00-.766-.473 2.423 2.423 0 00-.875-.151c-.285 0-.584.04-.869.151a2.328 2.328 0 00-.758.473c-.22.198-.402.467-.535.79a2.811 2.811 0 00-.214 1.119zM9.1 23.164v-7.615h4.814v1.412h-3.329v1.871h2.958v1.372h-2.952v2.96zm6.037 0v-7.615h4.798v1.412h-3.33v1.871h2.976v1.372h-2.959v2.96z"
          }
        ></path>

        <path
          d={
            "M49.618 3.97c0-.608.103-1.168.323-1.649.212-.497.497-.909.861-1.255a3.677 3.677 0 011.239-.79 4.127 4.127 0 012.976.001c.473.174.891.451 1.255.79.356.346.638.758.853 1.255.212.481.323 1.041.323 1.649a4.06 4.06 0 01-.323 1.649c-.215.49-.506.9-.854 1.247a3.74 3.74 0 01-1.255.788 4.127 4.127 0 01-2.976 0 3.731 3.731 0 01-1.239-.788 3.893 3.893 0 01-.861-1.249c-.219-.48-.322-1.04-.322-1.648zm1.54-.008c0 .426.063.798.204 1.129.135.315.323.576.546.774.22.212.473.37.758.473.283.111.568.15.867.15.293 0 .576-.038.877-.15a2.13 2.13 0 00.758-.473 2.08 2.08 0 00.543-.774c.143-.331.206-.703.206-1.129 0-.434-.063-.804-.206-1.119a2.111 2.111 0 00-.543-.79 2.099 2.099 0 00-.758-.475 2.704 2.704 0 00-.877-.15 2.52 2.52 0 00-.867.15 2.107 2.107 0 00-.758.475c-.222.204-.41.465-.546.79-.141.315-.204.685-.204 1.119zm12.516 3.813l-3.457-5.407v5.407h-1.483V.158h1.855l3.156 5.036V.158h1.483v7.617zm-6.164 5.594h1.436V25.31H57.51z"
          }
          opacity={".5"}
        ></path>

        <path d={"M22.848 25.16L24 24.324l7.025 9.668-1.162.844z"}></path>
      </g>

      <circle cx={"58.228"} cy={"72.728"} r={"47.329"} opacity={".35"}></circle>

      <path
        d={
          "M86.952 103.261l-9.088 6.603a3.35 3.35 0 01-4.665-.739L28.72 47.906a3.35 3.35 0 01.739-4.665l9.088-6.603a3.35 3.35 0 014.665.739L87.69 98.596a3.35 3.35 0 01-.738 4.665z"
        }
        opacity={".7"}
      ></path>
    </svg>
  );
}

export default CalibrationRegcal01SvgIcon;
/* prettier-ignore-end */
