/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react'
import * as GQL from 'generated/graphql'
import { modalStyle } from 'components/Modal/Modal'
import PlasmicOrders from 'components/Orders'
import TableRow from 'components/TableRow'
import CellModel from 'components/CellModel'
import CellCustomer from 'components/CellCustomer'
import CellBoxes from 'components/CellBoxes'
import CellCreated from 'components/CellCreated'
import CellActions from 'components/CellActions'
import FullLoader from 'components/Loader/FullLoader'
import { displayFormatedDateTime, displayToast, modelFlag, progressCursor } from 'util/tools'
import ModalNewOrder from 'components/ModalNewOrder'
import ReactModal from 'react-modal'
// eslint-disable-next-line camelcase
import Select__Option from 'components/Select__Option'
import DropdownAction from 'components/DropdownAction'
import ModalDelete from 'components/ModalDelete'
import CellOrderNumber from 'components/CellOrderNumber'
import CornerLoader from 'components/Loader/CornerLoader'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

export function Orders() {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deleteData, setDeleteData] = useState<any>({})
  const [newOrderModalOpen, setNewOrderModalOpen] = useState<boolean>(false)
  const [distributor, setDistributor] = useState<string>('')
  const [orderModel, setOrderModel] = useState<string>('')
  const [boxesNumber, setBoxesNumber] = useState<number>(0)
  const [clickedActions, setClickedActions] = useState<string>('')

  const navigate = useNavigate()

  const intl = useIntl()
  const t = intl.formatMessage

  const ref = useRef<any>()
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (clickedActions && ref.current && !ref.current.contains(e.target)) {
        setClickedActions('')
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [clickedActions])

  // GQL QUERIES

  const allDistributors = GQL.useAllDistributors({
    notifyOnNetworkStatusChange: true,
    onError: err => {
      displayToast('Problem with fetching distributors')
      console.log(err.message)
    },
  })
    .data?.allDistributors?.edges?.map((distr: any) => {
      return distr.node
    })
    .sort((a, b) => (a.fullName?.toLowerCase() > b.fullName?.toLowerCase() ? 1 : b.fullName?.toLowerCase() > a.fullName?.toLowerCase() ? -1 : 0))

  const { data, loading } = GQL.useAllBoxOrders({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError: err => {
      displayToast('Problem with fetching box orders!')
      console.log(err.message)
    },
  })

  const sensorModels = GQL.useAllSensorModels({
    onError: err => {
      displayToast('Problem with fetching Sensor Models')
      console.log(err.message)
    },
  }).data?.allSensorModels?.edges.map(models => {
    return { id: models?.node?.id, name: models?.node?.name }
  })

  // GQL MUTATIONS

  const [deleteBoxOrder] = GQL.useDeleteBoxOrder({
    refetchQueries: ['AllBoxOrders'],
    onError: err => {
      displayToast('There was an error deleting box order!')
      console.log(err)
    },
    onCompleted: ({ deleteBoxOrder }: any) => {
      if (deleteBoxOrder === null) {
        displayToast('Denied! Try again or contact support.')
        return
      }
      const { ok } = deleteBoxOrder
      if (!ok) {
        displayToast('Server error. Try again or contact support.')
        return
      }
      onRequestCloseDeleteModal()
      displayToast('Order deleted succesfully!', 'success', { toastId: null })
    },
  })

  const [createBoxOrder] = GQL.useCreateBoxOrder({
    refetchQueries: ['AllBoxOrders'],
    onCompleted: ({ createBoxOrder }: any) => {
      if (createBoxOrder === null) {
        displayToast('Access Denied! Try again or contact support.')
        return
      }
      const ok = createBoxOrder.ok
      if (!ok) {
        displayToast('Server error. Try again or contact support.')
        return
      }
      onRequestCloseNewOrderModal()
      displayToast('Order created successfully.', 'success', { toastId: null })
    },
    onError: (err: any) => {
      console.log(err)
      displayToast(err.message)
    },
  })

  const submitOrder = (createBoxOrder: Function) => {
    if (boxesNumber <= 0) {
      displayToast("Can't order 0 boxes!")
      return
    }
    if (boxesNumber > 10000) {
      displayToast('Too many boxes in one order!')
      return
    }
    if (!distributor || !orderModel || !boxesNumber) {
      displayToast('Missign data in Input Fields')
      return
    }

    createBoxOrder({
      variables: {
        boxesOrdered: boxesNumber,
        distributorId: distributor,
        sensorModelId: orderModel,
      },
    })
  }

  const boxesGenerator = (ordered: number, allocated: number, delivered: number) => {
    const temp = []
    for (let i = 0; i < ordered - allocated; i++) {
      temp.push(<span key={'box4' + i} className='icon-box-font empty' title='Ordered' />)
    }
    for (let i = 0; i < allocated - delivered; i++) {
      temp.push(
        <div key={'style2' + i}>
          <span key={'box2' + i} className='icon-box-font allocated' />
          <span key={'box3' + i} className='icon-box-font allocated2' title='Allocated' />
        </div>
      )
    }
    for (let i = 0; i < delivered; i++) {
      temp.push(
        <div key={'style1' + i}>
          <span key={'box' + i} className='icon-box-font solid' />
          <span key={'box1' + i} className='icon-box-font solid2' title='Delivered' />
        </div>
      )
    }
    return temp
  }

  const onRequestCloseNewOrderModal = () => {
    setNewOrderModalOpen(false)
    setBoxesNumber(0)
    setDistributor('')
    setOrderModel('')
  }
  const onRequestCloseDeleteModal = () => {
    setDeleteData({})
    setDeleteModalOpen(false)
  }

  return (
    <>
      {loading && <CornerLoader size={32} topAdjust='-15px' />}
      {/* delete order Modal */}
      <ReactModal isOpen={deleteModalOpen} onRequestClose={onRequestCloseDeleteModal} style={modalStyle()}>
        <ModalDelete
          closeModal={{ onClick: () => onRequestCloseDeleteModal() }}
          btnDelete={{
            onClick: () => {
              deleteBoxOrder({ variables: { id: deleteData.id } })
            },
          }}
          objectTitle={'order ' + window.atob(deleteData.id ?? 'MA==').split(':')[1]}
          description={'Please confirm if you want to delete order nr. ' + window.atob(deleteData.id ?? 'MA==').split(':')[1] + ' for ' + deleteData.name}
        />
      </ReactModal>

      {/* New order Modal */}
      <ReactModal
        isOpen={newOrderModalOpen}
        onRequestClose={onRequestCloseNewOrderModal}
        style={modalStyle()}
        appElement={document.getElementById('root') as HTMLElement}
      >
        <ModalNewOrder
          closeModalCross={{ onClick: () => onRequestCloseNewOrderModal() }}
          closeModal={{ onClick: () => onRequestCloseNewOrderModal() }}
          customer={{
            props: {
              onChange: (e: any) => setDistributor(e),
              'aria-label': 'Distributor',
              children: allDistributors?.map((distributor, i) => {
                return (
                  <Select__Option key={i + 'Select'} value={distributor.id}>
                    {distributor.fullName}
                  </Select__Option>
                )
              }),
            },
          }}
          product={{
            props: {
              onChange: (e: any) => setOrderModel(e),
              'aria-label': 'yes',
              children: sensorModels?.map((model, i) => {
                return (
                  <Select__Option key={i + 'Selecta'} value={model.id}>
                    {model.name}
                  </Select__Option>
                )
              }),
            },
          }}
          boxQuantity={{
            props: { value: boxesNumber ? boxesNumber : '', onChange: (e: any) => setBoxesNumber(parseInt(e.target.value || '0')) },
          }}
          sensorQuantity={{
            props: { value: boxesNumber * 9, readOnly: true },
          }}
          btnCreateOrder={{
            props: {
              onClick: (e: any) => {
                e.preventDefault()
                submitOrder(createBoxOrder)
              },
            },
          }}
        />
      </ReactModal>
      <PlasmicOrders
        text={t({ id: 'common.orders' })}
        btnNewOrder={{
          onClick: () => setNewOrderModalOpen(true),
          title: t({ id: 'common.new_orders' }),
        }}
        headCustomer={t({ id: 'common.customers' })}
        headBoxesDeliveredOrdered={t({ id: 'orders.boxes_delivered_ordered' })}
        headOrder={t({ id: 'common.order' })}
        headOrderDate={t({ id: 'common.order_date' })}
        rows={
          <>
            {progressCursor(loading)}
            {loading && !data ? (
              <FullLoader color='white' />
            ) : (
              data?.allBoxOrders?.edges?.map((order: any, i: number) => (
                <TableRow
                  key={i + 'tablerow'}
                  customer={<CellCustomer onClick={() => navigate(`/customers/${order.node.distributor.id}`)}>{order.node.distributor.fullName}</CellCustomer>}
                  model={<CellModel symbol={modelFlag(order?.node.requestedModel?.name)}>{order?.node.requestedModel?.name || 'ND'}</CellModel>}
                  boxes={
                    <CellBoxes count={order.node.boxesAllocated + '/' + order.node.boxesOrdered}>
                      {boxesGenerator(order.node.boxesOrdered, order.node.boxesAllocated, order.node.boxesDelivered)}
                    </CellBoxes>
                  }
                  orderNumber={<CellOrderNumber>{window.atob(order.node.id).split(':')[1]}</CellOrderNumber>}
                  created={<CellCreated>{displayFormatedDateTime(order.node.createdAt || null)}</CellCreated>}
                  actions={
                    <CellActions
                      open={clickedActions === order.node.id ? true : false}
                      ref={clickedActions === order.node.id ? ref : null}
                      onClick={() => (clickedActions === order.node.id ? setClickedActions('') : setClickedActions(order.node.id))}
                      actions={
                        <>
                          <DropdownAction
                            onClick={(e: any) => {
                              e.stopPropagation()
                              setDeleteModalOpen(true)
                              setDeleteData({ id: order.node.id, name: order.node.distributor.fullName })
                              setClickedActions('')
                            }}
                          >
                            ❌ {t({ id: 'common.delete' })}
                          </DropdownAction>
                        </>
                      }
                    />
                  }
                  visibleColumns={['customer', 'model', 'boxes', 'created', 'actions', 'orderNumber']}
                />
              ))
            )}
          </>
        }
      />
    </>
  )
}

export default Orders
