// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type HealthR02SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function HealthR02SvgIcon(props: HealthR02SvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 23.79 20.426"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M23.388.358A1.33 1.33 0 0022.444 0c-.207 0-.429.039-.66.116L1.449 6.894C.279 7.284.042 7.966.006 8.338c-.037.372.061 1.087 1.132 1.699l6.298 3.599v4.812c0 1.357.588 1.777.937 1.906.127.047.262.071.403.071.512 0 1.034-.306 1.55-.908l2.435-2.839 3.902 2.229c.474.271 1.032.319 1.498.129.473-.192.831-.607.984-1.14L23.7 1.95c.252-.884-.087-1.377-.312-1.592zM7.815 12.355L1.784 8.909c-.388-.222-.481-.401-.484-.439.011-.044.139-.201.561-.342l19.041-6.347L7.815 12.355zm1.524 6.317c-.25.292-.421.397-.502.435-.039-.083-.1-.275-.1-.658V14.38l2.875 1.643-2.273 2.649zm8.555-1.131c-.042.147-.121.25-.223.292-.101.041-.232.021-.363-.054l-8.281-4.732L22.224 2.385l-4.33 15.156z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default HealthR02SvgIcon;
/* prettier-ignore-end */
