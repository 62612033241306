/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as GQL from 'generated/graphql'
import TableRow from 'components/TableRow'
import FullLoader from 'components/Loader/FullLoader'
import CellModel from 'components/CellModel'
import { displayFormatedDateTime, displayToast, modelFlag, showSensors } from 'util/tools'
import CellCreated from 'components/CellCreated'
import PlasmicCustomerPage from 'components/CustomerPage'
import CellBoxId from 'components/CellBoxId'
import CellSensorId from 'components/CellSensorId'
import CellProduction from 'components/CellProduction'
import CellLocation from 'components/CellLocation'
// eslint-disable-next-line camelcase
import Select__Option from 'components/Select__Option'
import CellActions from 'components/CellActions'
import DropdownAction from 'components/DropdownAction'
import CornerLoader from 'components/Loader/CornerLoader'
import { useIntl } from 'react-intl'

export function CustomerPage() {
  const [clickedActions, setClickedActions] = useState<string>('')
  const navigate = useNavigate()

  const { customerId } = useParams<{ customerId: string }>()

  const ref = useRef<any>()

  const intl = useIntl()
  const t = intl.formatMessage

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (clickedActions && ref.current && !ref.current.contains(e.target)) {
        e.preventDefault()
        e.stopPropagation()
        setClickedActions('')
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [clickedActions])

  // GQL QUERIES

  const boxLocations = GQL.useAllBoxLocations({ onError: err => displayToast('Error Fetching Locations') })?.data?.allBoxLocations?.map((e: any) => {
    return { loc: e.name, id: e.id }
  })

  const { data, loading } = GQL.useDistributor({
    variables: {
      distributor: customerId!,
    },
    notifyOnNetworkStatusChange: true,
  })

  const allDistributors = GQL.useAllDistributors({
    onError: err => displayToast(err.message ?? 'Error while fetching distributors'),
  })
    .data?.allDistributors?.edges?.map((distr: any) => {
      return distr.node
    })
    .sort((a, b) => (a.fullName?.toLowerCase() > b.fullName?.toLowerCase() ? 1 : b.fullName?.toLowerCase() > a.fullName?.toLowerCase() ? -1 : 0))

  // GQL MUTATIONS

  const [unallocate] = GQL.usePatchBox({
    refetchQueries: ['Distributor'],
    onCompleted: ({ patchBox }: any) => {
      if (patchBox === null) {
        displayToast('Denied! Try again or contact support.')
        return
      }
      const { ok, error } = patchBox
      if (!ok) {
        error ? displayToast(error) : displayToast('Server error. Try again or contact support.')
        return
      }
      displayToast('Order unallocated succesfully!', 'success')
    },
  })

  const [patchBox] = GQL.usePatchBox({
    onError: (err: any) => {
      console.log(err)
      displayToast('Error while editing the box!\n Try again!')
    },
    onCompleted: ({ patchBox }: any) => {
      if (patchBox === null) {
        displayToast('Denied! Try again or contact support.')
        return
      }
      const { ok, error } = patchBox
      if (!ok) {
        error ? displayToast(error) : displayToast('Server error. Try again or contact support.')
        return
      }
      displayToast('Box location edited successfully!', 'success', { toastId: null })
    },
  })

  return (
    <>
      {loading && <CornerLoader size={32} topAdjust='-15px' />}
      <PlasmicCustomerPage
        headDateAllocated={t({ id: 'customers.date_allocated' })}
        headLocation={t({ id: 'common.location' })}
        headProduction={t({ id: 'common.production' })}
        select={{
          props: {
            'aria-label': 'Select a distributor',
            placeholder: t({ id: 'common.select' }),
            defaultValue: customerId,
            onChange: (e: any) => navigate(`/customers/${e}`),
            children: allDistributors?.map(e => (
              <Select__Option key={e.id} value={e.id}>
                {e.fullName}
              </Select__Option>
            )),
          },
        }}
        rows={
          <>
            {loading && !data ? (
              <FullLoader color='yellow' />
            ) : (
              data?.distributor?.boxOrders?.edges?.map((order: any) => {
                return order.node.boxes.map((box: GQL.BoxNode) => {
                  return (
                    <TableRow
                      key={box.id + 'customerPage'}
                      boxId={<CellBoxId color={box.isShipped ? 'shipped' : box.allocatedDate ? 'allocated' : undefined}>{box.name}</CellBoxId>}
                      model={
                        <CellModel symbol={modelFlag(box.sensors?.find(e => e?.model?.name)?.model?.name)}>
                          {box.sensors?.find(e => e?.model?.name)?.model?.name ?? 'undefined'}
                        </CellModel>
                      }
                      production={
                        <CellProduction>
                          {box.sensors?.some(e => e?.createdAt)
                            ? new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' }).format(new Date(box.createdAt))
                            : 'NoData'}
                        </CellProduction>
                      }
                      created={<CellCreated>{displayFormatedDateTime(box.allocatedDate || null)}</CellCreated>}
                      location={
                        <CellLocation
                          selectLocation={{
                            props: {
                              label: 'Location select',
                              onChange: (e: any) => {
                                e === 'delivered'
                                  ? patchBox({
                                      variables: {
                                        boxId: box.id,
                                        input: { isShipped: true },
                                      },
                                    })
                                  : patchBox({
                                      variables: {
                                        boxId: box.id,
                                        input: { location: e, isShipped: false },
                                      },
                                    })
                              },
                              defaultValue: box.isShipped ? 'delivered' : box.location?.id,
                              children: [
                                boxLocations?.map(e => (
                                  <Select__Option key={e.id} value={e.id}>
                                    {e.loc}
                                  </Select__Option>
                                )),
                                <Select__Option key={'[ag28#'} value={'delivered'}>
                                  Delivered
                                </Select__Option>,
                              ],
                            },
                          }}
                        />
                      }
                      sensorIDs={<CellSensorId>{box.sensors ? showSensors(box.sensors) : 'no data'}</CellSensorId>}
                      actions={
                        <CellActions
                          ref={clickedActions === box.id ? ref : null}
                          open={clickedActions === box.id ? true : false}
                          onClick={() => (clickedActions === box.id ? setClickedActions('') : setClickedActions(box.id))}
                          actions={
                            <>
                              <DropdownAction
                                onClick={(e: any) => {
                                  unallocate({
                                    variables: {
                                      boxId: box.id,
                                      input: {
                                        boxOrder: '',
                                      },
                                    },
                                  })
                                  e.stopPropagation()
                                  setClickedActions('')
                                }}
                              >
                                ❌ {t({ id: 'common.unallocate' })}
                              </DropdownAction>
                            </>
                          }
                        />
                      }
                      visibleColumns={['boxId', 'model', 'sensorIDs', 'created', 'production', 'actions', 'location']}
                    />
                  )
                })
              })
            )}
          </>
        }
      />
    </>
  )
}

export default CustomerPage
