// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type HealthR05SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function HealthR05SvgIcon(props: HealthR05SvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 18.002 19.174"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M4.664 3.763a1.385 1.385 0 00-1.958-.023 9 9 0 00-.138 12.728 9 9 0 0015.434-6.296 8.93 8.93 0 00-2.706-6.433 1.384 1.384 0 00-1.935 1.981 6.174 6.174 0 011.873 4.452 6.232 6.232 0 11-12.464 0A6.174 6.174 0 014.643 5.72c.545-.533.555-1.41.021-1.957z"
          }
        ></path>

        <path
          d={
            "M10.385 8.557v-7.17a1.385 1.385 0 10-2.769-.004v7.173a1.385 1.385 0 002.769.001z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default HealthR05SvgIcon;
/* prettier-ignore-end */
