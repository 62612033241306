import React from 'react'
import styled from 'styled-components'

import Loader from './Loader'

interface IFullLoaderWrapperProps {
  cover?: 'page' | 'parent';
  visible?: boolean;
  opacity?: number;
  size?: number;
  top?: string;
}

const FullLoaderWrapper = styled.div<IFullLoaderWrapperProps>`
  position: ${props => props.cover === "page" ? "fixed" : "absolute"};
  width: ${props => props.cover === "page" ? "100vw" : "100%"};
  height: ${props => props.cover === "page" ? "100vh" : "100%"};
  display: flex;
  box-sizing:border-box ;
  justify-content: center;
  align-items: center;
  opacity: ${props => props.opacity};
  top: ${props => props.top ? props.top : 0};
  left: 0;
  z-index: 9999;
`

FullLoaderWrapper.defaultProps = {
  cover: "page",
  opacity: 0.5,
  size: 80,
}

const Inner = styled.div`
  position: relative;
  margin-top: -6em;
`

interface IFullLoaderProps {
  cover?: 'page' | 'parent';
  color?: string;
  opacity?: number;
  size?: number;
  top?: string;
}

export default function FullLoader({
  cover,
  color,
  opacity,
  size = 80,
  top,
}: IFullLoaderProps) {
  return (
    <FullLoaderWrapper cover={cover} opacity={opacity} top={top}>
      <Inner>
        <Loader color={color} size={size} />
      </Inner>
    </FullLoaderWrapper>
  )
}
