// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type HealthR04SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function HealthR04SvgIcon(props: HealthR04SvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 10.878 21.078"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M10.124 5.366H5.822l1.863-4.302C7.907.576 7.53 0 6.998 0H3.184a.787.787 0 00-.732.532L.035 8.647c-.155.488.222.976.732.976h4.435l-1.397 4.745H1.809a.631.631 0 00-.599.82l1.818 5.454a.64.64 0 001.042.266l5.787-5.454c.421-.399.133-1.086-.443-1.086h-2.04l3.437-7.938a.75.75 0 00-.687-1.064z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default HealthR04SvgIcon;
/* prettier-ignore-end */
