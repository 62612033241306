import SensorId from 'components/SensorId'
import React, { MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Toast from 'components/Toast'
import * as GQL from 'generated/graphql'
import { format } from 'date-fns'
import { locale } from 'locale/locale'

/**
 * Function which takes Date and returns string with formated DateTime
 * @param {Date} date a date in a Date format
 * @return {String} `Jan 1, 1970 0:00`
 * @author Marcin
 */
export const displayFormatedDateTime = (date: Date) => {
  if (date === null || !date) {
    return ''
  }
  return format(new Date(date), 'd MMM, HH:mm', { locale: locale })
}

export const displayFormattedDate = (date: Date) => {
  if (date === null || !date) {
    return ''
  }
  return format(new Date(date), 'MMM Y', { locale: locale })
}

/**
 * @version v1.1
 * @description a function to show toast messages.
 * @param {String} info a string which is shown on a toast message
 * @param {'error' | 'info' | 'success' | 'warning' | undefined} type a type of toast to display. If undefined 'error' will be used.
 * @param {Object} options is an object of react-toastify options. Overwritting the toastId to null will make duplicates of same message possible.
 * Other options are: autoClose, closeOnClick, position, style etc.
 * @param {boolean} noClose pass true if you do not want a close button to display in the toast
 */
 export const displayToast = (info: string | ReactElement, type?: 'error' | 'info' | 'success' | 'warning' | undefined, options?: object, noClose?: boolean) => {
  toast(
    ({ closeToast }) => (
      <Toast
        btnCloseToast={{
          onClick: closeToast,
          ...(noClose && {'style': {visibility: 'hidden'}})
        }}
        type={type || 'error'}
        info={info}
      />
    ),
    { toastId: JSON.stringify(info), ...options }
  )
}

export function useDebounce<T>(value: T, delay: number = 650) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export const useFocus = (): [any, () => void] => {
  const htmlElRef: MutableRefObject<any> = useRef(null)
  const setFocus = (): void => {
    htmlElRef?.current?.focus?.()
  }
  return [htmlElRef, setFocus]
}

/**
 * It takes model and output the string for the flag selection. If undefined or not in
 * recognized format it will output `undefined` which results in Whole World icon
 * @param {string | undefined} boxmodel as string with EU or AU ending or anything else or undefined
 * @return {'eu' | 'au' | undefined} `'eu' | 'au' | undefined`
 */
export const modelFlag = (boxmodel: string | undefined) => {
  if (boxmodel === undefined) return undefined
  const flag = boxmodel.slice(-2).toLowerCase()
  if (flag === 'eu' || flag === 'au') return flag
  return undefined
}

/**
 * It takes array of sensors and output 9 field components even of there is no 9 sensors in the array
 * @param {Array<any>} sensors node from GraphQl
 * @return {Array<React.Component>} array of SensorId components
 */
export const showSensors = (sensors: Array<any>) => {
  const result = []
  sensors.map(s => {
    result.push(
      <SensorId
        sensor={s}
        key={s.serialNumber}
      />
    )
    return null
  })
  if (sensors.length < 9) {
    for (let i = 0; i < 9 - sensors.length; i++) {
      result.push(<SensorId key={'32tgdfgd' + i} color='empty' />)
    }
  }
  return result
}

export const currentSensorStatus = (sensor: GQL.SensorNode) => {
  const flags = sensor.flags as GQL.SensorFlagNode[]
  if (sensor.box?.id) return 'packaged'
  if (flags.some(f => f.flag === 'WRECKED')) return 'error'
  if (flags.some(f => f.flag === 'END_TEST_SUCCEEDED')) return 'finalTestDone'
  if (flags.some(f => f.flag === 'END_TEST_STARTED')) return 'finalTesting'
  if (flags.some(f => f.flag === 'CALIBRATED')) return 'calibrationComplete'
  if (flags.some(f => f.flag === 'CALIBRATION_STARTED')) return 'calibrating'
  if (flags.some(f => f.flag === 'LASERED')) return 'lasered'  
  return undefined
}

export const progressCursor = (loading: boolean) => {
  const root = document.querySelector<HTMLElement>('body')
  loading ? (root!.style.cursor = 'wait') : (root!.style.cursor = '')
  return null
}

export const getDomain = () => {
  const domain = window.location.hostname.split('.')
  return domain.slice(domain.indexOf('regcal') + 1).join('.')
}
