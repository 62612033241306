import React from 'react'
import styled from 'styled-components'
import animationData from './sc-loader.json'
import Lottie from 'lottie-react'

interface ILoaderProps {
  className?: string
  size?: number
  color?: string
  inline?: boolean
  opacity?: number
}

const InnerLoader: React.FC<ILoaderProps> = ({ className, size }) => {
  return (
    <div className={className}>
      <Lottie animationData={animationData} style={{ height: size?.toString(), width: size?.toString() }} />
    </div>
  )
}

/**
 * Loader which is plain without centering
 * @param {size} number is a number in pixels of height and width of the Loader (square), default 32.
 * @param {color} string color of the loader, can me name from theme or simple color code or name, default gray6
 * @param {inline} boolean  if true, loader will be inline-block, default false
 */
const Loader = styled(InnerLoader)<ILoaderProps>`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  height: ${props => props.size || 32}px;
  width: ${props => props.size || 32}px;
  fill: ${props => props.color};
  opacity: ${props => props.opacity || 0.6};
`

export default Loader
