// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LoadingsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LoadingsvgIcon(props: LoadingsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 46.526 46.526"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M23.263 9.006a2 2 0 01-2-2V2a2 2 0 014 0v5.006a2 2 0 01-2 2zm-11.495 4.762a1.99 1.99 0 01-1.414-.586l-3.54-3.54a2 2 0 112.828-2.828l3.54 3.54a2 2 0 01-1.414 3.414zM7.006 25.263H2a2 2 0 010-4h5.006a2 2 0 010 4zm1.222 15.036a2 2 0 01-1.414-3.414l3.54-3.54a2 2 0 112.828 2.828l-3.54 3.54a1.99 1.99 0 01-1.414.586zm15.035 6.227a2 2 0 01-2-2v-5.005a2 2 0 014 0v5.005a2 2 0 01-2 2zm15.036-6.227a1.99 1.99 0 01-1.414-.586l-3.54-3.54a2 2 0 112.828-2.828l3.54 3.54a2 2 0 01-1.414 3.414zm6.227-15.036h-5.005a2 2 0 010-4h5.005a2 2 0 010 4zm-9.767-11.495a2 2 0 01-1.414-3.414l3.54-3.54a2 2 0 112.828 2.828l-3.54 3.54c-.39.39-.902.586-1.414.586z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default LoadingsvgIcon;
/* prettier-ignore-end */
