import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Date: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
  JSONString: any;
  Time: any;
  UUID: any;
  Upload: any;
};

export type AddCylinderGroupNoteMutation = {
  __typename?: 'AddCylinderGroupNoteMutation';
  note?: Maybe<CylinderGroupNoteNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddCylinderGroupTagMutation = {
  __typename?: 'AddCylinderGroupTagMutation';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  ok?: Maybe<Scalars['Boolean']>;
  tag?: Maybe<Scalars['String']>;
};

export type AddPermissionGroup = {
  __typename?: 'AddPermissionGroup';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AppVersionNode = Node & {
  __typename?: 'AppVersionNode';
  app?: Maybe<Apps>;
  createdAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  info?: Maybe<Scalars['String']>;
  infoHtml?: Maybe<Scalars['String']>;
  major?: Maybe<Scalars['Int']>;
  minor?: Maybe<Scalars['Int']>;
  patch?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  version: Scalars['String'];
};

export type AppVersionNodeConnection = {
  __typename?: 'AppVersionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppVersionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AppVersionNode` and its cursor. */
export type AppVersionNodeEdge = {
  __typename?: 'AppVersionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AppVersionNode>;
};

/** An enumeration. */
export enum Apps {
  CustomerApp = 'CUSTOMER_APP',
  DriverApp = 'DRIVER_APP',
  Solace = 'SOLACE'
}

export type AssignBoxesToOrderMutation = {
  __typename?: 'AssignBoxesToOrderMutation';
  boxOrder?: Maybe<BoxOrderNode>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type BoxInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BoxNode = Node & {
  __typename?: 'BoxNode';
  allocatedDate?: Maybe<Scalars['DateTime']>;
  /** The box order the box has been assigned to */
  boxOrder?: Maybe<BoxOrderNode>;
  createdAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isShipped: Scalars['Boolean'];
  /** The location of the box */
  location?: Maybe<LocationNode>;
  name?: Maybe<Scalars['String']>;
  sensors?: Maybe<Array<Maybe<SensorNode>>>;
  shippedDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type BoxNodeConnection = {
  __typename?: 'BoxNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BoxNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BoxNode` and its cursor. */
export type BoxNodeEdge = {
  __typename?: 'BoxNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BoxNode>;
};

export type BoxOrderNode = Node & {
  __typename?: 'BoxOrderNode';
  boxes?: Maybe<Array<Maybe<BoxNode>>>;
  boxesAllocated?: Maybe<Scalars['Int']>;
  boxesDelivered?: Maybe<Scalars['Int']>;
  boxesOrdered: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** The distributor the order has been requesten from */
  distributor: DistributorNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The sensor model for the sensors in the box */
  requestedModel?: Maybe<SensorModelNode>;
  updatedAt: Scalars['DateTime'];
};

export type BoxOrderNodeConnection = {
  __typename?: 'BoxOrderNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BoxOrderNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BoxOrderNode` and its cursor. */
export type BoxOrderNodeEdge = {
  __typename?: 'BoxOrderNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BoxOrderNode>;
};

export type CancelRouteMutation = {
  __typename?: 'CancelRouteMutation';
  route?: Maybe<RouteNode>;
};

export type CancelStopMutation = {
  __typename?: 'CancelStopMutation';
  stop?: Maybe<StopNode>;
};

export type CertNode = Node & {
  __typename?: 'CertNode';
  /** certificate in X.509 PEM format */
  certificate: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** private key in X.509 PEM format */
  privateKey: Scalars['String'];
};

export type ChangeDriverOnRouteMutation = {
  __typename?: 'ChangeDriverOnRouteMutation';
  ok?: Maybe<Scalars['Boolean']>;
  route?: Maybe<RouteNode>;
};

export type ChangePasswordMutation = {
  __typename?: 'ChangePasswordMutation';
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserNode>;
  validationErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CheckEncodedSensorSerialDistributor = {
  __typename?: 'CheckEncodedSensorSerialDistributor';
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type CheckEncodedSensorSerialResult = {
  __typename?: 'CheckEncodedSensorSerialResult';
  distributor?: Maybe<CheckEncodedSensorSerialDistributor>;
  ok?: Maybe<Scalars['Boolean']>;
  sensor?: Maybe<SensorNode>;
  /** The fully decoded sensor serial */
  sensorSerial?: Maybe<Scalars['String']>;
  /** An authentication token which can be used for subsequent requests involving the sensor. This will only be returned if ok is true. */
  token?: Maybe<Scalars['String']>;
};

export type ClearAllCylinderGroupTagsMutation = {
  __typename?: 'ClearAllCylinderGroupTagsMutation';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CompleteRouteAndStopsMutation = {
  __typename?: 'CompleteRouteAndStopsMutation';
  route?: Maybe<RouteNode>;
};

export type CompleteRouteMutation = {
  __typename?: 'CompleteRouteMutation';
  route?: Maybe<RouteNode>;
};

export type CompleteStopMutation = {
  __typename?: 'CompleteStopMutation';
  nextStop?: Maybe<StopNode>;
  stop?: Maybe<StopNode>;
};

export type ConsumptionRateInterval = {
  __typename?: 'ConsumptionRateInterval';
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type CreateBoxMutation = {
  __typename?: 'CreateBoxMutation';
  box?: Maybe<BoxNode>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateBoxOrderMutation = {
  __typename?: 'CreateBoxOrderMutation';
  boxOrder?: Maybe<BoxOrderNode>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateCustomerContactInput = {
  customer: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerContactMutation = {
  __typename?: 'CreateCustomerContactMutation';
  customerContact?: Maybe<CustomerContactNode>;
};

export type CreateCustomerContactNotificationInput = {
  contact: Scalars['ID'];
  data?: InputMaybe<Scalars['GenericScalar']>;
  resource: Scalars['ID'];
  service?: InputMaybe<CustomerContactNotificationService>;
};

export type CreateCustomerContactNotificationMutation = {
  __typename?: 'CreateCustomerContactNotificationMutation';
  customerContactNotification?: Maybe<CustomerContactNotificationNode>;
};

export type CreateCustomerMutation = {
  __typename?: 'CreateCustomerMutation';
  customer?: Maybe<CustomerNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateCustomerTypeMutation = {
  __typename?: 'CreateCustomerTypeMutation';
  distributorCustomerType?: Maybe<DistributorCustomerTypeNode>;
};

export type CreateCycleInput = {
  activeSide?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  cgSide: Scalars['ID'];
  currentWeight?: InputMaybe<Scalars['Float']>;
  customTareWeight?: InputMaybe<Scalars['Float']>;
  cycleSet?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  cycleeventsSet?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  depletionDate?: InputMaybe<Scalars['DateTime']>;
  depletionRate?: InputMaybe<Scalars['Float']>;
  depletionRateCycle?: InputMaybe<Scalars['Float']>;
  emptyDate?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  error?: InputMaybe<Scalars['String']>;
  errorCode?: InputMaybe<Scalars['ID']>;
  estimatedEmpty?: InputMaybe<Scalars['DateTime']>;
  firstUpdatedTs?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['DateTime']>;
  lastCalculation?: InputMaybe<Scalars['UUID']>;
  lastDepCoef?: InputMaybe<Scalars['Float']>;
  lastDepIntercept?: InputMaybe<Scalars['Float']>;
  lastLogStream?: InputMaybe<Scalars['String']>;
  lastUpdatedIndex?: InputMaybe<Scalars['String']>;
  lastUpdatedTs?: InputMaybe<Scalars['Float']>;
  lastWeight?: InputMaybe<Scalars['Float']>;
  monitoredCycle?: InputMaybe<Scalars['ID']>;
  noise?: InputMaybe<Scalars['Float']>;
  orders?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  predictions?: InputMaybe<Scalars['JSONString']>;
  prevCycle?: InputMaybe<Scalars['ID']>;
  refillDate?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
  startWeight?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<CycleState>;
  stockbalanceSet?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  stopDate?: InputMaybe<Scalars['DateTime']>;
  tareWeight?: InputMaybe<Scalars['Float']>;
};

export type CreateCycleMutation = {
  __typename?: 'CreateCycleMutation';
  cycle?: Maybe<CycleNode>;
};

export type CreateCylinderGroupOrder = {
  __typename?: 'CreateCylinderGroupOrder';
  cylinderGroupOrder?: Maybe<CylinderGroupOrderNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateDepotInput = {
  /** The address where the depot is located. */
  address?: InputMaybe<Scalars['String']>;
  distributor: Scalars['ID'];
  /** The latitude of the location of the depot. */
  latitude?: InputMaybe<Scalars['Float']>;
  /** The longitude of the location of the depot. */
  longitude?: InputMaybe<Scalars['Float']>;
  /** The name of the depot */
  name?: InputMaybe<Scalars['String']>;
};

export type CreateDepotMutation = {
  __typename?: 'CreateDepotMutation';
  depot?: Maybe<DepotNode>;
};

export type CreateDistributorCustomerTypeInput = {
  baseType?: InputMaybe<DistributorCustomerTypeBaseType>;
  distributor: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateDistributorRefillThresholdInput = {
  alertThreshold?: InputMaybe<Scalars['Float']>;
  distributor: Scalars['ID'];
  name: Scalars['String'];
  threshold: Scalars['Float'];
  unit?: InputMaybe<DistributorRefillThresholdUnit>;
};

export type CreateDistributorRefillThresholdMutation = {
  __typename?: 'CreateDistributorRefillThresholdMutation';
  distributorRefillThreshold?: Maybe<DistributorRefillThresholdNode>;
};

export type CreateDriverExceptionsInput = {
  defaultDepot?: InputMaybe<Scalars['ID']>;
  defaultVehicle?: InputMaybe<Scalars['ID']>;
  driver: Scalars['ID'];
  endTime?: InputMaybe<Scalars['Time']>;
  onDate: Scalars['Date'];
  startTime?: InputMaybe<Scalars['Time']>;
  vacated?: InputMaybe<Scalars['Boolean']>;
};

export type CreateDriverExceptionsMutation = {
  __typename?: 'CreateDriverExceptionsMutation';
  driverExceptions?: Maybe<DriverExceptionsNode>;
};

export type CreateDriverMutation = {
  __typename?: 'CreateDriverMutation';
  user?: Maybe<UserNode>;
};

export type CreateNotificationListenerMutation = {
  __typename?: 'CreateNotificationListenerMutation';
  error?: Maybe<Scalars['String']>;
  notificationListener?: Maybe<NotificationListenerNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateOrPatchDriverWorkhourMutation = {
  __typename?: 'CreateOrPatchDriverWorkhourMutation';
  ok?: Maybe<Scalars['Boolean']>;
  workhour?: Maybe<DriverStandardWorkhourNode>;
};

export type CreateRouteInput = {
  /** The depot from which this route originates. */
  depot?: InputMaybe<Scalars['ID']>;
  /** If true, the route is locked, and cannot be changed. */
  locked?: InputMaybe<Scalars['Boolean']>;
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type CreateRouteMutation = {
  __typename?: 'CreateRouteMutation';
  route?: Maybe<RouteNode>;
};

export type CreateSensorFlagMutation = {
  __typename?: 'CreateSensorFlagMutation';
  created?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  sensorFlag?: Maybe<SensorFlagNode>;
};

export type CreateSensorMutation = {
  __typename?: 'CreateSensorMutation';
  ok?: Maybe<Scalars['Boolean']>;
  sensor?: Maybe<SensorNode>;
  url?: Maybe<Scalars['String']>;
};

export type CreateSensorNewMutation = {
  __typename?: 'CreateSensorNewMutation';
  ok?: Maybe<Scalars['Boolean']>;
  serialNumber?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CreateStockInput = {
  currentEmpty?: InputMaybe<Scalars['Int']>;
  currentFull?: InputMaybe<Scalars['Int']>;
  cylinderGroups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type CreateStockMutation = {
  __typename?: 'CreateStockMutation';
  stock?: Maybe<StockNode>;
};

export type CreateStopMutation = {
  __typename?: 'CreateStopMutation';
  stop?: Maybe<StopNode>;
};

export type CreateUserMutation = {
  __typename?: 'CreateUserMutation';
  user?: Maybe<UserNode>;
};

export type CreateVehicleExceptionMutation = {
  __typename?: 'CreateVehicleExceptionMutation';
  vehicleExceptions?: Maybe<VehicleExceptionNode>;
};

export type CreateVehicleExceptionsInput = {
  depot?: InputMaybe<Scalars['ID']>;
  onDate: Scalars['Date'];
  vacated?: InputMaybe<Scalars['Boolean']>;
  vehicle: Scalars['ID'];
};

export type CreateVehicleInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  capacity?: InputMaybe<Scalars['Int']>;
  depot: Scalars['ID'];
  name: Scalars['String'];
  registrationNumber?: InputMaybe<Scalars['String']>;
  vehicleType: VehicleVehicleType;
};

export type CreateVehicleMutation = {
  __typename?: 'CreateVehicleMutation';
  vehicle?: Maybe<VehicleNode>;
};

export type CurrentOrderStatsResult = {
  __typename?: 'CurrentOrderStatsResult';
  gasType?: Maybe<GasTypeEnum>;
  gasWeight?: Maybe<Scalars['Int']>;
  numberOfEmptyCylinders?: Maybe<Scalars['Int']>;
  uniqueOrders?: Maybe<Scalars['Int']>;
};

export type CustomerContactNode = Node & {
  __typename?: 'CustomerContactNode';
  customer: CustomerNode;
  email?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  notifications?: Maybe<Array<Maybe<CustomerContactNotificationNode>>>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type CustomerContactNodeConnection = {
  __typename?: 'CustomerContactNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerContactNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomerContactNode` and its cursor. */
export type CustomerContactNodeEdge = {
  __typename?: 'CustomerContactNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CustomerContactNode>;
};

export type CustomerContactNotificationNode = Node & {
  __typename?: 'CustomerContactNotificationNode';
  contact: CustomerContactNode;
  data?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  resource: NotificationResourceNode;
  service?: Maybe<CustomerContactNotificationService>;
};

/** An enumeration. */
export enum CustomerContactNotificationService {
  App = 'APP',
  Email = 'EMAIL',
  Sms = 'SMS'
}

/** An enumeration. */
export enum CustomerDomainType {
  Business = 'BUSINESS',
  Resident = 'RESIDENT'
}

export type CustomerLocationNode = Node & {
  __typename?: 'CustomerLocationNode';
  customer: CustomerNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** An arbitrary string-identifier of the location in question. This is typically the name of the city, neighborhood etc. of the customer. */
  location: Scalars['String'];
};

export type CustomerLocationNodeConnection = {
  __typename?: 'CustomerLocationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerLocationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomerLocationNode` and its cursor. */
export type CustomerLocationNodeEdge = {
  __typename?: 'CustomerLocationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CustomerLocationNode>;
};

/**
 * This object contains fields that a customer logged in via the app
 * can use to query his/her own data.
 */
export type CustomerMe = {
  __typename?: 'CustomerMe';
  contact?: Maybe<CustomerContactNode>;
  customer?: Maybe<CustomerNode>;
  distributor?: Maybe<DistributorNode>;
  orders?: Maybe<Array<Maybe<CylinderGroupOrderNode>>>;
  setup?: Maybe<CustomerSetupInfo>;
};

export type CustomerNode = Node & {
  __typename?: 'CustomerNode';
  /** The address of the customer. Can, but must not include information like postal code, city, and so on. */
  address: Scalars['String'];
  /** Defines is customer is always available for delivery and thus ignoring standard opening hours */
  alwaysOpen: Scalars['Boolean'];
  /** The email of the contact person at the customer. */
  contactPersonEmail?: Maybe<Scalars['String']>;
  /** The name of the contact person at the customer. */
  contactPersonName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customerDomainType?: Maybe<CustomerDomainType>;
  /** A unique customer identifier */
  customerIdentifier?: Maybe<Scalars['String']>;
  /** Cylinder groups */
  cylinderGroups?: Maybe<Array<Maybe<CylinderGroupNode>>>;
  /** Which depot does the customer belong to? */
  depot?: Maybe<DepotNode>;
  /** The distributor to which this customer belong. */
  distributor: DistributorNode;
  distributorSpecificTypes?: Maybe<Array<Maybe<DistributorCustomerTypeNode>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  /** The latitude of the location of the customer. */
  latitude?: Maybe<Scalars['Float']>;
  /** The longitude of the location of the customer. */
  longitude?: Maybe<Scalars['Float']>;
  /** The name of the customer. */
  name: Scalars['String'];
  numberOfCylinders?: Maybe<Scalars['Int']>;
  orderMethod: CustomerOrderMethod;
  /** The phone number of the customer. */
  phoneNumber?: Maybe<Scalars['String']>;
  rawId?: Maybe<Scalars['Int']>;
  standardOpeningHours?: Maybe<Array<Maybe<CustomerStandardOpeningHoursNode>>>;
  /** [Deprecated] The type of customer. Will be replaced with distributor-specific types. */
  type: CustomerType;
  updatedAt: Scalars['DateTime'];
};

export type CustomerNodeConnection = {
  __typename?: 'CustomerNodeConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CustomerNode` and its cursor. */
export type CustomerNodeEdge = {
  __typename?: 'CustomerNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CustomerNode>;
};

/** An enumeration. */
export enum CustomerOrderMethod {
  /** App */
  App = 'APP',
  /** Auto */
  Auto = 'AUTO',
  /** Phone */
  Phone = 'PHONE'
}

export type CustomerOrderMethodStats = {
  __typename?: 'CustomerOrderMethodStats';
  count?: Maybe<Scalars['Int']>;
  orderMethod?: Maybe<Scalars['String']>;
};

export type CustomerPlaceOrder = {
  __typename?: 'CustomerPlaceOrder';
  order?: Maybe<CylinderGroupOrderNode>;
};

export type CustomerSetupInfo = {
  __typename?: 'CustomerSetupInfo';
  becameEmptyAt?: Maybe<Scalars['DateTime']>;
  cylinderGroup?: Maybe<CylinderGroupNode>;
  hasEmptySide?: Maybe<Scalars['Boolean']>;
  lastOrderAt?: Maybe<Scalars['DateTime']>;
  sides?: Maybe<Array<Maybe<CustomerSideInfo>>>;
};

export type CustomerSideInfo = {
  __typename?: 'CustomerSideInfo';
  cycle?: Maybe<CycleNode>;
  daysUntilEmpty?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isEmpty?: Maybe<Scalars['Boolean']>;
  monitored?: Maybe<Scalars['Boolean']>;
  numberOfCylinders?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  sensors?: Maybe<Array<Maybe<CustomerSideSensorInfo>>>;
  state?: Maybe<Scalars['String']>;
};

export type CustomerSideSensorInfo = {
  __typename?: 'CustomerSideSensorInfo';
  gasType?: Maybe<Scalars['String']>;
  gasWeight?: Maybe<Scalars['Float']>;
  installedAt?: Maybe<Scalars['DateTime']>;
  totalWeight?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type CustomerStandardOpeningHoursNode = Node & {
  __typename?: 'CustomerStandardOpeningHoursNode';
  /** Closing hours of the customer. I.e. the latest time one can visit the customer. If this is before `opens_at` it should interpreted as the next day */
  closesAt: Scalars['Time'];
  customer: CustomerNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Opening hours of the customer. I.e. the earliest time one can visit the customer. */
  opensAt: Scalars['Time'];
  weekday: CustomerStandardOpeningHoursWeekday;
};

/** An enumeration. */
export enum CustomerStandardOpeningHoursWeekday {
  /** Friday */
  Friday = 'FRIDAY',
  /** Monday */
  Monday = 'MONDAY',
  /** Saturday */
  Saturday = 'SATURDAY',
  /** Sunday */
  Sunday = 'SUNDAY',
  /** Thursday */
  Thursday = 'THURSDAY',
  /** Tuesday */
  Tuesday = 'TUESDAY',
  /** Wednesday */
  Wednesday = 'WEDNESDAY'
}

export type CustomerStats = {
  __typename?: 'CustomerStats';
  /** Number of customers with empty side */
  hasEmptySide?: Maybe<Scalars['Int']>;
  /** Number of other customers */
  other?: Maybe<Scalars['Int']>;
  /** Number of private customers */
  private?: Maybe<Scalars['Int']>;
  /** Number of restaurants */
  restaurants?: Maybe<Scalars['Int']>;
  /** Number of clients */
  total?: Maybe<Scalars['Int']>;
};

export type CustomerTheme = {
  __typename?: 'CustomerTheme';
  theme?: Maybe<DistributorThemeNode>;
};

/** An enumeration. */
export enum CustomerType {
  /** Other */
  Ot = 'OT',
  /** Private */
  Pr = 'PR',
  /** Restaurant */
  Re = 'RE'
}

export type CycleErrorCodeNode = Node & {
  __typename?: 'CycleErrorCodeNode';
  errorDescription: Scalars['String'];
  errorDisplay: Scalars['String'];
  errorName: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type CycleEventsNode = Node & {
  __typename?: 'CycleEventsNode';
  calculationId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['DateTime'];
  cycle: CycleNode;
  fromValue?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  key: Scalars['String'];
  toValue?: Maybe<Scalars['String']>;
};

export type CycleEventsNodeConnection = {
  __typename?: 'CycleEventsNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CycleEventsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CycleEventsNode` and its cursor. */
export type CycleEventsNodeEdge = {
  __typename?: 'CycleEventsNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CycleEventsNode>;
};

export type CycleNode = Node & {
  __typename?: 'CycleNode';
  cgSide: CylinderGroupSideNode;
  createdAt: Scalars['DateTime'];
  currentWeight?: Maybe<Scalars['Float']>;
  customTareWeight?: Maybe<Scalars['Float']>;
  depletionDate?: Maybe<Scalars['DateTime']>;
  depletionRate?: Maybe<Scalars['Float']>;
  depletionRateCycle?: Maybe<Scalars['Float']>;
  emptyDate?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  error?: Maybe<Scalars['String']>;
  errorCode?: Maybe<CycleErrorCodeNode>;
  estimatedEmpty?: Maybe<Scalars['DateTime']>;
  firstUpdatedTs?: Maybe<Scalars['Float']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  last?: Maybe<Scalars['DateTime']>;
  lastCalculation?: Maybe<Scalars['UUID']>;
  lastDepCoef?: Maybe<Scalars['Float']>;
  lastDepIntercept?: Maybe<Scalars['Float']>;
  lastLogStream?: Maybe<Scalars['String']>;
  lastUpdatedIndex?: Maybe<Scalars['String']>;
  lastUpdatedTs?: Maybe<Scalars['Float']>;
  lastWeight?: Maybe<Scalars['Float']>;
  monitoredCycle?: Maybe<CycleNode>;
  noise?: Maybe<Scalars['Float']>;
  orders?: Maybe<Array<Maybe<CylinderGroupOrderNode>>>;
  predictions?: Maybe<Scalars['JSONString']>;
  prevCycle?: Maybe<CycleNode>;
  refillDate?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
  startWeight?: Maybe<Scalars['Float']>;
  state?: Maybe<CycleState>;
  stopDate?: Maybe<Scalars['DateTime']>;
  tareWeight?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type CycleNodeConnection = {
  __typename?: 'CycleNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CycleNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CycleNode` and its cursor. */
export type CycleNodeEdge = {
  __typename?: 'CycleNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CycleNode>;
};

/** An enumeration. */
export enum CycleState {
  Calibrating = 'CALIBRATING',
  Depleting = 'DEPLETING',
  Empty = 'EMPTY',
  Error = 'ERROR',
  Full = 'FULL',
  Idling = 'IDLING',
  Init = 'INIT',
  NotInUse = 'NOT_IN_USE',
  Unknown = 'UNKNOWN'
}

/** An enumeration. */
export enum CylinderGroupGasType {
  /** CO2 */
  Co2 = 'CO2',
  /** Propane */
  Propane = 'PROPANE'
}

/** An enumeration. */
export enum CylinderGroupMode {
  Insitu = 'INSITU',
  Parallel = 'PARALLEL',
  Standard = 'STANDARD'
}

/** CylinderGroupNode provides a GraphQL-Relay interface to the CylinderGroup model. */
export type CylinderGroupNode = Node & {
  __typename?: 'CylinderGroupNode';
  activeSide?: Maybe<CylinderGroupSideNode>;
  activeSideId?: Maybe<Scalars['ID']>;
  awaitingDelivery?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  customer: CustomerNode;
  cylinderMode?: Maybe<CylinderGroupMode>;
  cylinderSides?: Maybe<Array<Maybe<CylinderGroupSideNode>>>;
  /** The type of gas of the cylinder group. */
  gasType?: Maybe<CylinderGroupGasType>;
  hasEmptySide?: Maybe<Scalars['Boolean']>;
  hasPendingRefill?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  lastOrder?: Maybe<CylinderGroupOrderNode>;
  localThreshold?: Maybe<Scalars['Float']>;
  /** An optional identifier. Useful if there are multiple cylinder groups per customer. */
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<CylinderGroupNoteNode>>>;
  numberOfPassiveSideSensors?: Maybe<Scalars['Int']>;
  numberOfSides?: Maybe<Scalars['Int']>;
  orders?: Maybe<Array<Maybe<CylinderGroupOrderNode>>>;
  sensors?: Maybe<Array<Maybe<SensorNode>>>;
  /** The standard cylinder gas weight for this cylinder group. */
  standardCylinderGasWeight?: Maybe<Scalars['Int']>;
  stock?: Maybe<StockNode>;
  threshold?: Maybe<DistributorRefillThresholdNode>;
  updatedAt: Scalars['DateTime'];
};

export type CylinderGroupNodeConnection = {
  __typename?: 'CylinderGroupNodeConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CylinderGroupNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CylinderGroupNode` and its cursor. */
export type CylinderGroupNodeEdge = {
  __typename?: 'CylinderGroupNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CylinderGroupNode>;
};

export type CylinderGroupNoteNode = Node & {
  __typename?: 'CylinderGroupNoteNode';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserNode>;
  cylinderGroup: CylinderGroupNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  note: Scalars['String'];
  noteType: CylinderGroupNoteTypeList;
  severity?: Maybe<CylinderGroupNoteSeverity>;
  severityNumber?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum CylinderGroupNoteSeverity {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL'
}

export type CylinderGroupNoteTypeList = {
  __typename?: 'CylinderGroupNoteTypeList';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  translationKey?: Maybe<Scalars['String']>;
};

export type CylinderGroupNoteTypeNode = Node & {
  __typename?: 'CylinderGroupNoteTypeNode';
  description?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  translationKey?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum CylinderGroupOrderGasType {
  /** CO2 */
  Co2 = 'CO2',
  /** Propane */
  Propane = 'PROPANE'
}

export type CylinderGroupOrderNode = Node & {
  __typename?: 'CylinderGroupOrderNode';
  /** The person that has added the order, if any. */
  addedBy?: Maybe<UserNode>;
  /** If true, the order was added by the customer (from the app). */
  addedByCustomer: Scalars['Boolean'];
  /** If true, the order was made automatically by the Smart Cylinders system */
  automatic: Scalars['Boolean'];
  /** Any additional information pertinent to the order. */
  comment: Scalars['String'];
  confirmedAt?: Maybe<Scalars['DateTime']>;
  /** When the order was placed. */
  createdAt: Scalars['DateTime'];
  cycle?: Maybe<CycleNode>;
  /** The associated cylinder group */
  cylinderGroup: CylinderGroupNode;
  /** The associated cylinder group side */
  cylinderGroupSide?: Maybe<CylinderGroupSideNode>;
  /** The date the order was delivered. */
  deliveredAt?: Maybe<Scalars['DateTime']>;
  firstPossibleDelivery?: Maybe<Scalars['DateTime']>;
  /** The cylinder gas type for this particular order. If null, this vlaue will be inherited from the cylinder group. */
  gasType?: Maybe<CylinderGroupOrderGasType>;
  gasWeight?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  inStops?: Maybe<Array<Maybe<StopNode>>>;
  lastPossibleDelivery?: Maybe<Scalars['DateTime']>;
  /** The number of cylinders to be delivered. */
  numberOfCylinders: Scalars['Int'];
  orderedByContact?: Maybe<CustomerContactNode>;
  orderedByType?: Maybe<Scalars['String']>;
  /** If true, this order was placed with a request for future orders to be automatic. */
  requestForAutomaticOrdering: Scalars['Boolean'];
  state?: Maybe<CylinderGroupOrderStates>;
  stateId?: Maybe<Scalars['Int']>;
  trigger?: Maybe<DistributorRefillThresholdNode>;
  unassigned?: Maybe<RouteUnassignedNode>;
  /** The last time the order was updated. */
  updatedAt: Scalars['DateTime'];
};

export type CylinderGroupOrderNodeConnection = {
  __typename?: 'CylinderGroupOrderNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CylinderGroupOrderNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CylinderGroupOrderNode` and its cursor. */
export type CylinderGroupOrderNodeEdge = {
  __typename?: 'CylinderGroupOrderNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CylinderGroupOrderNode>;
};

/** An enumeration. */
export enum CylinderGroupOrderStates {
  AwaitingDelivery = 'AWAITING_DELIVERY',
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Delivered = 'DELIVERED'
}

export type CylinderGroupSettingNode = Node & {
  __typename?: 'CylinderGroupSettingNode';
  cylinderGroup: CylinderGroupNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The key of the setting. Every setting is a key-value pair. */
  key: Scalars['String'];
  /** The value of the setting. Every setting is a key-value pair. */
  value: Scalars['String'];
};

export type CylinderGroupSettingNodeConnection = {
  __typename?: 'CylinderGroupSettingNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CylinderGroupSettingNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CylinderGroupSettingNode` and its cursor. */
export type CylinderGroupSettingNodeEdge = {
  __typename?: 'CylinderGroupSettingNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CylinderGroupSettingNode>;
};

export type CylinderGroupSideList = {
  __typename?: 'CylinderGroupSideList';
  activeCycle?: Maybe<CycleNode>;
  createdAt: Scalars['DateTime'];
  cylinderCount: Scalars['Int'];
  cylinderGroup: CylinderGroupNode;
  estimatedEmpty?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  /** The associated cylinder group side */
  orders: CylinderGroupOrderNodeConnection;
  priority: Scalars['Int'];
  /** Related cylinder group side of the sensor */
  sensors: SensorNodeConnection;
  state: CylinderGroupSideState;
  stateUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};


export type CylinderGroupSideListOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CylinderGroupSideListSensorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CylinderGroupSideNode = Node & {
  __typename?: 'CylinderGroupSideNode';
  activeCycle?: Maybe<CycleNode>;
  createdAt: Scalars['DateTime'];
  cylinderCount: Scalars['Int'];
  cylinderGroup: CylinderGroupNode;
  estimatedEmpty?: Maybe<Scalars['Date']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The associated cylinder group side */
  orders: CylinderGroupOrderNodeConnection;
  priority: Scalars['Int'];
  sensors?: Maybe<Array<Maybe<SensorNode>>>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['Int']>;
  stateUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};


export type CylinderGroupSideNodeOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CylinderGroupSideNodeConnection = {
  __typename?: 'CylinderGroupSideNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CylinderGroupSideNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CylinderGroupSideNode` and its cursor. */
export type CylinderGroupSideNodeEdge = {
  __typename?: 'CylinderGroupSideNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CylinderGroupSideNode>;
};

/** An enumeration. */
export enum CylinderGroupSideState {
  /** Init */
  A_1 = 'A_1',
  /** Full */
  A_2 = 'A_2',
  /** Depleting */
  A_3 = 'A_3',
  /** Empty */
  A_4 = 'A_4',
  /** Unknown */
  A_5 = 'A_5',
  /** Idling */
  A_6 = 'A_6'
}

export type CylinderSideFlipMutation = {
  __typename?: 'CylinderSideFlipMutation';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type DecodeSensorSerialResult = {
  __typename?: 'DecodeSensorSerialResult';
  ok?: Maybe<Scalars['Boolean']>;
  sensor?: Maybe<SensorNode>;
  /** The fully decoded sensor serial */
  sensorSerial?: Maybe<Scalars['String']>;
};

export type DeleteAllWorkhoursMutation = {
  __typename?: 'DeleteAllWorkhoursMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteBoxMutation = {
  __typename?: 'DeleteBoxMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteBoxOrderMutation = {
  __typename?: 'DeleteBoxOrderMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteCustomerContactNotificationMutation = {
  __typename?: 'DeleteCustomerContactNotificationMutation';
  deletedId?: Maybe<Scalars['ID']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteDistributorRefillThresholdMutation = {
  __typename?: 'DeleteDistributorRefillThresholdMutation';
  deletedId?: Maybe<Scalars['ID']>;
  deletedInputId?: Maybe<Scalars['ID']>;
  deletedRawId?: Maybe<Scalars['ID']>;
  found?: Maybe<Scalars['Boolean']>;
};

export type DeleteDriverMutation = {
  __typename?: 'DeleteDriverMutation';
  deletedId?: Maybe<Scalars['ID']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteDriverWorkhoursMutation = {
  __typename?: 'DeleteDriverWorkhoursMutation';
  deletedId?: Maybe<Scalars['ID']>;
  deletedInputId?: Maybe<Scalars['ID']>;
  deletedRawId?: Maybe<Scalars['ID']>;
  found?: Maybe<Scalars['Boolean']>;
};

export type DeleteRouteMutation = {
  __typename?: 'DeleteRouteMutation';
  deletedId?: Maybe<Scalars['ID']>;
  deletedInputId?: Maybe<Scalars['ID']>;
  deletedRawId?: Maybe<Scalars['ID']>;
  found?: Maybe<Scalars['Boolean']>;
};

export type DeleteSensorFlagMutation = {
  __typename?: 'DeleteSensorFlagMutation';
  deletedId?: Maybe<Scalars['ID']>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteStopFromRouteMutation = {
  __typename?: 'DeleteStopFromRouteMutation';
  route?: Maybe<RouteNode>;
};

export type DeleteUserMutation = {
  __typename?: 'DeleteUserMutation';
  deletedId?: Maybe<Scalars['ID']>;
  deletedInputId?: Maybe<Scalars['ID']>;
  deletedRawId?: Maybe<Scalars['ID']>;
  found?: Maybe<Scalars['Boolean']>;
};

export type DeleteVehicleExceptionMutation = {
  __typename?: 'DeleteVehicleExceptionMutation';
  deletedId?: Maybe<Scalars['ID']>;
  deletedInputId?: Maybe<Scalars['ID']>;
  deletedRawId?: Maybe<Scalars['ID']>;
  found?: Maybe<Scalars['Boolean']>;
};

export type DeleteVehicleMutation = {
  __typename?: 'DeleteVehicleMutation';
  deletedId?: Maybe<Scalars['ID']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type DepotNode = Node & {
  __typename?: 'DepotNode';
  /** The address where the depot is located. */
  address?: Maybe<Scalars['String']>;
  distributor: DistributorNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The latitude of the location of the depot. */
  latitude: Scalars['Float'];
  /** The longitude of the location of the depot. */
  longitude: Scalars['Float'];
  /** The name of the depot */
  name?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Array<Maybe<VehicleNode>>>;
};

export type DepotNodeConnection = {
  __typename?: 'DepotNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DepotNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DepotNode` and its cursor. */
export type DepotNodeEdge = {
  __typename?: 'DepotNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DepotNode>;
};

export type DispatchRouteMutation = {
  __typename?: 'DispatchRouteMutation';
  route?: Maybe<RouteNode>;
};

/** An enumeration. */
export enum DistributorCountry {
  /** Australia */
  Au = 'AU',
  /** Finland */
  Fi = 'FI',
  /** England */
  Gb = 'GB',
  /** Ireland */
  Ie = 'IE',
  /** Nigeria */
  Ng = 'NG',
  /** Norway */
  No = 'NO',
  /** New Zealand */
  Nz = 'NZ',
  /** Philippines */
  Ph = 'PH',
  /** Sweden */
  Se = 'SE',
  /** Slovenia */
  Si = 'SI',
  /** Wales */
  Wal = 'WAL',
  /** South Africa */
  Za = 'ZA'
}

/**
 * DistributorCustomerLocations is an object which contains
 * information about a number of customer locations related to a distributor.
 */
export type DistributorCustomerLocations = {
  __typename?: 'DistributorCustomerLocations';
  distributor?: Maybe<DistributorNode>;
  locations?: Maybe<Array<Maybe<CustomerLocationNode>>>;
  locationsDense?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum DistributorCustomerTypeBaseType {
  /** Commercial */
  Commercial = 'COMMERCIAL',
  /** Private */
  Private = 'PRIVATE'
}

export type DistributorCustomerTypeNode = Node & {
  __typename?: 'DistributorCustomerTypeNode';
  baseType?: Maybe<DistributorCustomerTypeBaseType>;
  distributor: DistributorNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DistributorCustomerTypeNodeConnection = {
  __typename?: 'DistributorCustomerTypeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DistributorCustomerTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DistributorCustomerTypeNode` and its cursor. */
export type DistributorCustomerTypeNodeEdge = {
  __typename?: 'DistributorCustomerTypeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DistributorCustomerTypeNode>;
};

export type DistributorGroupNode = Node & {
  __typename?: 'DistributorGroupNode';
  children?: Maybe<Array<Maybe<DistributorGroupNode>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  key: Scalars['String'];
  /** The name of the group. */
  name: Scalars['String'];
  parent?: Maybe<DistributorGroupNode>;
  parentKey?: Maybe<Scalars['String']>;
};

export type DistributorGroupNodeConnection = {
  __typename?: 'DistributorGroupNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DistributorGroupNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DistributorGroupNode` and its cursor. */
export type DistributorGroupNodeEdge = {
  __typename?: 'DistributorGroupNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DistributorGroupNode>;
};

/** An enumeration. */
export enum DistributorLanguage {
  /** English */
  En = 'EN',
  /** Norway */
  Nb = 'NB'
}

export type DistributorNode = Node & {
  __typename?: 'DistributorNode';
  /** The address of the distributor's HQ. */
  address?: Maybe<Scalars['String']>;
  appHost?: Maybe<Scalars['String']>;
  /** The distributor the order has been requesten from */
  boxOrders: BoxOrderNodeConnection;
  /** The contact email of the distributor. */
  contactEmail?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<UserNode>;
  /** The contact phone number of the distributor. */
  contactPhoneNumber?: Maybe<Scalars['String']>;
  /** The country of the distributor. */
  country?: Maybe<DistributorCountry>;
  createdAt: Scalars['DateTime'];
  customerCanOrder: Scalars['Boolean'];
  customerTypes: DistributorCustomerTypeNodeConnection;
  /** The distributor to which this customer belong. */
  customers: CustomerNodeConnection;
  demoDistributor: Scalars['Boolean'];
  depots?: Maybe<Array<Maybe<DepotNode>>>;
  /** The full name of the distributor. */
  fullName?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The default communication language for the company */
  language?: Maybe<DistributorLanguage>;
  /** The short name of the distributor. This must be unique, as it determines the subdomain one can access the distributor at.  */
  name?: Maybe<Scalars['String']>;
  smsIdentifier?: Maybe<Scalars['String']>;
  /** The standard cylinder gas weight for this company. */
  standardCylinderGasWeight?: Maybe<Scalars['Int']>;
  /** The type of gas of the company. */
  standardGasType?: Maybe<DistributorStandardGasType>;
  /** Customer app theme */
  theme?: Maybe<DistributorThemeNode>;
  updatedAt: Scalars['DateTime'];
  userRoles?: Maybe<Array<Maybe<DistributorUserRoleNode>>>;
  /** All the users which are associated with this distributor. This also very loosely translates to which users have access to the distributor's data. */
  users: UserNodeConnection;
};


export type DistributorNodeBoxOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DistributorNodeCustomerTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distributor?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DistributorNodeCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DistributorNodeUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type DistributorNodeConnection = {
  __typename?: 'DistributorNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DistributorNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DistributorNode` and its cursor. */
export type DistributorNodeEdge = {
  __typename?: 'DistributorNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DistributorNode>;
};

export type DistributorRefillThresholdNode = Node & {
  __typename?: 'DistributorRefillThresholdNode';
  alertThreshold: Scalars['Float'];
  distributor: DistributorNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  threshold: Scalars['Float'];
  unit?: Maybe<DistributorRefillThresholdUnit>;
};

export type DistributorRefillThresholdNodeConnection = {
  __typename?: 'DistributorRefillThresholdNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DistributorRefillThresholdNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DistributorRefillThresholdNode` and its cursor. */
export type DistributorRefillThresholdNodeEdge = {
  __typename?: 'DistributorRefillThresholdNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DistributorRefillThresholdNode>;
};

/** An enumeration. */
export enum DistributorRefillThresholdUnit {
  Days = 'DAYS',
  Kilogram = 'KILOGRAM',
  Liter = 'LITER',
  Percent = 'PERCENT'
}

export type DistributorRoleNode = Node & {
  __typename?: 'DistributorRoleNode';
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The name of the role. */
  name: Scalars['String'];
};

export type DistributorSettingNode = Node & {
  __typename?: 'DistributorSettingNode';
  distributor: DistributorNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The key of the setting. Every setting is a key-value pair. */
  key: Scalars['String'];
  /** The value of the setting. Every setting is a key-value pair. */
  value: Scalars['String'];
};

export type DistributorSettingsMutation = {
  __typename?: 'DistributorSettingsMutation';
  distributor?: Maybe<DistributorNode>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DistributorSettingsOutput = {
  __typename?: 'DistributorSettingsOutput';
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  ssos?: Maybe<Array<Maybe<DistributorSettingsOutputSso>>>;
};

export type DistributorSettingsOutputSso = {
  __typename?: 'DistributorSettingsOutputSso';
  clientId?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider?: Maybe<DistributorSsoProvider>;
  tenant?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum DistributorSsoProvider {
  Auth0 = 'AUTH0',
  Ms = 'MS',
  Okta = 'OKTA'
}

/** An enumeration. */
export enum DistributorStandardGasType {
  /** CO2 */
  Co2 = 'CO2',
  /** Propane */
  Propane = 'PROPANE'
}

export type DistributorStats = {
  __typename?: 'DistributorStats';
  allCustomers?: Maybe<Scalars['Int']>;
  allCustomersByOrderMethod?: Maybe<Array<Maybe<CustomerOrderMethodStats>>>;
  averageCalibration?: Maybe<Scalars['Float']>;
  customersAlmostAllEmpty?: Maybe<Scalars['Int']>;
  customersAwaitingDelivery?: Maybe<Scalars['Int']>;
  customersReadyToRefill?: Maybe<Scalars['Int']>;
  customersRecentlyRefilled?: Maybe<Scalars['Int']>;
  newSensorCustomers?: Maybe<Scalars['Int']>;
  newSensors?: Maybe<Scalars['Int']>;
  sensorCustomers?: Maybe<Scalars['Int']>;
  sensorsInCalibration?: Maybe<Scalars['Int']>;
  sensorsInService?: Maybe<Scalars['Int']>;
  sensorsNotReporting?: Maybe<Scalars['Int']>;
};

export type DistributorThemeNode = Node & {
  __typename?: 'DistributorThemeNode';
  colorBackground?: Maybe<Scalars['String']>;
  colorPrimary: Scalars['String'];
  colorSecondary: Scalars['String'];
  colorTertiary: Scalars['String'];
  distributor: DistributorNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  logo: Scalars['String'];
  logoS3?: Maybe<Scalars['String']>;
};

export type DistributorUserRoleNode = Node & {
  __typename?: 'DistributorUserRoleNode';
  distributor: DistributorNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  permissionGroups?: Maybe<Array<Maybe<DistributorGroupNode>>>;
  role: DistributorRoleNode;
  userCount?: Maybe<Scalars['Int']>;
};

export type DistributorUserRoleNodeConnection = {
  __typename?: 'DistributorUserRoleNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DistributorUserRoleNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DistributorUserRoleNode` and its cursor. */
export type DistributorUserRoleNodeEdge = {
  __typename?: 'DistributorUserRoleNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DistributorUserRoleNode>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug';
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
};

export type DriverExceptionsNode = Node & {
  __typename?: 'DriverExceptionsNode';
  defaultDepot?: Maybe<DepotNode>;
  defaultVehicle?: Maybe<VehicleNode>;
  driver: DriverNode;
  endTime?: Maybe<Scalars['Time']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  onDate: Scalars['Date'];
  startTime?: Maybe<Scalars['Time']>;
  vacated: Scalars['Boolean'];
};

export type DriverExtendedNode = Node & {
  __typename?: 'DriverExtendedNode';
  defaultDepot?: Maybe<DepotNode>;
  defaultDepotId?: Maybe<Scalars['Int']>;
  defaultVehicle?: Maybe<VehicleNode>;
  distributor?: Maybe<DistributorNode>;
  driverId?: Maybe<Scalars['ID']>;
  exceptionId?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  routeDepot?: Maybe<DepotNode>;
  routeDepotId?: Maybe<Scalars['Int']>;
  routeDow?: Maybe<Scalars['Int']>;
  routeEndTime?: Maybe<Scalars['Time']>;
  routeOnDate?: Maybe<Scalars['Date']>;
  routeStartTime?: Maybe<Scalars['Time']>;
  routeVacated?: Maybe<Scalars['Boolean']>;
  routeVehicle?: Maybe<VehicleNode>;
  routeVehicleId?: Maybe<Scalars['Int']>;
  standardEndTime?: Maybe<Scalars['Time']>;
  standardStartTime?: Maybe<Scalars['Time']>;
  user: UserNode;
  vehicleActive?: Maybe<Scalars['Boolean']>;
  vehicleDepot?: Maybe<DepotNode>;
  vehicleDepotId?: Maybe<Scalars['Int']>;
  vehicleType?: Maybe<Scalars['String']>;
  vehicleVacated?: Maybe<Scalars['Boolean']>;
};

export type DriverNode = Node & {
  __typename?: 'DriverNode';
  active: Scalars['Boolean'];
  defaultDepot?: Maybe<DepotNode>;
  defaultVehicle?: Maybe<VehicleNode>;
  deleted: Scalars['Boolean'];
  distributor?: Maybe<DistributorNode>;
  hasUpcomingRoutes?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  standardEndTime?: Maybe<Scalars['Time']>;
  standardStartTime?: Maybe<Scalars['Time']>;
  standardWorkhours?: Maybe<Array<Maybe<DriverStandardWorkhourNode>>>;
  upcomingRoutes?: Maybe<Array<Maybe<RouteNode>>>;
  user: UserNode;
};

export type DriverNodeConnection = {
  __typename?: 'DriverNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DriverNode` and its cursor. */
export type DriverNodeEdge = {
  __typename?: 'DriverNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DriverNode>;
};

export type DriverStandardWorkhourNode = Node & {
  __typename?: 'DriverStandardWorkhourNode';
  dow: Scalars['Int'];
  driver: DriverNode;
  endTime: Scalars['Time'];
  /** The ID of the object. */
  id: Scalars['ID'];
  startTime: Scalars['Time'];
};

export type DriverStandardWorkhourNodeConnection = {
  __typename?: 'DriverStandardWorkhourNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverStandardWorkhourNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DriverStandardWorkhourNode` and its cursor. */
export type DriverStandardWorkhourNodeEdge = {
  __typename?: 'DriverStandardWorkhourNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DriverStandardWorkhourNode>;
};

export type DriverWorkhourNode = Node & {
  __typename?: 'DriverWorkhourNode';
  driver: DriverNode;
  endTime: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  startTime: Scalars['DateTime'];
};

export type DriverWorkhourNodeConnection = {
  __typename?: 'DriverWorkhourNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverWorkhourNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DriverWorkhourNode` and its cursor. */
export type DriverWorkhourNodeEdge = {
  __typename?: 'DriverWorkhourNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DriverWorkhourNode>;
};

export type EditNotificationListenerMutation = {
  __typename?: 'EditNotificationListenerMutation';
  error?: Maybe<Scalars['String']>;
  notificationListener?: Maybe<NotificationListenerNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type ExtendedGroupNode = {
  __typename?: 'ExtendedGroupNode';
  frontend?: Maybe<FrontendNode>;
  name: Scalars['String'];
};

export type FinalizeSensorRegistration = {
  __typename?: 'FinalizeSensorRegistration';
  ok?: Maybe<Scalars['Boolean']>;
  sensor?: Maybe<SensorNode>;
};

export type ForgotPasswordMutation = {
  __typename?: 'ForgotPasswordMutation';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type FrontendNode = {
  __typename?: 'FrontendNode';
  name: Scalars['String'];
  prefixUrl: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum GqlErrors {
  AccessDenied = 'ACCESS_DENIED',
  DeniedOrDoesntExist = 'DENIED_OR_DOESNT_EXIST',
  DistributorMissing = 'DISTRIBUTOR_MISSING',
  LoginFailed = 'LOGIN_FAILED',
  ObjectExists = 'OBJECT_EXISTS',
  ObjectNotUpToDate = 'OBJECT_NOT_UP_TO_DATE',
  PermissionDenied = 'PERMISSION_DENIED',
  SensorMissing = 'SENSOR_MISSING',
  SqlConstraintError = 'SQL_CONSTRAINT_ERROR',
  Unknown = 'UNKNOWN'
}

export enum GasTypeEnum {
  Co2 = 'CO2',
  Propane = 'PROPANE'
}

export type GenerateOrdersMutation = {
  __typename?: 'GenerateOrdersMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type GetSensorFeedbackKeyVal = {
  __typename?: 'GetSensorFeedbackKeyVal';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Any']>;
};

export type GetSensorFeedbackResult = {
  __typename?: 'GetSensorFeedbackResult';
  calibrationGrams?: Maybe<Scalars['Int']>;
  calibrationValue?: Maybe<Scalars['Int']>;
  calibrationZero?: Maybe<Scalars['Int']>;
  hasFeedback?: Maybe<Scalars['Boolean']>;
  keyValuePairs?: Maybe<Array<Maybe<GetSensorFeedbackKeyVal>>>;
  newMeasurementInterval?: Maybe<Scalars['Int']>;
  newTransmissionInterval?: Maybe<Scalars['Int']>;
  recalibrate?: Maybe<Scalars['Int']>;
};

export type GroupNode = {
  __typename?: 'GroupNode';
  extendedGroups?: Maybe<ExtendedGroupNode>;
  name: Scalars['String'];
};

export type InputCreateCustomer = {
  address: Scalars['String'];
  customerDomainType: CustomerDomainType;
  customerIdentifier?: InputMaybe<Scalars['String']>;
  cylinderGroups?: InputMaybe<Array<InputMaybe<InputCylinderGroup>>>;
  depot: Scalars['ID'];
  latitude: Scalars['Decimal'];
  longitude: Scalars['Decimal'];
  name: Scalars['String'];
  orderMethod: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type InputCylinderGroup = {
  cylinderSides?: InputMaybe<Array<InputMaybe<InputCylinderGroupSide>>>;
  gasType: Scalars['String'];
  standardCylinderGasWeight: Scalars['Int'];
  thresholdId?: InputMaybe<Scalars['ID']>;
};

export type InputCylinderGroupNoteMutation = {
  note?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<CylinderGroupNoteSeverity>;
};

export type InputCylinderGroupSide = {
  cylinderCount: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
};

export type InputCylinderGroupSideMutation = {
  cylinderCount: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
};

export type InputPatchBoxMutation = {
  allocatedDate?: InputMaybe<Scalars['DateTime']>;
  boxOrder?: InputMaybe<Scalars['ID']>;
  isShipped?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['ID']>;
  sensors?: InputMaybe<Array<InputMaybe<SensorInput>>>;
  shippedDate?: InputMaybe<Scalars['DateTime']>;
};

export type InputPatchCylinderGroup = {
  cylinderMode?: InputMaybe<CylinderGroupMode>;
  cylinderSides?: InputMaybe<Array<InputMaybe<InputCylinderGroupSideMutation>>>;
  gasType?: InputMaybe<Scalars['String']>;
  standardCylinderGasWeight?: InputMaybe<Scalars['Int']>;
  stockId?: InputMaybe<Scalars['ID']>;
  thresholdId?: InputMaybe<Scalars['ID']>;
};

export type InputPatchSensor = {
  calibrationGrams?: InputMaybe<Scalars['Int']>;
  calibrationValue?: InputMaybe<Scalars['Int']>;
  calibrationZero?: InputMaybe<Scalars['Int']>;
  cgSide?: InputMaybe<Scalars['ID']>;
  model?: InputMaybe<Scalars['ID']>;
};

/** An enumeration. */
export enum IntervalStep {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type LocationNode = Node & {
  __typename?: 'LocationNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The name of the box */
  name: Scalars['String'];
};

export type LoginCustomer = {
  __typename?: 'LoginCustomer';
  loginToken?: Maybe<Scalars['String']>;
};

export type LoginMutation = {
  __typename?: 'LoginMutation';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
};

export type MoveRouteMutation = {
  __typename?: 'MoveRouteMutation';
  error?: Maybe<Scalars['String']>;
  route?: Maybe<RouteNode>;
};

export type Mutations = {
  __typename?: 'Mutations';
  addCylinderGroupTag?: Maybe<AddCylinderGroupTagMutation>;
  addCylinderNote?: Maybe<AddCylinderGroupNoteMutation>;
  addPermissionGroup?: Maybe<AddPermissionGroup>;
  assignBoxesToOrder?: Maybe<AssignBoxesToOrderMutation>;
  cancelRoute?: Maybe<CancelRouteMutation>;
  cancelStop?: Maybe<CancelStopMutation>;
  changeDriverOnRoute?: Maybe<ChangeDriverOnRouteMutation>;
  changePassword?: Maybe<ChangePasswordMutation>;
  clearAllCylinderGroupTags?: Maybe<ClearAllCylinderGroupTagsMutation>;
  completeRoute?: Maybe<CompleteRouteMutation>;
  completeRouteAndStops?: Maybe<CompleteRouteAndStopsMutation>;
  completeStop?: Maybe<CompleteStopMutation>;
  createBox?: Maybe<CreateBoxMutation>;
  createBoxOrder?: Maybe<CreateBoxOrderMutation>;
  createCustomer?: Maybe<CreateCustomerMutation>;
  createCustomerContact?: Maybe<CreateCustomerContactMutation>;
  createCustomerContactNotification?: Maybe<CreateCustomerContactNotificationMutation>;
  createCustomerType?: Maybe<CreateCustomerTypeMutation>;
  createCycle?: Maybe<CreateCycleMutation>;
  createCylinderGroupOrder?: Maybe<CreateCylinderGroupOrder>;
  createDepot?: Maybe<CreateDepotMutation>;
  createDistributorRefillThreshold?: Maybe<CreateDistributorRefillThresholdMutation>;
  createDriver?: Maybe<CreateDriverMutation>;
  createDriverExceptions?: Maybe<CreateDriverExceptionsMutation>;
  createNotificationListener?: Maybe<CreateNotificationListenerMutation>;
  createOrPatchDriverWorkhours?: Maybe<CreateOrPatchDriverWorkhourMutation>;
  createRoute?: Maybe<CreateRouteMutation>;
  createSensor?: Maybe<CreateSensorMutation>;
  createSensorFlag?: Maybe<CreateSensorFlagMutation>;
  createSensorNew?: Maybe<CreateSensorNewMutation>;
  createStock?: Maybe<CreateStockMutation>;
  createStop?: Maybe<CreateStopMutation>;
  createUser?: Maybe<CreateUserMutation>;
  createVehicle?: Maybe<CreateVehicleMutation>;
  createVehicleException?: Maybe<CreateVehicleExceptionMutation>;
  customerPlaceOrder?: Maybe<CustomerPlaceOrder>;
  cylinderSidesFlip?: Maybe<CylinderSideFlipMutation>;
  deleteAllWorkhours?: Maybe<DeleteAllWorkhoursMutation>;
  deleteBox?: Maybe<DeleteBoxMutation>;
  deleteBoxOrder?: Maybe<DeleteBoxOrderMutation>;
  deleteCustomerContactNotification?: Maybe<DeleteCustomerContactNotificationMutation>;
  deleteDistributorRefillThreshold?: Maybe<DeleteDistributorRefillThresholdMutation>;
  deleteDriver?: Maybe<DeleteDriverMutation>;
  deleteDriverWorkhours?: Maybe<DeleteDriverWorkhoursMutation>;
  deleteRoute?: Maybe<DeleteRouteMutation>;
  deleteSensorFlag?: Maybe<DeleteSensorFlagMutation>;
  deleteStopFromRoute?: Maybe<DeleteStopFromRouteMutation>;
  deleteUser?: Maybe<DeleteUserMutation>;
  deleteVehicle?: Maybe<DeleteVehicleMutation>;
  deleteVehicleException?: Maybe<DeleteVehicleExceptionMutation>;
  dispatchRoute?: Maybe<DispatchRouteMutation>;
  editNotificationListener?: Maybe<EditNotificationListenerMutation>;
  finalizeSensorRegistration?: Maybe<FinalizeSensorRegistration>;
  forgotPassword?: Maybe<ForgotPasswordMutation>;
  generateOrders?: Maybe<GenerateOrdersMutation>;
  login?: Maybe<LoginMutation>;
  loginCustomer?: Maybe<LoginCustomer>;
  moveRoute?: Maybe<MoveRouteMutation>;
  mutateDistributorSetting?: Maybe<DistributorSettingsMutation>;
  mutateUserSetting?: Maybe<UserSettingsMutation>;
  newCustomerSensorRegistration?: Maybe<NewCustomerSensorRegistration>;
  newSensorRegistration?: Maybe<NewSensorRegistration>;
  patchBox?: Maybe<PatchBoxMutation>;
  patchCustomer?: Maybe<PatchCustomerMutation>;
  patchCustomerContact?: Maybe<PatchCustomerContactMutation>;
  patchCustomerContactNotification?: Maybe<PatchCustomerContactNotificationMutation>;
  patchCustomerType?: Maybe<PatchCustomerTypeMutation>;
  patchCycleState?: Maybe<PatchCycleStateMutation>;
  patchCylinderGroup?: Maybe<PatchCylinderGroupMutation>;
  patchCylinderGroupOrder?: Maybe<PatchCylinderGroupOrderMutation>;
  patchCylinderGroupSide?: Maybe<PatchCylinderGroupSideMutation>;
  patchCylinderGroupSideState?: Maybe<PatchCylinderGroupSideStateMutation>;
  patchCylinderNote?: Maybe<PatchCylinderGroupNoteMutation>;
  patchDepot?: Maybe<PatchDepotMutation>;
  patchDistributor?: Maybe<PatchDistributorMutation>;
  patchDistributorRefillThreshold?: Maybe<PatchDistributorRefillThresholdMutation>;
  patchDriver?: Maybe<PatchDriverMutation>;
  patchDriverExceptions?: Maybe<PatchDriverExceptionsMutation>;
  patchRoute?: Maybe<PatchRouteMutation>;
  patchSensor?: Maybe<PatchSensorMutation>;
  patchStop?: Maybe<PatchStopMutation>;
  patchUser?: Maybe<PatchUserMutation>;
  patchVehicle?: Maybe<PatchVehicleMutation>;
  patchVehicleException?: Maybe<PatchVehicleExceptionMutation>;
  planRoutes?: Maybe<PlanRoutes>;
  populateVehicles?: Maybe<PopulateVehicles>;
  redoRoute?: Maybe<RedoRouteMutation>;
  refreshLoginToken?: Maybe<RefreshLoginTokenMutation>;
  registerAppVersion?: Maybe<RegisterAppVersionMutation>;
  removeCylinderGroupAwaitingDelivery?: Maybe<RemoveCylinderGroupAwaitingDelivery>;
  removeCylinderGroupAwaitingOrder?: Maybe<RemoveCylinderGroupAwaitingOrder>;
  removeCylinderGroupOrder?: Maybe<RemoveCylinderGroupOrder>;
  removeCylinderGroupTag?: Maybe<RemoveCylinderGroupTagMutation>;
  removeCylinderNote?: Maybe<RemoveCylinderGroupNoteMutation>;
  removePermissionGroup?: Maybe<RemovePermissionGroup>;
  reoptimizeRoutesOnDate?: Maybe<ReoptimizeRoutesOnDate>;
  requestCustomerLoginToken?: Maybe<RequestCustomerLoginTokenMutation>;
  resetOrders?: Maybe<ResetOrdersMutation>;
  resetPassword?: Maybe<ResetPasswordMutation>;
  resetRoutes?: Maybe<ResetRoutesMutation>;
  sensorAuth?: Maybe<SensorAuth>;
  sensorCompleteProvisioning?: Maybe<SensorCompleteProvisioningMutation>;
  setCylinderGroupAwaitingDelivery?: Maybe<SetCylinderGroupAwaitingDelivery>;
  setCylinderGroupAwaitingOrder?: Maybe<SetCylinderGroupAwaitingOrder>;
  setCylinderGroupPendingRefill?: Maybe<SetCylinderGroupPendingRefill>;
  setCylinderGroupReceivedDelivery?: Maybe<SetCylinderGroupReceivedDelivery>;
  setSensorAsWaitingForCalibration?: Maybe<SetSensorAsWaitingForCalibrationMutation>;
  setSensorCalibrationZero?: Maybe<SetSensorCalibrationZero>;
  setUserAsDriver?: Maybe<SetUserAsDriver>;
  signup?: Maybe<SignupMutation>;
  startRoute?: Maybe<StartRouteMutation>;
  updateCycle?: Maybe<UpdateCycleMutation>;
  updateSensorEstimatedEmpty?: Maybe<UpdateSensorEstimatedEmpty>;
  updateStock?: Maybe<PatchStockMutation>;
  uploadCylinderGroupImage?: Maybe<UploadCylinderGroupImageMutation>;
  validateMsToken?: Maybe<ValidateMsToken>;
  validateResetToken?: Maybe<ValidateResetTokenMutation>;
};


export type MutationsAddCylinderGroupTagArgs = {
  cylinderGroup: Scalars['String'];
  removeOthers?: InputMaybe<Scalars['Boolean']>;
  tag: Scalars['String'];
};


export type MutationsAddCylinderNoteArgs = {
  cylinderGroup: Scalars['ID'];
  note: Scalars['String'];
  noteType: Scalars['ID'];
  severity: CylinderGroupNoteSeverity;
};


export type MutationsAddPermissionGroupArgs = {
  distributorGroup: Scalars['ID'];
  distributorUserRole: Scalars['ID'];
};


export type MutationsAssignBoxesToOrderArgs = {
  boxOrder: Scalars['ID'];
  boxes?: InputMaybe<Array<InputMaybe<BoxInput>>>;
};


export type MutationsCancelRouteArgs = {
  route: Scalars['ID'];
};


export type MutationsCancelStopArgs = {
  stop: Scalars['ID'];
};


export type MutationsChangeDriverOnRouteArgs = {
  driver: Scalars['ID'];
  route: Scalars['ID'];
};


export type MutationsChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  user?: InputMaybe<Scalars['String']>;
};


export type MutationsClearAllCylinderGroupTagsArgs = {
  cylinderGroup: Scalars['String'];
};


export type MutationsCompleteRouteArgs = {
  route: Scalars['ID'];
};


export type MutationsCompleteRouteAndStopsArgs = {
  route: Scalars['ID'];
};


export type MutationsCompleteStopArgs = {
  comment?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  stockCount?: InputMaybe<Scalars['Int']>;
  stop: Scalars['ID'];
};


export type MutationsCreateBoxArgs = {
  location?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  sensors?: InputMaybe<Array<InputMaybe<SensorInput>>>;
};


export type MutationsCreateBoxOrderArgs = {
  boxesOrdered: Scalars['Int'];
  distributorId: Scalars['ID'];
  sensorModelId: Scalars['ID'];
};


export type MutationsCreateCustomerArgs = {
  input: InputCreateCustomer;
};


export type MutationsCreateCustomerContactArgs = {
  input: CreateCustomerContactInput;
};


export type MutationsCreateCustomerContactNotificationArgs = {
  input: CreateCustomerContactNotificationInput;
};


export type MutationsCreateCustomerTypeArgs = {
  input: CreateDistributorCustomerTypeInput;
};


export type MutationsCreateCycleArgs = {
  input: CreateCycleInput;
};


export type MutationsCreateCylinderGroupOrderArgs = {
  cylinderGroup: Scalars['String'];
  firstPossibleDeliveryDate?: InputMaybe<Scalars['Date']>;
  gasType?: InputMaybe<Scalars['String']>;
  gasWeight?: InputMaybe<Scalars['Int']>;
  lastPossibleDeliveryDate?: InputMaybe<Scalars['Date']>;
  numberOfCylinders?: InputMaybe<Scalars['Int']>;
  orderedBy?: InputMaybe<Scalars['ID']>;
};


export type MutationsCreateDepotArgs = {
  input: CreateDepotInput;
};


export type MutationsCreateDistributorRefillThresholdArgs = {
  input: CreateDistributorRefillThresholdInput;
};


export type MutationsCreateDriverArgs = {
  depot?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  vehicle: Scalars['ID'];
};


export type MutationsCreateDriverExceptionsArgs = {
  input: CreateDriverExceptionsInput;
};


export type MutationsCreateNotificationListenerArgs = {
  backend?: InputMaybe<NotificationBackendHandler>;
  topics?: InputMaybe<Array<InputMaybe<NotificationListenerTopicInput>>>;
};


export type MutationsCreateOrPatchDriverWorkhoursArgs = {
  dow: Scalars['Int'];
  driver: Scalars['ID'];
  end: Scalars['Time'];
  start: Scalars['Time'];
};


export type MutationsCreateRouteArgs = {
  input: CreateRouteInput;
};


export type MutationsCreateSensorArgs = {
  model: Scalars['ID'];
};


export type MutationsCreateSensorFlagArgs = {
  comment?: InputMaybe<Scalars['String']>;
  flag: SensorFlagEnum;
  serialNumber: Scalars['String'];
};


export type MutationsCreateSensorNewArgs = {
  model: Scalars['ID'];
};


export type MutationsCreateStockArgs = {
  input: CreateStockInput;
};


export type MutationsCreateStopArgs = {
  customer: Scalars['ID'];
  route: Scalars['ID'];
};


export type MutationsCreateUserArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  setAsDriver?: InputMaybe<Scalars['Boolean']>;
  userRole?: InputMaybe<Scalars['ID']>;
};


export type MutationsCreateVehicleArgs = {
  input: CreateVehicleInput;
};


export type MutationsCreateVehicleExceptionArgs = {
  input: CreateVehicleExceptionsInput;
};


export type MutationsCustomerPlaceOrderArgs = {
  numberOfCylinders?: InputMaybe<Scalars['Int']>;
  requestAutomaticOrdering?: InputMaybe<Scalars['Boolean']>;
};


export type MutationsCylinderSidesFlipArgs = {
  cylinderGroupId: Scalars['ID'];
};


export type MutationsDeleteAllWorkhoursArgs = {
  driver: Scalars['ID'];
};


export type MutationsDeleteBoxArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteBoxOrderArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteCustomerContactNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteDistributorRefillThresholdArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteDriverArgs = {
  driver: Scalars['ID'];
};


export type MutationsDeleteDriverWorkhoursArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteRouteArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteSensorFlagArgs = {
  flag: SensorFlagEnum;
  serialNumber: Scalars['String'];
};


export type MutationsDeleteStopFromRouteArgs = {
  stop: Scalars['ID'];
};


export type MutationsDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteVehicleArgs = {
  vehicle: Scalars['ID'];
};


export type MutationsDeleteVehicleExceptionArgs = {
  id: Scalars['ID'];
};


export type MutationsDispatchRouteArgs = {
  route: Scalars['ID'];
};


export type MutationsEditNotificationListenerArgs = {
  backend?: InputMaybe<NotificationBackendHandler>;
  id: Scalars['ID'];
  topics?: InputMaybe<Array<InputMaybe<NotificationListenerTopicInput>>>;
};


export type MutationsFinalizeSensorRegistrationArgs = {
  activeSideSensorsNum?: InputMaybe<Scalars['Int']>;
  cgSideId?: InputMaybe<Scalars['ID']>;
  customerAddress?: InputMaybe<Scalars['String']>;
  customerDepot?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  customerLatitude?: InputMaybe<Scalars['Float']>;
  customerLongitude?: InputMaybe<Scalars['Float']>;
  customerName?: InputMaybe<Scalars['String']>;
  customerPhone?: InputMaybe<Scalars['String']>;
  customerTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  gasWeight?: InputMaybe<Scalars['Int']>;
  passiveSideSensorsNum?: InputMaybe<Scalars['Int']>;
  serialHash: Scalars['String'];
  serialNumber: Scalars['String'];
};


export type MutationsForgotPasswordArgs = {
  email: Scalars['String'];
  referer?: InputMaybe<Scalars['String']>;
};


export type MutationsLoginArgs = {
  password: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
  username: Scalars['String'];
};


export type MutationsLoginCustomerArgs = {
  phoneNumber: Scalars['String'];
  pinCode: Scalars['String'];
  verificationToken: Scalars['String'];
};


export type MutationsMoveRouteArgs = {
  id: Scalars['ID'];
  newDate: Scalars['Date'];
};


export type MutationsMutateDistributorSettingArgs = {
  distributor: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};


export type MutationsMutateUserSettingArgs = {
  key: Scalars['String'];
  user: Scalars['String'];
  value: Scalars['String'];
};


export type MutationsNewCustomerSensorRegistrationArgs = {
  customerAddress: Scalars['String'];
  customerDepot?: InputMaybe<Scalars['ID']>;
  customerDomainType: CustomerDomainType;
  customerLatitude: Scalars['Float'];
  customerLongitude: Scalars['Float'];
  customerName: Scalars['String'];
  customerPhone?: InputMaybe<Scalars['String']>;
  cylinderMode?: InputMaybe<CylinderGroupMode>;
  gasWeight: Scalars['Int'];
  id: Scalars['ID'];
  leftSideCylinderCount: Scalars['Int'];
  placeSensorLeftSide?: InputMaybe<Scalars['Boolean']>;
  rightSideCylinderCount?: InputMaybe<Scalars['Int']>;
  tareWeight?: InputMaybe<Scalars['Int']>;
};


export type MutationsNewSensorRegistrationArgs = {
  customerId: Scalars['ID'];
  cylinderMode?: InputMaybe<CylinderGroupMode>;
  id: Scalars['ID'];
  leftSideCylinderCount: Scalars['Int'];
  placeSensorLeftSide?: InputMaybe<Scalars['Boolean']>;
  rightSideCylinderCount?: InputMaybe<Scalars['Int']>;
  tareWeight?: InputMaybe<Scalars['Int']>;
};


export type MutationsPatchBoxArgs = {
  id: Scalars['ID'];
  input: InputPatchBoxMutation;
};


export type MutationsPatchCustomerArgs = {
  id: Scalars['ID'];
  input: PatchCustomerInput;
};


export type MutationsPatchCustomerContactArgs = {
  id: Scalars['ID'];
  input: PatchCustomerContactInput;
};


export type MutationsPatchCustomerContactNotificationArgs = {
  id: Scalars['ID'];
  input: PatchCustomerContactNotificationInput;
};


export type MutationsPatchCustomerTypeArgs = {
  id: Scalars['ID'];
  input: PatchDistributorCustomerTypeInput;
};


export type MutationsPatchCycleStateArgs = {
  id: Scalars['ID'];
  state?: InputMaybe<CycleState>;
};


export type MutationsPatchCylinderGroupArgs = {
  id: Scalars['ID'];
  input: InputPatchCylinderGroup;
};


export type MutationsPatchCylinderGroupOrderArgs = {
  id: Scalars['ID'];
  input: PatchCylinderGroupOrderInput;
};


export type MutationsPatchCylinderGroupSideArgs = {
  id: Scalars['ID'];
  input: PatchCylinderGroupSideInput;
};


export type MutationsPatchCylinderGroupSideStateArgs = {
  id: Scalars['ID'];
  state: Scalars['Int'];
};


export type MutationsPatchCylinderNoteArgs = {
  id: Scalars['ID'];
  input: InputCylinderGroupNoteMutation;
};


export type MutationsPatchDepotArgs = {
  id: Scalars['ID'];
  input: PatchDepotInput;
};


export type MutationsPatchDistributorArgs = {
  id: Scalars['ID'];
  input: PatchDistributorInput;
};


export type MutationsPatchDistributorRefillThresholdArgs = {
  id: Scalars['ID'];
  input: PatchDistributorRefillThresholdInput;
};


export type MutationsPatchDriverArgs = {
  id: Scalars['ID'];
  input: PatchDriverInput;
};


export type MutationsPatchDriverExceptionsArgs = {
  id: Scalars['ID'];
  input: PatchDriverExceptionsInput;
};


export type MutationsPatchRouteArgs = {
  id: Scalars['ID'];
  input: PatchRouteInput;
};


export type MutationsPatchSensorArgs = {
  id: Scalars['ID'];
  input: InputPatchSensor;
};


export type MutationsPatchStopArgs = {
  id: Scalars['ID'];
  input: PatchStopInput;
};


export type MutationsPatchUserArgs = {
  id: Scalars['ID'];
  input: PatchUserInput;
};


export type MutationsPatchVehicleArgs = {
  id: Scalars['ID'];
  input: PatchVehicleInput;
};


export type MutationsPatchVehicleExceptionArgs = {
  id: Scalars['ID'];
  input: PatchVehicleExceptionsInput;
};


export type MutationsPlanRoutesArgs = {
  days: Scalars['Int'];
  tz?: InputMaybe<Scalars['String']>;
};


export type MutationsPopulateVehiclesArgs = {
  autoBalance?: InputMaybe<Scalars['Boolean']>;
  fitNewOrders?: InputMaybe<Scalars['Boolean']>;
  keepPlannedUnchanged?: InputMaybe<Scalars['Boolean']>;
  reoptimize?: InputMaybe<Scalars['Boolean']>;
};


export type MutationsRedoRouteArgs = {
  id: Scalars['ID'];
  newStopsOrder?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationsRefreshLoginTokenArgs = {
  token: Scalars['String'];
};


export type MutationsRegisterAppVersionArgs = {
  app: Apps;
  version: Scalars['String'];
};


export type MutationsRemoveCylinderGroupAwaitingDeliveryArgs = {
  cylinderGroup: Scalars['ID'];
};


export type MutationsRemoveCylinderGroupAwaitingOrderArgs = {
  cylinderGroup: Scalars['ID'];
};


export type MutationsRemoveCylinderGroupOrderArgs = {
  cylinderGroupOrder: Scalars['String'];
  setHasEmptySide?: InputMaybe<Scalars['Boolean']>;
};


export type MutationsRemoveCylinderGroupTagArgs = {
  cylinderGroup: Scalars['String'];
  tag: Scalars['String'];
};


export type MutationsRemoveCylinderNoteArgs = {
  id: Scalars['ID'];
};


export type MutationsRemovePermissionGroupArgs = {
  distributorGroup: Scalars['ID'];
  distributorUserRole: Scalars['ID'];
};


export type MutationsReoptimizeRoutesOnDateArgs = {
  autoBalance?: InputMaybe<Scalars['Boolean']>;
  date: Scalars['Date'];
};


export type MutationsRequestCustomerLoginTokenArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationsResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationsSensorAuthArgs = {
  key: Scalars['String'];
  serial: Scalars['String'];
};


export type MutationsSensorCompleteProvisioningArgs = {
  id: Scalars['ID'];
};


export type MutationsSetCylinderGroupAwaitingDeliveryArgs = {
  cylinderGroupOrder: Scalars['ID'];
};


export type MutationsSetCylinderGroupAwaitingOrderArgs = {
  cylinderGroupOrder: Scalars['ID'];
};


export type MutationsSetCylinderGroupPendingRefillArgs = {
  cylinderGroupOrder: Scalars['ID'];
};


export type MutationsSetCylinderGroupReceivedDeliveryArgs = {
  cylinderGroup: Scalars['ID'];
};


export type MutationsSetSensorAsWaitingForCalibrationArgs = {
  sensor: Scalars['String'];
};


export type MutationsSetSensorCalibrationZeroArgs = {
  calibrationGrams?: InputMaybe<Scalars['Int']>;
  calibrationValue?: InputMaybe<Scalars['Int']>;
  calibrationZero: Scalars['Int'];
  sensor: Scalars['String'];
};


export type MutationsSetUserAsDriverArgs = {
  depot?: InputMaybe<Scalars['ID']>;
  user: Scalars['ID'];
  vehicle: Scalars['ID'];
};


export type MutationsSignupArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationsStartRouteArgs = {
  route: Scalars['ID'];
};


export type MutationsUpdateCycleArgs = {
  cycleId?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<UpdateCycleInput>;
};


export type MutationsUpdateSensorEstimatedEmptyArgs = {
  estimatedEmpty?: InputMaybe<Scalars['DateTime']>;
  estimatedEmpty2?: InputMaybe<Scalars['DateTime']>;
  sensor: Scalars['ID'];
};


export type MutationsUpdateStockArgs = {
  id: Scalars['ID'];
  input: PatchStockInput;
};


export type MutationsUploadCylinderGroupImageArgs = {
  cylinderGroupId: Scalars['ID'];
  image: Scalars['Upload'];
};


export type MutationsValidateMsTokenArgs = {
  token: Scalars['String'];
};


export type MutationsValidateResetTokenArgs = {
  token: Scalars['String'];
};

export type NewCustomerSensorRegistration = {
  __typename?: 'NewCustomerSensorRegistration';
  ok?: Maybe<Scalars['Boolean']>;
  sensor?: Maybe<SensorNode>;
};

export type NewSensorRegistration = {
  __typename?: 'NewSensorRegistration';
  ok?: Maybe<Scalars['Boolean']>;
  sensor?: Maybe<SensorNode>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum NotificationBackendHandler {
  Email = 'EMAIL',
  Log = 'LOG',
  Sms = 'SMS',
  User = 'USER'
}

export type NotificationCommentNode = Node & {
  __typename?: 'NotificationCommentNode';
  /** The text of the comment. */
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  notification: NotificationNode;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<UserNode>;
};

export type NotificationCommentNodeConnection = {
  __typename?: 'NotificationCommentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationCommentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NotificationCommentNode` and its cursor. */
export type NotificationCommentNodeEdge = {
  __typename?: 'NotificationCommentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NotificationCommentNode>;
};

export type NotificationListenerContextNode = CustomerNode | DistributorNode | UserNode;

export type NotificationListenerNode = Node & {
  __typename?: 'NotificationListenerNode';
  /** If false, this listener will never fire. When someone unsubscribe this value will simply be set to false. */
  active: Scalars['Boolean'];
  backendHandler?: Maybe<NotificationBackendHandler>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The Notification Resource Name to listen to (see the info in the Notification model for more information). Only notifications having this nrn recursively will be matched. This means for instance that a listener with `nrn:scope1` will match a notification with nrn `nrn:scope1:scope2`. */
  nrn: Scalars['String'];
  periodicity?: Maybe<NotificationPeriodicity>;
  recipientUser?: Maybe<UserNode>;
  topics?: Maybe<Array<Maybe<NotificationListenerTopicNode>>>;
};

export type NotificationListenerTopicInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type NotificationListenerTopicNode = Node & {
  __typename?: 'NotificationListenerTopicNode';
  context?: Maybe<NotificationListenerContextNode>;
  contextContentType?: Maybe<Scalars['String']>;
  contextObjectId?: Maybe<Scalars['String']>;
  /** If true, the topic _must not_ exist on any notification that should match. */
  exclude: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  resource?: Maybe<NotificationResourceNode>;
};

/**
 * NotificationsNode provides a GraphQL-Relay interface to the
 * :py:class:`notifications.models.Notifications` model.
 */
export type NotificationNode = Node & {
  __typename?: 'NotificationNode';
  actionObjectObjectId?: Maybe<Scalars['String']>;
  actorObjectId?: Maybe<Scalars['String']>;
  actorText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSONString']>;
  description?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The full message/contents of the notification. */
  message: Scalars['String'];
  /** The Notification Resource Name of the notification. This is a string which indicates what type of notification it is. A nrn is a hierarchical string, where each level is separated by a colon (":")). The start of an nrn is always "nrn:".  */
  nrn: Scalars['String'];
  objectText?: Maybe<Scalars['String']>;
  /** If true, this notification is considered 'pinned'. This means that it should be put at the top in any view over notifications relevant to this notification. */
  pinned: Scalars['Boolean'];
  public: Scalars['Boolean'];
  resource?: Maybe<NotificationResourceNode>;
  targetObjectId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** The title of the notification. */
  title: Scalars['String'];
  verb?: Maybe<Scalars['String']>;
};

export type NotificationNodeConnection = {
  __typename?: 'NotificationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NotificationNode` and its cursor. */
export type NotificationNodeEdge = {
  __typename?: 'NotificationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NotificationNode>;
};

/** An enumeration. */
export enum NotificationPeriodicity {
  EveryTime = 'EVERY_TIME',
  Once = 'ONCE'
}

export type NotificationResourceNode = Node & {
  __typename?: 'NotificationResourceNode';
  description?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isCustom: Scalars['Boolean'];
  name: Scalars['String'];
  params?: Maybe<Array<Maybe<NotificationResourceParamNode>>>;
  title?: Maybe<Scalars['String']>;
};

export type NotificationResourceParamNode = Node & {
  __typename?: 'NotificationResourceParamNode';
  dataConfig?: Maybe<Scalars['GenericScalar']>;
  dataType?: Maybe<NotificationResourceParamType>;
  description?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  resource: NotificationResourceNode;
  title: Scalars['String'];
};

/** An enumeration. */
export enum NotificationResourceParamType {
  Int = 'INT',
  String = 'STRING'
}

export type NotificationTopicNode = Node & {
  __typename?: 'NotificationTopicNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  notification: NotificationNode;
  /** The topic identifier. This can be any arbitrary string. */
  topic: Scalars['String'];
};

export type NotificationUserInteractionNode = Node & {
  __typename?: 'NotificationUserInteractionNode';
  contact?: Maybe<CustomerContactNode>;
  data?: Maybe<Scalars['JSONString']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  notification: NotificationNode;
  /** The type of interaction between the user and the notification. */
  type: NotificationUserInteractionType;
  user?: Maybe<UserNode>;
};

/** An enumeration. */
export enum NotificationUserInteractionType {
  /** Notified */
  Notified = 'NOTIFIED',
  /** Resolved */
  Resolved = 'RESOLVED',
  /** Seen */
  Seen = 'SEEN'
}

/** An enumeration. */
export enum OperationsNotificationAlertLevel {
  /** Error */
  Error = 'ERROR',
  /** Info */
  Info = 'INFO',
  /** Success */
  Success = 'SUCCESS',
  /** Update */
  Update = 'UPDATE',
  /** Warning */
  Warning = 'WARNING'
}

/**
 * OperationsNode provides a GraphQL-Relay interface to the
 * :py:class:`notifications.models.Operations` model.
 */
export type OperationsNotificationNode = Node & {
  __typename?: 'OperationsNotificationNode';
  /** The alert level of the operations notification. This is semantically similar to log levels. */
  alertLevel: OperationsNotificationAlertLevel;
  /** The content (i.e. message) of the operations notification. */
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The latest point in time when the operations notification should be shown. */
  expiresAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The title of the operations notification. */
  title: Scalars['String'];
};

export type OperationsNotificationNodeConnection = {
  __typename?: 'OperationsNotificationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OperationsNotificationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OperationsNotificationNode` and its cursor. */
export type OperationsNotificationNodeEdge = {
  __typename?: 'OperationsNotificationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<OperationsNotificationNode>;
};

export type OrderStateStatsInterval = {
  __typename?: 'OrderStateStatsInterval';
  bottles?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  deliveredBottles?: Maybe<Scalars['Int']>;
  deliveredOrdersCount?: Maybe<Scalars['Int']>;
  deliveredWeight?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  ordersCount?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PasswordValidator = {
  __typename?: 'PasswordValidator';
  helperText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PatchBoxMutation = {
  __typename?: 'PatchBoxMutation';
  box?: Maybe<BoxNode>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type PatchCustomerContactInput = {
  customer?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
};

export type PatchCustomerContactMutation = {
  __typename?: 'PatchCustomerContactMutation';
  customerContact?: Maybe<CustomerContactNode>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type PatchCustomerContactNotificationInput = {
  resource?: InputMaybe<Scalars['ID']>;
  service?: InputMaybe<CustomerContactNotificationService>;
};

export type PatchCustomerContactNotificationMutation = {
  __typename?: 'PatchCustomerContactNotificationMutation';
  customerContactNotification?: Maybe<CustomerContactNotificationNode>;
};

export type PatchCustomerInput = {
  /** The address of the customer. Can, but must not include information like postal code, city, and so on. */
  address?: InputMaybe<Scalars['String']>;
  /** Defines is customer is always available for delivery and thus ignoring standard opening hours */
  alwaysOpen?: InputMaybe<Scalars['Boolean']>;
  /** The email of the contact person at the customer. */
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  /** The name of the contact person at the customer. */
  contactPersonName?: InputMaybe<Scalars['String']>;
  customerDomainType?: InputMaybe<CustomerDomainType>;
  /** A unique customer identifier */
  customerIdentifier?: InputMaybe<Scalars['String']>;
  /** Which depot does the customer belong to? */
  depot?: InputMaybe<Scalars['ID']>;
  /** The current type(s) of this customer. */
  distributorSpecificTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** The latitude of the location of the customer. */
  latitude?: InputMaybe<Scalars['Float']>;
  /** The longitude of the location of the customer. */
  longitude?: InputMaybe<Scalars['Float']>;
  /** The name of the customer. */
  name?: InputMaybe<Scalars['String']>;
  orderMethod?: InputMaybe<CustomerOrderMethod>;
  /** The phone number of the customer. */
  phoneNumber?: InputMaybe<Scalars['String']>;
  standardOpeningHoursAdd?: InputMaybe<Array<InputMaybe<PatchCustomerInputAddCustomerStandardopeninghours>>>;
  standardOpeningHoursRemove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  standardOpeningHoursUpdate?: InputMaybe<Array<InputMaybe<PatchCustomerInputUpdateCustomerStandardopeninghours>>>;
  /** [Deprecated] The type of customer. Will be replaced with distributor-specific types. */
  type?: InputMaybe<CustomerType>;
  validatorUpdatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PatchCustomerInputAddCustomerStandardopeninghours = {
  /** Closing hours of the customer. I.e. the latest time one can visit the customer. If this is before `opens_at` it should interpreted as the next day */
  closesAt: Scalars['Time'];
  /** Opening hours of the customer. I.e. the earliest time one can visit the customer. */
  opensAt: Scalars['Time'];
  weekday: CustomerStandardOpeningHoursWeekday;
};

export type PatchCustomerInputUpdateCustomerStandardopeninghours = {
  /** Closing hours of the customer. I.e. the latest time one can visit the customer. If this is before `opens_at` it should interpreted as the next day */
  closesAt: Scalars['Time'];
  id: Scalars['ID'];
  /** Opening hours of the customer. I.e. the earliest time one can visit the customer. */
  opensAt: Scalars['Time'];
  weekday: CustomerStandardOpeningHoursWeekday;
};

export type PatchCustomerMutation = {
  __typename?: 'PatchCustomerMutation';
  customer?: Maybe<CustomerNode>;
};

export type PatchCustomerTypeMutation = {
  __typename?: 'PatchCustomerTypeMutation';
  distributorCustomerType?: Maybe<DistributorCustomerTypeNode>;
};

export type PatchCycleStateMutation = {
  __typename?: 'PatchCycleStateMutation';
  cycle?: Maybe<CycleNode>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type PatchCylinderGroupMutation = {
  __typename?: 'PatchCylinderGroupMutation';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type PatchCylinderGroupNoteMutation = {
  __typename?: 'PatchCylinderGroupNoteMutation';
  note?: Maybe<CylinderGroupNoteNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type PatchCylinderGroupOrderInput = {
  /** If true, the order was made automatically by the Smart Cylinders system */
  automatic?: InputMaybe<Scalars['Boolean']>;
  /** Any additional information pertinent to the order. */
  comment?: InputMaybe<Scalars['String']>;
  confirmedAt?: InputMaybe<Scalars['DateTime']>;
  firstPossibleDelivery?: InputMaybe<Scalars['DateTime']>;
  /** The cylinder gas type for this particular order. If null, this vlaue will be inherited from the cylinder group. */
  gasType?: InputMaybe<CylinderGroupOrderGasType>;
  /** The cylinder gas weight for this particular order. If null, this value will be inherited from the cylinder group. */
  gasWeight?: InputMaybe<Scalars['Int']>;
  lastPossibleDelivery?: InputMaybe<Scalars['DateTime']>;
  /** The number of cylinders to be delivered. */
  numberOfCylinders?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<CylinderGroupOrderStates>;
};

export type PatchCylinderGroupOrderMutation = {
  __typename?: 'PatchCylinderGroupOrderMutation';
  cylinderGroupOrder?: Maybe<CylinderGroupOrderNode>;
};

export type PatchCylinderGroupSideInput = {
  cylinderCount?: InputMaybe<Scalars['Int']>;
};

export type PatchCylinderGroupSideMutation = {
  __typename?: 'PatchCylinderGroupSideMutation';
  cylinderGroupSide?: Maybe<CylinderGroupSideNode>;
};

export type PatchCylinderGroupSideStateMutation = {
  __typename?: 'PatchCylinderGroupSideStateMutation';
  cylinderGroupSide?: Maybe<CylinderGroupSideNode>;
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type PatchDepotInput = {
  /** The address where the depot is located. */
  address?: InputMaybe<Scalars['String']>;
  /** The latitude of the location of the depot. */
  latitude?: InputMaybe<Scalars['Float']>;
  /** The longitude of the location of the depot. */
  longitude?: InputMaybe<Scalars['Float']>;
  /** The name of the depot */
  name?: InputMaybe<Scalars['String']>;
};

export type PatchDepotMutation = {
  __typename?: 'PatchDepotMutation';
  depot?: Maybe<DepotNode>;
};

export type PatchDistributorCustomerTypeInput = {
  baseType?: InputMaybe<DistributorCustomerTypeBaseType>;
  name?: InputMaybe<Scalars['String']>;
};

export type PatchDistributorInput = {
  /** The address of the distributor's HQ. */
  address?: InputMaybe<Scalars['String']>;
  /** The contact email of the distributor. */
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['ID']>;
  /** The contact phone number of the distributor. */
  contactPhoneNumber?: InputMaybe<Scalars['String']>;
  /** The country of the distributor. */
  country?: InputMaybe<DistributorCountry>;
  /** The full name of the distributor. */
  fullName?: InputMaybe<Scalars['String']>;
  /** The default communication language for the company */
  language?: InputMaybe<DistributorLanguage>;
  /** The short name of the distributor. This must be unique, as it determines the subdomain one can access the distributor at.  */
  name?: InputMaybe<Scalars['String']>;
  smsIdentifier?: InputMaybe<Scalars['String']>;
  /** The standard cylinder gas weight for this company. */
  standardCylinderGasWeight?: InputMaybe<Scalars['Int']>;
  /** The type of gas of the company. */
  standardGasType?: InputMaybe<DistributorStandardGasType>;
};

export type PatchDistributorMutation = {
  __typename?: 'PatchDistributorMutation';
  distributor?: Maybe<DistributorNode>;
};

export type PatchDistributorRefillThresholdInput = {
  alertThreshold?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  threshold?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<DistributorRefillThresholdUnit>;
};

export type PatchDistributorRefillThresholdMutation = {
  __typename?: 'PatchDistributorRefillThresholdMutation';
  distributorRefillThreshold?: Maybe<DistributorRefillThresholdNode>;
};

export type PatchDriverExceptionsInput = {
  defaultDepot?: InputMaybe<Scalars['ID']>;
  defaultVehicle?: InputMaybe<Scalars['ID']>;
  driver?: InputMaybe<Scalars['ID']>;
  endTime?: InputMaybe<Scalars['Time']>;
  onDate?: InputMaybe<Scalars['Date']>;
  startTime?: InputMaybe<Scalars['Time']>;
  vacated?: InputMaybe<Scalars['Boolean']>;
};

export type PatchDriverExceptionsMutation = {
  __typename?: 'PatchDriverExceptionsMutation';
  driverExceptions?: Maybe<DriverExceptionsNode>;
};

export type PatchDriverInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  defaultDepot?: InputMaybe<Scalars['ID']>;
  defaultVehicle?: InputMaybe<Scalars['ID']>;
  standardEndTime?: InputMaybe<Scalars['Time']>;
  standardStartTime?: InputMaybe<Scalars['Time']>;
};

export type PatchDriverMutation = {
  __typename?: 'PatchDriverMutation';
  driver?: Maybe<DriverNode>;
};

export type PatchRouteInput = {
  /** The depot from which this route originates. */
  depot?: InputMaybe<Scalars['ID']>;
  /** If true, the route is locked, and cannot be changed. */
  locked?: InputMaybe<Scalars['Boolean']>;
  stopsAdd?: InputMaybe<Array<InputMaybe<PatchRouteInputAddRouteStops>>>;
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type PatchRouteInputAddRouteStops = {
  completedAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<Scalars['ID']>;
  /** In meters */
  distanceFromPreviousStop?: InputMaybe<Scalars['Float']>;
  /** The drivers comments on the stop. */
  driversComment?: InputMaybe<Scalars['String']>;
  expectedArrivalTime?: InputMaybe<Scalars['DateTime']>;
  expectedStartTime?: InputMaybe<Scalars['DateTime']>;
  /** In seconds */
  expectedTravelTimeFromPreviousStop?: InputMaybe<Scalars['Float']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** The related cylinder group order. May be none if this is an extra stop. */
  order?: InputMaybe<Scalars['ID']>;
  orderInRoute: Scalars['Int'];
  status?: InputMaybe<StopStatus>;
  stopType?: InputMaybe<StopStopType>;
};

export type PatchRouteMutation = {
  __typename?: 'PatchRouteMutation';
  route?: Maybe<RouteNode>;
};

export type PatchSensorMutation = {
  __typename?: 'PatchSensorMutation';
  ok?: Maybe<Scalars['Boolean']>;
  sensor?: Maybe<SensorNode>;
};

export type PatchStockInput = {
  currentEmpty?: InputMaybe<Scalars['Int']>;
  currentFull?: InputMaybe<Scalars['Int']>;
  cylinderGroups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type PatchStockMutation = {
  __typename?: 'PatchStockMutation';
  stock?: Maybe<StockNode>;
};

export type PatchStopInput = {
  completedAt?: InputMaybe<Scalars['DateTime']>;
  expectedArrivalTime?: InputMaybe<Scalars['DateTime']>;
  expectedStartTime?: InputMaybe<Scalars['DateTime']>;
  orderInRoute?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<StopStatus>;
  stopType?: InputMaybe<StopStopType>;
};

export type PatchStopMutation = {
  __typename?: 'PatchStopMutation';
  stop?: Maybe<StopNode>;
};

export type PatchUserInput = {
  distributorRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['Upload']>;
  tourStatus?: InputMaybe<Scalars['JSONString']>;
};

export type PatchUserMutation = {
  __typename?: 'PatchUserMutation';
  user?: Maybe<UserNode>;
};

export type PatchVehicleExceptionMutation = {
  __typename?: 'PatchVehicleExceptionMutation';
  vehicleExceptions?: Maybe<VehicleExceptionNode>;
};

export type PatchVehicleExceptionsInput = {
  depot?: InputMaybe<Scalars['ID']>;
  onDate?: InputMaybe<Scalars['Date']>;
  vacated?: InputMaybe<Scalars['Boolean']>;
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type PatchVehicleInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  capacity?: InputMaybe<Scalars['Int']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  depot?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  vehicleType?: InputMaybe<VehicleVehicleType>;
};

export type PatchVehicleMutation = {
  __typename?: 'PatchVehicleMutation';
  vehicle?: Maybe<VehicleNode>;
};

export type PlanRoutes = {
  __typename?: 'PlanRoutes';
  code?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  errorDepots?: Maybe<Array<Maybe<DepotNode>>>;
  errorOrders?: Maybe<Array<Maybe<CylinderGroupOrderNode>>>;
  errorUsers?: Maybe<Array<Maybe<UserNode>>>;
  errorVehicles?: Maybe<Array<Maybe<VehicleNode>>>;
  possibleRoutes?: Maybe<Array<Maybe<DriverExtendedNode>>>;
  routes?: Maybe<Array<Maybe<RouteNode>>>;
};

export type PopulateVehicles = {
  __typename?: 'PopulateVehicles';
  routes?: Maybe<Array<Maybe<RouteNode>>>;
  unscheduledOrders?: Maybe<Array<Maybe<CylinderGroupOrderNode>>>;
};

/** The root Graphql query. Simply extend all other queries. */
export type Query = {
  __typename?: 'Query';
  _debug?: Maybe<DjangoDebug>;
  allBoxLocations?: Maybe<Array<Maybe<LocationNode>>>;
  allBoxOrders?: Maybe<BoxOrderNodeConnection>;
  allBoxes?: Maybe<BoxNodeConnection>;
  allCustomerContacts?: Maybe<CustomerContactNodeConnection>;
  allCustomerLocations?: Maybe<CustomerLocationNodeConnection>;
  allCustomerNotificationResources?: Maybe<Array<Maybe<NotificationResourceNode>>>;
  allCustomerNotifications?: Maybe<Array<Maybe<NotificationNode>>>;
  allCustomers?: Maybe<CustomerNodeConnection>;
  allCycleEvents?: Maybe<CycleEventsNodeConnection>;
  allCycles?: Maybe<CycleNodeConnection>;
  allCylinderGroupNoteTypes?: Maybe<Array<Maybe<CylinderGroupNoteTypeList>>>;
  allCylinderGroupNotes?: Maybe<Array<Maybe<CylinderGroupNoteNode>>>;
  allCylinderGroupOrders?: Maybe<CylinderGroupOrderNodeConnection>;
  allCylinderGroupSettings?: Maybe<CylinderGroupSettingNodeConnection>;
  allCylinderGroupSides?: Maybe<CylinderGroupSideNodeConnection>;
  allCylinderGroups?: Maybe<CylinderGroupNodeConnection>;
  allDepots?: Maybe<DepotNodeConnection>;
  allDistributorCustomerTypes?: Maybe<DistributorCustomerTypeNodeConnection>;
  allDistributorDepots?: Maybe<DepotNodeConnection>;
  allDistributorGroups?: Maybe<DistributorGroupNodeConnection>;
  allDistributorNotifications?: Maybe<Array<Maybe<NotificationNode>>>;
  allDistributorRefillThresholds?: Maybe<DistributorRefillThresholdNodeConnection>;
  allDistributorUserRoles?: Maybe<DistributorUserRoleNodeConnection>;
  allDistributors?: Maybe<DistributorNodeConnection>;
  allDriverStandardWorkhours?: Maybe<DriverStandardWorkhourNodeConnection>;
  allDriverWorkhours?: Maybe<DriverWorkhourNodeConnection>;
  allDrivers?: Maybe<DriverNodeConnection>;
  allNotificationComments?: Maybe<NotificationCommentNodeConnection>;
  allNotificationResources?: Maybe<Array<Maybe<NotificationResourceNode>>>;
  allNotifications?: Maybe<NotificationNodeConnection>;
  allOperationsNotifications?: Maybe<OperationsNotificationNodeConnection>;
  allRoutes?: Maybe<RouteNodeConnection>;
  allSensorCalibrations?: Maybe<SensorCalibrationNodeConnection>;
  allSensorConfigurations?: Maybe<SensorConfigurationNodeConnection>;
  allSensorFeedbackKeyVals?: Maybe<SensorFeedbackKeyValNodeConnection>;
  allSensorFeedbacks?: Maybe<SensorFeedbackNodeConnection>;
  allSensorModels?: Maybe<SensorModelNodeConnection>;
  allSensorSettings?: Maybe<SensorSettingNodeConnection>;
  allSensors?: Maybe<SensorNodeConnection>;
  allStops?: Maybe<StopNodeConnection>;
  allTestSensorImeis?: Maybe<Array<Maybe<Scalars['String']>>>;
  allUnassignedRoutes?: Maybe<Array<Maybe<RouteUnassignedNode>>>;
  allUserSettings?: Maybe<UserSettingNodeConnection>;
  allUsers?: Maybe<UserNodeConnection>;
  allVehicles?: Maybe<VehicleNodeConnection>;
  box?: Maybe<BoxNode>;
  boxOrder?: Maybe<BoxOrderNode>;
  checkEncodedSensorSerial?: Maybe<CheckEncodedSensorSerialResult>;
  consumptionRate?: Maybe<Array<Maybe<ConsumptionRateInterval>>>;
  contact?: Maybe<CustomerContactNode>;
  currentDistributor?: Maybe<DistributorNode>;
  currentDistributorSettings?: Maybe<Array<Maybe<DistributorSettingNode>>>;
  currentOrderStats?: Maybe<Array<Maybe<CurrentOrderStatsResult>>>;
  customer?: Maybe<CustomerNode>;
  customerContactExists?: Maybe<Scalars['Boolean']>;
  customerLocation?: Maybe<CustomerLocationNode>;
  customerMe?: Maybe<CustomerMe>;
  customerStandardOpeningHours?: Maybe<CustomerStandardOpeningHoursNode>;
  customerStats?: Maybe<CustomerStats>;
  customerTheme?: Maybe<CustomerTheme>;
  cycle?: Maybe<CycleNode>;
  cycleEvent?: Maybe<CycleEventsNode>;
  cylinderGroup?: Maybe<CylinderGroupNode>;
  cylinderGroupNoteType?: Maybe<CylinderGroupNoteTypeNode>;
  cylinderGroupNoteTypeByKey?: Maybe<CylinderGroupNoteTypeNode>;
  cylinderGroupOrder?: Maybe<CylinderGroupOrderNode>;
  cylinderGroupSetting?: Maybe<CylinderGroupSettingNode>;
  cylinderGroupSide?: Maybe<CylinderGroupSideNode>;
  daysWithRoutes?: Maybe<Scalars['Int']>;
  decodeSensorSerial?: Maybe<DecodeSensorSerialResult>;
  depot?: Maybe<DepotNode>;
  distributor?: Maybe<DistributorNode>;
  distributorCustomerType?: Maybe<DistributorCustomerTypeNode>;
  distributorLocations?: Maybe<DistributorCustomerLocations>;
  distributorRefillThreshold?: Maybe<DistributorRefillThresholdNode>;
  distributorSetting?: Maybe<DistributorSettingNode>;
  distributorSettings?: Maybe<Array<Maybe<DistributorSettingNode>>>;
  distributorSetup?: Maybe<DistributorSettingsOutput>;
  distributorStats?: Maybe<DistributorStats>;
  distributorUserRole?: Maybe<DistributorUserRoleNode>;
  driver?: Maybe<DriverNode>;
  driverStandardWorkhour?: Maybe<DriverStandardWorkhourNode>;
  driverWorkhour?: Maybe<DriverWorkhourNode>;
  errorCodes?: Maybe<GqlErrors>;
  getAppVersions?: Maybe<AppVersionNodeConnection>;
  getJwtTokenForUser?: Maybe<Scalars['String']>;
  getLatestAppVersion?: Maybe<AppVersionNode>;
  getSensorFeedback?: Maybe<GetSensorFeedbackResult>;
  getSensorForCalculation?: Maybe<SensorForCalculation>;
  getSensorMeta?: Maybe<SensorForCalculation>;
  getVersion?: Maybe<Scalars['String']>;
  iHavePermission?: Maybe<UserHasPermission>;
  iHavePermissions?: Maybe<UserHasPermissions>;
  isLoggedIn?: Maybe<Scalars['Boolean']>;
  me?: Maybe<UserNode>;
  myNotificationListeners?: Maybe<Array<Maybe<NotificationListenerNode>>>;
  myUnseenOperationsNotifications?: Maybe<Array<Maybe<OperationsNotificationNode>>>;
  nextBoxId?: Maybe<Scalars['String']>;
  notification?: Maybe<NotificationNode>;
  notificationComment?: Maybe<NotificationCommentNode>;
  notificationTopic?: Maybe<NotificationTopicNode>;
  notificationUserInteraction?: Maybe<NotificationUserInteractionNode>;
  operationsNotification?: Maybe<OperationsNotificationNode>;
  ordersByInterval?: Maybe<Array<Maybe<OrderStateStatsInterval>>>;
  passwordRules?: Maybe<Array<Maybe<Scalars['String']>>>;
  passwordValidators?: Maybe<Array<Maybe<PasswordValidator>>>;
  route?: Maybe<RouteNode>;
  routes?: Maybe<Array<Maybe<DriverExtendedNode>>>;
  searchCustomer?: Maybe<SearchOutput>;
  sensor?: Maybe<SensorNode>;
  sensorById?: Maybe<SensorNode>;
  sensorCalibration?: Maybe<SensorCalibrationNode>;
  sensorConfiguration?: Maybe<SensorConfigurationNode>;
  sensorDebugStats?: Maybe<SensorDebugStats>;
  sensorFeedback?: Maybe<SensorFeedbackNode>;
  sensorFeedbackKeyVal?: Maybe<SensorFeedbackKeyValNode>;
  sensorModel?: Maybe<SensorModelNode>;
  sensorRegistrationStatus?: Maybe<SensorRegistrationStatus>;
  sensorSetting?: Maybe<SensorSettingNode>;
  stock?: Maybe<StockNode>;
  stockBalance?: Maybe<StockBalanceNode>;
  stop?: Maybe<StopNode>;
  testCylinderGroupSide?: Maybe<Array<Maybe<CylinderGroupSideList>>>;
  user?: Maybe<UserNode>;
  userHasPermission?: Maybe<UserHasPermission>;
  userHasPermissions?: Maybe<UserHasPermissions>;
  userSetting?: Maybe<UserSettingNode>;
  userSettings?: Maybe<Array<Maybe<UserSettingNode>>>;
  vehicle?: Maybe<VehicleNode>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllBoxOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllBoxesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distributorName?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAllocated?: InputMaybe<Scalars['Boolean']>;
  isShipped?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locationName?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  q?: InputMaybe<Scalars['String']>;
  sensorModelName?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCustomerContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customer: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCustomerLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCustomerNotificationsArgs = {
  customer: Scalars['ID'];
  resources?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  awaitingDelivery?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isCalibrating?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  passive?: InputMaybe<Scalars['Boolean']>;
  pendingRefill?: InputMaybe<Scalars['Boolean']>;
  q?: InputMaybe<Scalars['String']>;
  readyToRefill?: InputMaybe<Scalars['Boolean']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCycleEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cycleId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCyclesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cylinderGroupId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCylinderGroupNotesArgs = {
  cylinderGroup?: InputMaybe<Scalars['ID']>;
  noteType?: InputMaybe<Scalars['ID']>;
  translationKey?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCylinderGroupOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cylinderGroup?: InputMaybe<Scalars['ID']>;
  cylinderGroup_Customer?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  q?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['Float']>;
  stateIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  stateNotIn?: InputMaybe<Array<InputMaybe<CylinderGroupOrderStates>>>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCylinderGroupSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCylinderGroupSidesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllCylinderGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  awaitingDelivery?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  isCalibrating?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  notScheduledOnDate?: InputMaybe<Scalars['Date']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderByField?: InputMaybe<Scalars['String']>;
  pendingRefill?: InputMaybe<Scalars['Boolean']>;
  q?: InputMaybe<Scalars['String']>;
  readyToRefill?: InputMaybe<Scalars['Boolean']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDepotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDistributorCustomerTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distributor?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDistributorDepotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDistributorGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDistributorNotificationsArgs = {
  resources?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDistributorRefillThresholdsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distributor?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDistributorUserRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDistributorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDriverStandardWorkhoursArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDriverWorkhoursArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllDriversArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllNotificationCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  nrn?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllOperationsNotificationsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllRoutesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  date_Gt?: InputMaybe<Scalars['Date']>;
  date_Gte?: InputMaybe<Scalars['Date']>;
  date_Lt?: InputMaybe<Scalars['Date']>;
  date_Lte?: InputMaybe<Scalars['Date']>;
  driver?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  statusNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllSensorCalibrationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllSensorConfigurationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllSensorFeedbackKeyValsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllSensorFeedbacksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllSensorModelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllSensorSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllSensorsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  before?: InputMaybe<Scalars['String']>;
  boxed?: InputMaybe<Scalars['Boolean']>;
  createdAtGt?: InputMaybe<Scalars['DateTime']>;
  createdAtLt?: InputMaybe<Scalars['DateTime']>;
  currentFlag?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customerType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  deployed?: InputMaybe<Scalars['Boolean']>;
  estimatedRefillGt?: InputMaybe<Scalars['Date']>;
  estimatedRefillLt?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  flags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hasEmptySide?: InputMaybe<Scalars['Boolean']>;
  hasEstimatedRefill?: InputMaybe<Scalars['Boolean']>;
  hasSettings?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isCalibrating?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  latitudeGt?: InputMaybe<Scalars['Float']>;
  latitudeLt?: InputMaybe<Scalars['Float']>;
  longitudeGt?: InputMaybe<Scalars['Float']>;
  longitudeLt?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  passive?: InputMaybe<Scalars['Boolean']>;
  percent?: InputMaybe<Scalars['Float']>;
  percentGt?: InputMaybe<Scalars['Float']>;
  percentLt?: InputMaybe<Scalars['Float']>;
  q?: InputMaybe<Scalars['String']>;
  recentlyRefilled?: InputMaybe<Scalars['Boolean']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllStopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllUserSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDriver?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryAllVehiclesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryBoxArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryBoxOrderArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCheckEncodedSensorSerialArgs = {
  includeSensor?: InputMaybe<Scalars['Boolean']>;
  sensorSerial: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryConsumptionRateArgs = {
  customer?: InputMaybe<Scalars['ID']>;
  depots?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  end: Scalars['DateTime'];
  interval: IntervalStep;
  start: Scalars['DateTime'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryContactArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCustomerContactExistsArgs = {
  phone: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCustomerLocationArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCustomerStandardOpeningHoursArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCustomerStatsArgs = {
  distributor?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCycleArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCycleEventArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCylinderGroupArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCylinderGroupNoteTypeArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCylinderGroupNoteTypeByKeyArgs = {
  key: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCylinderGroupOrderArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCylinderGroupSettingArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryCylinderGroupSideArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDecodeSensorSerialArgs = {
  sensorSerial: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDepotArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDistributorArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDistributorCustomerTypeArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDistributorLocationsArgs = {
  distributor?: InputMaybe<Scalars['String']>;
  q?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDistributorRefillThresholdArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDistributorSettingArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDistributorSettingsArgs = {
  distributor?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDistributorSetupArgs = {
  name: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDistributorUserRoleArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDriverArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDriverStandardWorkhourArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryDriverWorkhourArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryGetAppVersionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  app: Apps;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasInfo?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryGetJwtTokenForUserArgs = {
  expiry?: InputMaybe<Scalars['Int']>;
  secret?: InputMaybe<Scalars['String']>;
  user: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryGetLatestAppVersionArgs = {
  app?: InputMaybe<Apps>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryGetSensorFeedbackArgs = {
  doNotUpdateSent?: InputMaybe<Scalars['Boolean']>;
  hasRestarted?: InputMaybe<Scalars['Boolean']>;
  sensor: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryGetSensorForCalculationArgs = {
  imei: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryGetSensorMetaArgs = {
  serial: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryIHavePermissionArgs = {
  permission: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryIHavePermissionsArgs = {
  permissions: Array<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryNotificationCommentArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryNotificationTopicArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryNotificationUserInteractionArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryOperationsNotificationArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryOrdersByIntervalArgs = {
  customer?: InputMaybe<Scalars['ID']>;
  depots?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  end?: InputMaybe<Scalars['DateTime']>;
  interval: IntervalStep;
  start: Scalars['DateTime'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryRouteArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryRoutesArgs = {
  days?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  tz?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySearchCustomerArgs = {
  q?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySensorArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySensorByIdArgs = {
  id: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySensorCalibrationArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySensorConfigurationArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySensorDebugStatsArgs = {
  sensor?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySensorFeedbackArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySensorFeedbackKeyValArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySensorModelArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySensorRegistrationStatusArgs = {
  sensorId: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QuerySensorSettingArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryStockArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryStockBalanceArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryStopArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryUserArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryUserHasPermissionArgs = {
  permission: Scalars['String'];
  user: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryUserHasPermissionsArgs = {
  permissions: Array<Scalars['String']>;
  user: Scalars['String'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryUserSettingArgs = {
  id: Scalars['ID'];
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryUserSettingsArgs = {
  user?: InputMaybe<Scalars['String']>;
};


/** The root Graphql query. Simply extend all other queries. */
export type QueryVehicleArgs = {
  id: Scalars['ID'];
};

export type RedoRouteMutation = {
  __typename?: 'RedoRouteMutation';
  code?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  route?: Maybe<RouteNode>;
};

export type RefreshLoginTokenMutation = {
  __typename?: 'RefreshLoginTokenMutation';
  newToken?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type RegisterAppVersionMutation = {
  __typename?: 'RegisterAppVersionMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveCylinderGroupAwaitingDelivery = {
  __typename?: 'RemoveCylinderGroupAwaitingDelivery';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveCylinderGroupAwaitingOrder = {
  __typename?: 'RemoveCylinderGroupAwaitingOrder';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveCylinderGroupNoteMutation = {
  __typename?: 'RemoveCylinderGroupNoteMutation';
  deletedId?: Maybe<Scalars['ID']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveCylinderGroupOrder = {
  __typename?: 'RemoveCylinderGroupOrder';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveCylinderGroupTagMutation = {
  __typename?: 'RemoveCylinderGroupTagMutation';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  ok?: Maybe<Scalars['Boolean']>;
  tag?: Maybe<Scalars['String']>;
};

export type RemovePermissionGroup = {
  __typename?: 'RemovePermissionGroup';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ReoptimizeRoutesOnDate = {
  __typename?: 'ReoptimizeRoutesOnDate';
  routes?: Maybe<Array<Maybe<RouteNode>>>;
};

export type RequestCustomerLoginTokenMutation = {
  __typename?: 'RequestCustomerLoginTokenMutation';
  verificationToken?: Maybe<Scalars['String']>;
};

export type ResetOrdersMutation = {
  __typename?: 'ResetOrdersMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordMutation = {
  __typename?: 'ResetPasswordMutation';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  validationErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResetRoutesMutation = {
  __typename?: 'ResetRoutesMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RouteNode = Node & {
  __typename?: 'RouteNode';
  breakDistance?: Maybe<Scalars['Int']>;
  breakEndTime?: Maybe<Scalars['DateTime']>;
  breakStartTime?: Maybe<Scalars['DateTime']>;
  /** The date of the route */
  date: Scalars['Date'];
  /** The depot from which this route originates. */
  depot?: Maybe<DepotNode>;
  distributor: DistributorNode;
  driver?: Maybe<DriverNode>;
  driverEndTime?: Maybe<Scalars['Time']>;
  driverEndedRouteAt?: Maybe<Scalars['DateTime']>;
  driverStartTime?: Maybe<Scalars['Time']>;
  driverStartedRouteAt?: Maybe<Scalars['DateTime']>;
  /** The drivers final comments after completing or aborting the route. */
  driversComment?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** If true, the route is locked, and cannot be changed. */
  locked: Scalars['Boolean'];
  /** The route polyline if it exists. */
  polyline?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  status: RouteStatus;
  stops?: Maybe<Array<Maybe<StopNode>>>;
  totalCylindersToDeliver?: Maybe<Scalars['Int']>;
  /** In kilometers */
  totalDrivingDistance?: Maybe<Scalars['Float']>;
  totalWeightToDeliver?: Maybe<Scalars['Int']>;
  vehicle?: Maybe<VehicleNode>;
};

export type RouteNodeConnection = {
  __typename?: 'RouteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RouteNode` and its cursor. */
export type RouteNodeEdge = {
  __typename?: 'RouteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<RouteNode>;
};

/** An enumeration. */
export enum RouteStatus {
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Completed */
  Completed = 'COMPLETED',
  /** Dispatched */
  Dispatched = 'DISPATCHED',
  /** Dispatched but updated */
  DispatchedButUpdated = 'DISPATCHED_BUT_UPDATED',
  /** Planned */
  Planned = 'PLANNED',
  /** Started */
  Started = 'STARTED',
  /** Waiting */
  Waiting = 'WAITING'
}

export type RouteUnassignedNode = Node & {
  __typename?: 'RouteUnassignedNode';
  /** When the order was placed. */
  createdAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  order: CylinderGroupOrderNode;
  /** The last time the order was updated. */
  updatedAt: Scalars['DateTime'];
};

export type SearchOutput = {
  __typename?: 'SearchOutput';
  hits?: Maybe<Array<Maybe<CustomerNode>>>;
};

export type SensorAuth = {
  __typename?: 'SensorAuth';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SensorCalibrationNode = Node & {
  __typename?: 'SensorCalibrationNode';
  createdAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The new total weight (tare + gas) of the cylinder. */
  newTotalWeight: Scalars['Float'];
  sensor: SensorNode;
};

export type SensorCalibrationNodeConnection = {
  __typename?: 'SensorCalibrationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SensorCalibrationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SensorCalibrationNode` and its cursor. */
export type SensorCalibrationNodeEdge = {
  __typename?: 'SensorCalibrationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SensorCalibrationNode>;
};

export type SensorCompleteProvisioningMutation = {
  __typename?: 'SensorCompleteProvisioningMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SensorConfigurationNode = Node & {
  __typename?: 'SensorConfigurationNode';
  /** The full configuration object that was sent. This is useful for debugging purposes. */
  configurationBlob?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  sensor: SensorNode;
  /** The type of configuration. This is either the initial configuration, or a reconfiguration. */
  type: SensorConfigurationType;
  updatedAt: Scalars['DateTime'];
};

export type SensorConfigurationNodeConnection = {
  __typename?: 'SensorConfigurationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SensorConfigurationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SensorConfigurationNode` and its cursor. */
export type SensorConfigurationNodeEdge = {
  __typename?: 'SensorConfigurationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SensorConfigurationNode>;
};

/** An enumeration. */
export enum SensorConfigurationType {
  /** Initial configuration */
  Init = 'INIT',
  /** Reconfiguration */
  Reco = 'RECO'
}

/**
 * SensorDebugStats provides a GraphQL interface to querying
 * various debug stats regarding a sensor.
 *
 * In particular, attributes like the number of transmissions, the last time
 * a transmission was sent, etc. are provided here.
 */
export type SensorDebugStats = {
  __typename?: 'SensorDebugStats';
  sensor?: Maybe<SensorNode>;
};

export type SensorFeedbackKeyValNode = Node & {
  __typename?: 'SensorFeedbackKeyValNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  key: Scalars['String'];
  /** If true, the key-val pair will be sent also after a sensor restart. */
  sendAfterRestart: Scalars['Boolean'];
  sensorFeedback: SensorFeedbackNode;
  type: SensorFeedbackKeyValType;
  value: Scalars['String'];
};

export type SensorFeedbackKeyValNodeConnection = {
  __typename?: 'SensorFeedbackKeyValNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SensorFeedbackKeyValNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SensorFeedbackKeyValNode` and its cursor. */
export type SensorFeedbackKeyValNodeEdge = {
  __typename?: 'SensorFeedbackKeyValNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SensorFeedbackKeyValNode>;
};

/** An enumeration. */
export enum SensorFeedbackKeyValType {
  /** Boolean */
  Boolean = 'BOOLEAN',
  /** Float */
  Float = 'FLOAT',
  /** Null */
  Null = 'NULL',
  /** String */
  String = 'STRING',
  /** Positive Integer */
  Uint = 'UINT'
}

export type SensorFeedbackNode = Node & {
  __typename?: 'SensorFeedbackNode';
  createdAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  keyValuePairs?: Maybe<Array<Maybe<SensorFeedbackKeyValNode>>>;
  /** The new measurement interval to send to the sensor. If null, no value will be sent. */
  newMeasurementInterval?: Maybe<Scalars['Int']>;
  /** The new transmission interval to send to the sensor. If null, no value will be sent. */
  newTransmissionInterval?: Maybe<Scalars['Int']>;
  sensor: SensorNode;
  /** If true, this feedback has been sent. It will not be sent again. If you uncheck this value, it will be sent again. */
  sent: Scalars['Boolean'];
};

export type SensorFeedbackNodeConnection = {
  __typename?: 'SensorFeedbackNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SensorFeedbackNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SensorFeedbackNode` and its cursor. */
export type SensorFeedbackNodeEdge = {
  __typename?: 'SensorFeedbackNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SensorFeedbackNode>;
};

/** An enumeration. */
export enum SensorFlagEnum {
  Calibrated = 'CALIBRATED',
  CalibrationStarted = 'CALIBRATION_STARTED',
  EndTestStarted = 'END_TEST_STARTED',
  EndTestSucceeded = 'END_TEST_SUCCEEDED',
  Hidden = 'HIDDEN',
  Lasered = 'LASERED',
  Wrecked = 'WRECKED'
}

export type SensorFlagNode = Node & {
  __typename?: 'SensorFlagNode';
  /** Any additional information pertinent to the flag. */
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  flag?: Maybe<SensorFlagEnum>;
  /** The ID of the object. */
  id: Scalars['ID'];
  sensor: SensorNode;
};

export type SensorForCalculation = {
  __typename?: 'SensorForCalculation';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  otherSides?: Maybe<Array<Maybe<CylinderGroupSideNode>>>;
  sensor?: Maybe<SensorNode>;
};

export type SensorInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type SensorModelNode = Node & {
  __typename?: 'SensorModelNode';
  boxes?: Maybe<Array<Maybe<BoxNode>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The prefix for the sensor model */
  letter?: Maybe<Scalars['String']>;
  /** The name of the box model */
  name: Scalars['String'];
  numberOfBoxes?: Maybe<Scalars['Int']>;
};

export type SensorModelNodeConnection = {
  __typename?: 'SensorModelNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SensorModelNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SensorModelNode` and its cursor. */
export type SensorModelNodeEdge = {
  __typename?: 'SensorModelNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SensorModelNode>;
};

/**
 * SensorNode provides a GraphQL-Relay interface to the Sensor model.
 * It also provides the connections `weightMeasurements` and `voltageMeasurements`,
 * which point at the weight/voltage measurements of the given sensor.
 */
export type SensorNode = Node & {
  __typename?: 'SensorNode';
  /** Allow this sensor to provision. */
  allowProvisioning: Scalars['Boolean'];
  /** The box which this sensor is to be shipped in. */
  box?: Maybe<BoxNode>;
  /** The grams calibration. */
  calibrationGrams: Scalars['Int'];
  calibrationStatus?: Maybe<Scalars['Float']>;
  /** The value calibration. */
  calibrationValue: Scalars['Int'];
  /** The zero calibration. */
  calibrationZero: Scalars['Int'];
  certificate?: Maybe<CertNode>;
  /** Cylinder group side */
  cgSide?: Maybe<CylinderGroupSideNode>;
  createdAt: Scalars['DateTime'];
  currentFlag?: Maybe<SensorFlagNode>;
  /** Assigned to distributor */
  distributor?: Maybe<DistributorNode>;
  /** The estimated next refill date. This value is updated continuously. */
  estimatedRefillDate?: Maybe<Scalars['DateTime']>;
  /** The estimated refill date of the _next_ refill. This value is updated continuously. */
  estimatedRefillDate2?: Maybe<Scalars['DateTime']>;
  flags?: Maybe<Array<Maybe<SensorFlagNode>>>;
  /** Every cylinder (over a sensor) has a predetermined gas weight. This is the amount of gas (in kg) in a full cylinder. */
  gasWeight?: Maybe<Scalars['Float']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The IMEI number of the cellular module. */
  imei?: Maybe<Scalars['String']>;
  /** When the sensor was last installed at its current location */
  installedAt?: Maybe<Scalars['DateTime']>;
  /** If true, the sensor is marked as still calibrating, and it's data is slightly unprecise. */
  isCalibrating: Scalars['Boolean'];
  latestMeasurementInterval?: Maybe<Scalars['Int']>;
  latestTransmissionInterval?: Maybe<Scalars['Int']>;
  /** The model of the sensor */
  model?: Maybe<SensorModelNode>;
  /** The latest registered percent (of weight) on the cylinder. */
  percent: Scalars['Float'];
  registrationStatus?: Maybe<SensorRegistrationStatus>;
  /** The serial number of the sensor. This is a token set by the Smart Cylinders team. */
  serialNumber: Scalars['String'];
  /** The current software version running the sensor. */
  softwareVersion: Scalars['String'];
  /** The model name of the telit module on the sensor. */
  telitModelName: Scalars['String'];
  /** The software version of the telit module on the sensor. */
  telitSoftwareVersion: Scalars['String'];
  /** The weight (tare + gas) of the current cylinder when it was full. */
  totalWeight?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  /** Key to authenticate sensor on calibration. */
  validationKey?: Maybe<Scalars['String']>;
  /** The latest registered voltage on the cylinder. */
  voltage: Scalars['Float'];
  /** The latest registered weight on the cylinder. */
  weight: Scalars['Float'];
};

export type SensorNodeConnection = {
  __typename?: 'SensorNodeConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SensorNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SensorNode` and its cursor. */
export type SensorNodeEdge = {
  __typename?: 'SensorNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SensorNode>;
};

export enum SensorRegistrationStatus {
  Calibrating = 'CALIBRATING',
  Finished = 'FINISHED',
  Registered = 'REGISTERED'
}

export type SensorSettingNode = Node & {
  __typename?: 'SensorSettingNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The key of the setting. Every setting is a key-value pair. */
  key: Scalars['String'];
  sensor: SensorNode;
  /** The value of the setting. Every setting is a key-value pair. */
  value: Scalars['String'];
};

export type SensorSettingNodeConnection = {
  __typename?: 'SensorSettingNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SensorSettingNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SensorSettingNode` and its cursor. */
export type SensorSettingNodeEdge = {
  __typename?: 'SensorSettingNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SensorSettingNode>;
};

export type SetCylinderGroupAwaitingDelivery = {
  __typename?: 'SetCylinderGroupAwaitingDelivery';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetCylinderGroupAwaitingOrder = {
  __typename?: 'SetCylinderGroupAwaitingOrder';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetCylinderGroupPendingRefill = {
  __typename?: 'SetCylinderGroupPendingRefill';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetCylinderGroupReceivedDelivery = {
  __typename?: 'SetCylinderGroupReceivedDelivery';
  cylinderGroup?: Maybe<CylinderGroupNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetSensorAsWaitingForCalibrationMutation = {
  __typename?: 'SetSensorAsWaitingForCalibrationMutation';
  ok?: Maybe<Scalars['Boolean']>;
  sensor?: Maybe<SensorNode>;
};

export type SetSensorCalibrationZero = {
  __typename?: 'SetSensorCalibrationZero';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetUserAsDriver = {
  __typename?: 'SetUserAsDriver';
  user?: Maybe<UserNode>;
};

export type SignupMutation = {
  __typename?: 'SignupMutation';
  ok?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
};

export type StartRouteMutation = {
  __typename?: 'StartRouteMutation';
  route?: Maybe<RouteNode>;
};

export type StockBalanceNode = Node & {
  __typename?: 'StockBalanceNode';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  cycle?: Maybe<CycleNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  order?: Maybe<CylinderGroupOrderNode>;
  stock: StockNode;
};

export type StockBalanceNodeConnection = {
  __typename?: 'StockBalanceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StockBalanceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StockBalanceNode` and its cursor. */
export type StockBalanceNodeEdge = {
  __typename?: 'StockBalanceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StockBalanceNode>;
};

export type StockNode = Node & {
  __typename?: 'StockNode';
  balance: StockBalanceNodeConnection;
  createdAt: Scalars['DateTime'];
  currentEmpty: Scalars['Int'];
  currentFull: Scalars['Int'];
  estimatedEmpty?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};


export type StockNodeBalanceArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type StopImageNode = Node & {
  __typename?: 'StopImageNode';
  /** When the image was created. */
  createdAt: Scalars['DateTime'];
  createdBy: UserNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  image: Scalars['String'];
  /** The last time the image was updated. */
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type StopNode = Node & {
  __typename?: 'StopNode';
  completedAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerNode>;
  /** In meters */
  distanceFromPreviousStop?: Maybe<Scalars['Float']>;
  /** The drivers comments on the stop. */
  driversComment?: Maybe<Scalars['String']>;
  expectedArrivalTime?: Maybe<Scalars['DateTime']>;
  expectedStartTime?: Maybe<Scalars['DateTime']>;
  /** In seconds */
  expectedTravelTimeFromPreviousStop?: Maybe<Scalars['Float']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<StopImageNode>>>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** The related cylinder group order. May be none if this is an extra stop. */
  order?: Maybe<CylinderGroupOrderNode>;
  orderInRoute: Scalars['Int'];
  route: RouteNode;
  status: StopStatus;
  stopType: StopStopType;
  tripStatus?: Maybe<TripStatus>;
};

export type StopNodeConnection = {
  __typename?: 'StopNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StopNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StopNode` and its cursor. */
export type StopNodeEdge = {
  __typename?: 'StopNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StopNode>;
};

/** An enumeration. */
export enum StopStatus {
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Completed */
  Completed = 'COMPLETED',
  /** Started */
  Started = 'STARTED',
  /** Waiting */
  Waiting = 'WAITING'
}

/** An enumeration. */
export enum StopStopType {
  /** Delivery */
  Delivery = 'DELIVERY',
  /** Pickup */
  Pickup = 'PICKUP'
}

export type TripStatus = {
  __typename?: 'TripStatus';
  delivered?: Maybe<Scalars['Boolean']>;
  delta?: Maybe<Scalars['Int']>;
  distanceToCover?: Maybe<Scalars['Int']>;
  expectedArrival?: Maybe<Scalars['DateTime']>;
  inProgress?: Maybe<Scalars['Boolean']>;
  numberInLine?: Maybe<Scalars['Int']>;
  routeStatus?: Maybe<Scalars['String']>;
};

export type UpdateCycleInput = {
  calculationId?: InputMaybe<Scalars['UUID']>;
  cgSideId?: InputMaybe<Scalars['ID']>;
  currentWeight?: InputMaybe<Scalars['Float']>;
  depletionDate?: InputMaybe<Scalars['DateTime']>;
  depletionRate?: InputMaybe<Scalars['Float']>;
  depletionRateCycle?: InputMaybe<Scalars['Float']>;
  emptyDate?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  error?: InputMaybe<Scalars['String']>;
  errorCodeId?: InputMaybe<Scalars['String']>;
  estimatedEmpty?: InputMaybe<Scalars['DateTime']>;
  firstUpdatedTs?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['DateTime']>;
  lastCalculation?: InputMaybe<Scalars['UUID']>;
  lastDepCoef?: InputMaybe<Scalars['Float']>;
  lastDepIntercept?: InputMaybe<Scalars['Float']>;
  lastLogStream?: InputMaybe<Scalars['String']>;
  lastUpdatedIndex?: InputMaybe<Scalars['String']>;
  lastUpdatedTs?: InputMaybe<Scalars['Float']>;
  lastWeight?: InputMaybe<Scalars['Float']>;
  noise?: InputMaybe<Scalars['Float']>;
  nullFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  predictions?: InputMaybe<Scalars['JSONString']>;
  prevCycleId?: InputMaybe<Scalars['ID']>;
  refillDate?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
  startWeight?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<Scalars['String']>;
  stopDate?: InputMaybe<Scalars['DateTime']>;
  tareWeight?: InputMaybe<Scalars['Float']>;
};

export type UpdateCycleMutation = {
  __typename?: 'UpdateCycleMutation';
  created?: Maybe<Scalars['Boolean']>;
  cycle?: Maybe<CycleNode>;
  ok?: Maybe<Scalars['Boolean']>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateSensorEstimatedEmpty = {
  __typename?: 'UpdateSensorEstimatedEmpty';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UploadCylinderGroupImageMutation = {
  __typename?: 'UploadCylinderGroupImageMutation';
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

/**
 * UserHasPermission contains whether or not a specific user
 * has got a certain permission.
 */
export type UserHasPermission = {
  __typename?: 'UserHasPermission';
  ok?: Maybe<Scalars['Boolean']>;
  permission?: Maybe<Scalars['String']>;
};

/**
 * UserHasPermissions contains whether or not a specific user
 * has a number of permissions
 */
export type UserHasPermissions = {
  __typename?: 'UserHasPermissions';
  ok?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserNode = Node & {
  __typename?: 'UserNode';
  allPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateJoined: Scalars['DateTime'];
  distributorRoles?: Maybe<Array<Maybe<DistributorUserRoleNode>>>;
  distributors?: Maybe<Array<Maybe<DistributorNode>>>;
  driver?: Maybe<DriverNode>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  frontends?: Maybe<Array<Maybe<FrontendNode>>>;
  fullName?: Maybe<Scalars['String']>;
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups: Array<GroupNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  initials?: Maybe<Scalars['String']>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  /** If true, the user needs to change his/her password. */
  needsPasswordChange?: Maybe<Scalars['Boolean']>;
  phoneNumber: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  primaryDistributor?: Maybe<DistributorNode>;
  profilePicture?: Maybe<Scalars['String']>;
  settings: UserSettingNodeConnection;
  tourStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
};


export type UserNodeSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
};

export type UserSettingNode = Node & {
  __typename?: 'UserSettingNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The key of the setting. Every setting is a key-value pair. */
  key: Scalars['String'];
  user: UserNode;
  /** The value of the setting. Every setting is a key-value pair. */
  value: Scalars['String'];
};

export type UserSettingNodeConnection = {
  __typename?: 'UserSettingNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserSettingNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserSettingNode` and its cursor. */
export type UserSettingNodeEdge = {
  __typename?: 'UserSettingNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserSettingNode>;
};

export type UserSettingsMutation = {
  __typename?: 'UserSettingsMutation';
  key?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
  value?: Maybe<Scalars['String']>;
};

export type ValidateMsToken = {
  __typename?: 'ValidateMsToken';
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type ValidateResetTokenMutation = {
  __typename?: 'ValidateResetTokenMutation';
  email?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type VehicleCapacityNode = Node & {
  __typename?: 'VehicleCapacityNode';
  capacity: Scalars['Float'];
  /** The ID of the object. */
  id: Scalars['ID'];
  type: VehicleCapacityType;
  vehicle: VehicleNode;
};

/** An enumeration. */
export enum VehicleCapacityType {
  /** CO2 */
  Co2 = 'CO2',
  /** m² (Area) */
  M2 = 'M2',
  /** m² (Area) */
  M2_1 = 'M2_1',
  /** Propane */
  Propane = 'PROPANE'
}

export type VehicleExceptionNode = Node & {
  __typename?: 'VehicleExceptionNode';
  depot?: Maybe<DepotNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  onDate: Scalars['Date'];
  vacated: Scalars['Boolean'];
  vehicle?: Maybe<VehicleNode>;
};

export type VehicleNode = Node & {
  __typename?: 'VehicleNode';
  active: Scalars['Boolean'];
  capacities?: Maybe<Array<Maybe<VehicleCapacityNode>>>;
  capacity?: Maybe<Scalars['Int']>;
  defaultForDrivers?: Maybe<Array<Maybe<DriverNode>>>;
  deleted: Scalars['Boolean'];
  depot: DepotNode;
  exceptions?: Maybe<Array<Maybe<VehicleExceptionNode>>>;
  hasUpcomingRoutes?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  upcomingRoutes?: Maybe<Array<Maybe<RouteNode>>>;
  vehicleType: VehicleVehicleType;
};

export type VehicleNodeConnection = {
  __typename?: 'VehicleNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VehicleNode` and its cursor. */
export type VehicleNodeEdge = {
  __typename?: 'VehicleNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VehicleNode>;
};

/** An enumeration. */
export enum VehicleVehicleType {
  /** Pickup */
  Pickup = 'PICKUP',
  /** Truck */
  Truck = 'TRUCK',
  /** Van */
  Van = 'VAN'
}

export type SensorInfo = { __typename?: 'SensorNode', id: string, serialNumber: string, createdAt: any, imei?: string | null, updatedAt: any, installedAt?: any | null, calibrationStatus?: number | null, calibrationZero: number, calibrationValue: number, currentFlag?: { __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null } | null, flags?: Array<{ __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null, createdAt: any } | null> | null, cgSide?: { __typename?: 'CylinderGroupSideNode', id: string, cylinderGroup: { __typename?: 'CylinderGroupNode', id: string, customer: { __typename?: 'CustomerNode', name: string } } } | null, model?: { __typename?: 'SensorModelNode', name: string } | null, box?: { __typename?: 'BoxNode', id: string, name?: string | null, isShipped: boolean, allocatedDate?: any | null, createdAt: any, boxOrder?: { __typename?: 'BoxOrderNode', id: string } | null } | null, distributor?: { __typename?: 'DistributorNode', id: string, fullName?: string | null } | null };

export type AllBoxLocationsVariables = Exact<{ [key: string]: never; }>;


export type AllBoxLocations = { __typename?: 'Query', allBoxLocations?: Array<{ __typename?: 'LocationNode', id: string, name: string } | null> | null };

export type AllBoxOrdersVariables = Exact<{
  isCompleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type AllBoxOrders = { __typename?: 'Query', allBoxOrders?: { __typename?: 'BoxOrderNodeConnection', edges: Array<{ __typename?: 'BoxOrderNodeEdge', node?: { __typename?: 'BoxOrderNode', boxesOrdered: number, boxesAllocated?: number | null, boxesDelivered?: number | null, createdAt: any, id: string, requestedModel?: { __typename?: 'SensorModelNode', name: string, numberOfBoxes?: number | null } | null, distributor: { __typename?: 'DistributorNode', id: string, name?: string | null, fullName?: string | null } } | null } | null> } | null };

export type AllBoxesVariables = Exact<{
  q?: InputMaybe<Scalars['String']>;
  sensorModelName?: InputMaybe<Scalars['String']>;
  distributorName?: InputMaybe<Scalars['String']>;
  isAllocated?: InputMaybe<Scalars['Boolean']>;
  isShipped?: InputMaybe<Scalars['Boolean']>;
  locationName?: InputMaybe<Scalars['String']>;
}>;


export type AllBoxes = { __typename?: 'Query', allBoxes?: { __typename?: 'BoxNodeConnection', edges: Array<{ __typename?: 'BoxNodeEdge', node?: { __typename?: 'BoxNode', id: string, name?: string | null, createdAt: any, isShipped: boolean, allocatedDate?: any | null, boxOrder?: { __typename?: 'BoxOrderNode', id: string } | null, location?: { __typename?: 'LocationNode', id: string, name: string } | null, sensors?: Array<{ __typename?: 'SensorNode', id: string, serialNumber: string, createdAt: any, imei?: string | null, updatedAt: any, installedAt?: any | null, calibrationStatus?: number | null, calibrationZero: number, calibrationValue: number, currentFlag?: { __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null } | null, flags?: Array<{ __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null, createdAt: any } | null> | null, cgSide?: { __typename?: 'CylinderGroupSideNode', id: string, cylinderGroup: { __typename?: 'CylinderGroupNode', id: string, customer: { __typename?: 'CustomerNode', name: string } } } | null, model?: { __typename?: 'SensorModelNode', name: string } | null, box?: { __typename?: 'BoxNode', id: string, name?: string | null, isShipped: boolean, allocatedDate?: any | null, createdAt: any, boxOrder?: { __typename?: 'BoxOrderNode', id: string } | null } | null, distributor?: { __typename?: 'DistributorNode', id: string, fullName?: string | null } | null } | null> | null } | null } | null> } | null };

export type AllDistributorsVariables = Exact<{ [key: string]: never; }>;


export type AllDistributors = { __typename?: 'Query', allDistributors?: { __typename?: 'DistributorNodeConnection', edges: Array<{ __typename?: 'DistributorNodeEdge', node?: { __typename?: 'DistributorNode', id: string, name?: string | null, fullName?: string | null, createdAt: any, updatedAt: any, boxOrders: { __typename?: 'BoxOrderNodeConnection', edges: Array<{ __typename?: 'BoxOrderNodeEdge', node?: { __typename?: 'BoxOrderNode', boxesDelivered?: number | null, boxesAllocated?: number | null, requestedModel?: { __typename?: 'SensorModelNode', name: string } | null } | null } | null> } } | null } | null> } | null };

export type AllSensorModelsVariables = Exact<{ [key: string]: never; }>;


export type AllSensorModels = { __typename?: 'Query', allSensorModels?: { __typename?: 'SensorModelNodeConnection', edges: Array<{ __typename?: 'SensorModelNodeEdge', node?: { __typename?: 'SensorModelNode', name: string, id: string } | null } | null> } | null };

export type AllSensorsVariables = Exact<{
  q?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  boxed?: InputMaybe<Scalars['Boolean']>;
  createdAtGt?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  flags?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  currentFlag?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  serialNumber?: InputMaybe<Scalars['String']>;
}>;


export type AllSensors = { __typename?: 'Query', allSensors?: { __typename?: 'SensorNodeConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'SensorNodeEdge', node?: { __typename?: 'SensorNode', id: string, serialNumber: string, createdAt: any, imei?: string | null, updatedAt: any, installedAt?: any | null, calibrationStatus?: number | null, calibrationZero: number, calibrationValue: number, currentFlag?: { __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null } | null, flags?: Array<{ __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null, createdAt: any } | null> | null, cgSide?: { __typename?: 'CylinderGroupSideNode', id: string, cylinderGroup: { __typename?: 'CylinderGroupNode', id: string, customer: { __typename?: 'CustomerNode', name: string } } } | null, model?: { __typename?: 'SensorModelNode', name: string } | null, box?: { __typename?: 'BoxNode', id: string, name?: string | null, isShipped: boolean, allocatedDate?: any | null, createdAt: any, boxOrder?: { __typename?: 'BoxOrderNode', id: string } | null } | null, distributor?: { __typename?: 'DistributorNode', id: string, fullName?: string | null } | null } | null } | null> } | null };

export type AssignBoxesToOrderVariables = Exact<{
  boxOrder: Scalars['ID'];
  boxes?: InputMaybe<Array<InputMaybe<BoxInput>> | InputMaybe<BoxInput>>;
}>;


export type AssignBoxesToOrder = { __typename?: 'Mutations', assignBoxesToOrder?: { __typename?: 'AssignBoxesToOrderMutation', ok?: boolean | null, error?: string | null } | null };

export type CreateBoxVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  sensors?: InputMaybe<Array<InputMaybe<SensorInput>> | InputMaybe<SensorInput>>;
  location?: InputMaybe<Scalars['ID']>;
}>;


export type CreateBox = { __typename?: 'Mutations', createBox?: { __typename?: 'CreateBoxMutation', ok?: boolean | null, error?: string | null } | null };

export type CreateBoxOrderVariables = Exact<{
  boxesOrdered: Scalars['Int'];
  distributorId: Scalars['ID'];
  sensorModelId: Scalars['ID'];
}>;


export type CreateBoxOrder = { __typename?: 'Mutations', createBoxOrder?: { __typename?: 'CreateBoxOrderMutation', ok?: boolean | null, error?: string | null } | null };

export type CreateSensorFlagVariables = Exact<{
  flag: SensorFlagEnum;
  serialNumber: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
}>;


export type CreateSensorFlag = { __typename?: 'Mutations', createSensorFlag?: { __typename?: 'CreateSensorFlagMutation', ok?: boolean | null, error?: string | null, sensorFlag?: { __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null, createdAt: any } | null } | null };

export type DecodeSensorSerialVariables = Exact<{
  sensorSerial: Scalars['String'];
}>;


export type DecodeSensorSerial = { __typename?: 'Query', decodeSensorSerial?: { __typename?: 'DecodeSensorSerialResult', sensorSerial?: string | null, ok?: boolean | null, sensor?: { __typename?: 'SensorNode', id: string, serialNumber: string, createdAt: any, imei?: string | null, updatedAt: any, installedAt?: any | null, calibrationStatus?: number | null, calibrationZero: number, calibrationValue: number, currentFlag?: { __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null } | null, flags?: Array<{ __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null, createdAt: any } | null> | null, cgSide?: { __typename?: 'CylinderGroupSideNode', id: string, cylinderGroup: { __typename?: 'CylinderGroupNode', id: string, customer: { __typename?: 'CustomerNode', name: string } } } | null, model?: { __typename?: 'SensorModelNode', name: string } | null, box?: { __typename?: 'BoxNode', id: string, name?: string | null, isShipped: boolean, allocatedDate?: any | null, createdAt: any, boxOrder?: { __typename?: 'BoxOrderNode', id: string } | null } | null, distributor?: { __typename?: 'DistributorNode', id: string, fullName?: string | null } | null } | null } | null };

export type DeleteBoxVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBox = { __typename?: 'Mutations', deleteBox?: { __typename?: 'DeleteBoxMutation', ok?: boolean | null } | null };

export type DeleteBoxOrderVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBoxOrder = { __typename?: 'Mutations', deleteBoxOrder?: { __typename?: 'DeleteBoxOrderMutation', ok?: boolean | null } | null };

export type DeleteSensorFlagVariables = Exact<{
  flag: SensorFlagEnum;
  serialNumber: Scalars['String'];
}>;


export type DeleteSensorFlag = { __typename?: 'Mutations', deleteSensorFlag?: { __typename?: 'DeleteSensorFlagMutation', ok?: boolean | null, error?: string | null } | null };

export type DistributorVariables = Exact<{
  distributor: Scalars['ID'];
}>;


export type Distributor = { __typename?: 'Query', distributor?: { __typename?: 'DistributorNode', id: string, name?: string | null, fullName?: string | null, createdAt: any, updatedAt: any, boxOrders: { __typename?: 'BoxOrderNodeConnection', edges: Array<{ __typename?: 'BoxOrderNodeEdge', node?: { __typename?: 'BoxOrderNode', id: string, boxes?: Array<{ __typename?: 'BoxNode', id: string, name?: string | null, createdAt: any, allocatedDate?: any | null, shippedDate?: any | null, isShipped: boolean, location?: { __typename?: 'LocationNode', id: string, name: string } | null, sensors?: Array<{ __typename?: 'SensorNode', id: string, serialNumber: string, createdAt: any, imei?: string | null, updatedAt: any, installedAt?: any | null, calibrationStatus?: number | null, calibrationZero: number, calibrationValue: number, currentFlag?: { __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null } | null, flags?: Array<{ __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null, createdAt: any } | null> | null, cgSide?: { __typename?: 'CylinderGroupSideNode', id: string, cylinderGroup: { __typename?: 'CylinderGroupNode', id: string, customer: { __typename?: 'CustomerNode', name: string } } } | null, model?: { __typename?: 'SensorModelNode', name: string } | null, box?: { __typename?: 'BoxNode', id: string, name?: string | null, isShipped: boolean, allocatedDate?: any | null, createdAt: any, boxOrder?: { __typename?: 'BoxOrderNode', id: string } | null } | null, distributor?: { __typename?: 'DistributorNode', id: string, fullName?: string | null } | null } | null> | null } | null> | null } | null } | null> } } | null };

export type LastBoxVariables = Exact<{ [key: string]: never; }>;


export type LastBox = { __typename?: 'Query', allBoxes?: { __typename?: 'BoxNodeConnection', edges: Array<{ __typename?: 'BoxNodeEdge', node?: { __typename?: 'BoxNode', id: string, createdAt: any, updatedAt: any, name?: string | null } | null } | null> } | null };

export type MeVariables = Exact<{ [key: string]: never; }>;


export type Me = { __typename?: 'Query', me?: { __typename?: 'UserNode', id: string, isStaff: boolean, username: string, email: string, primaryDistributor?: { __typename?: 'DistributorNode', name?: string | null } | null } | null };

export type NextBoxIdVariables = Exact<{ [key: string]: never; }>;


export type NextBoxId = { __typename?: 'Query', nextBoxId?: string | null };

export type PatchBoxVariables = Exact<{
  boxId: Scalars['ID'];
  input: InputPatchBoxMutation;
}>;


export type PatchBox = { __typename?: 'Mutations', patchBox?: { __typename?: 'PatchBoxMutation', ok?: boolean | null, error?: string | null } | null };

export type SensorVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Sensor = { __typename?: 'Query', sensor?: { __typename?: 'SensorNode', id: string, serialNumber: string, createdAt: any, imei?: string | null, updatedAt: any, installedAt?: any | null, calibrationStatus?: number | null, calibrationZero: number, calibrationValue: number, currentFlag?: { __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null } | null, flags?: Array<{ __typename?: 'SensorFlagNode', id: string, flag?: SensorFlagEnum | null, comment?: string | null, createdAt: any } | null> | null, cgSide?: { __typename?: 'CylinderGroupSideNode', id: string, cylinderGroup: { __typename?: 'CylinderGroupNode', id: string, customer: { __typename?: 'CustomerNode', name: string } } } | null, model?: { __typename?: 'SensorModelNode', name: string } | null, box?: { __typename?: 'BoxNode', id: string, name?: string | null, isShipped: boolean, allocatedDate?: any | null, createdAt: any, boxOrder?: { __typename?: 'BoxOrderNode', id: string } | null } | null, distributor?: { __typename?: 'DistributorNode', id: string, fullName?: string | null } | null } | null };

export type SetSensorAsWaitingForCalibrationVariables = Exact<{
  sensor: Scalars['String'];
}>;


export type SetSensorAsWaitingForCalibration = { __typename?: 'Mutations', setSensorAsWaitingForCalibration?: { __typename?: 'SetSensorAsWaitingForCalibrationMutation', ok?: boolean | null } | null };

export const SensorInfo = gql`
    fragment SensorInfo on SensorNode {
  id
  currentFlag {
    id
    flag
    comment
  }
  flags {
    id
    flag
    comment
    createdAt
  }
  serialNumber
  createdAt
  imei
  createdAt
  updatedAt
  installedAt
  calibrationStatus
  calibrationZero
  calibrationValue
  cgSide {
    id
    cylinderGroup {
      id
      customer {
        name
      }
    }
  }
  model {
    name
  }
  box {
    id
    name
    isShipped
    allocatedDate
    createdAt
    boxOrder {
      id
    }
  }
  distributor {
    id
    fullName
  }
}
    `;
export const AllBoxLocationsDocument = gql`
    query AllBoxLocations {
  allBoxLocations {
    id
    name
  }
}
    `;

/**
 * __useAllBoxLocations__
 *
 * To run a query within a React component, call `useAllBoxLocations` and pass it any options that fit your needs.
 * When your component renders, `useAllBoxLocations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBoxLocations({
 *   variables: {
 *   },
 * });
 */
export function useAllBoxLocations(baseOptions?: Apollo.QueryHookOptions<AllBoxLocations, AllBoxLocationsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBoxLocations, AllBoxLocationsVariables>(AllBoxLocationsDocument, options);
      }
export function useAllBoxLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBoxLocations, AllBoxLocationsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBoxLocations, AllBoxLocationsVariables>(AllBoxLocationsDocument, options);
        }
export type AllBoxLocationsHookResult = ReturnType<typeof useAllBoxLocations>;
export type AllBoxLocationsLazyQueryHookResult = ReturnType<typeof useAllBoxLocationsLazyQuery>;
export type AllBoxLocationsQueryResult = Apollo.QueryResult<AllBoxLocations, AllBoxLocationsVariables>;
export const AllBoxOrdersDocument = gql`
    query AllBoxOrders($isCompleted: Boolean) {
  allBoxOrders(last: 1000, isCompleted: $isCompleted) {
    edges {
      node {
        requestedModel {
          name
          numberOfBoxes
        }
        distributor {
          id
          name
          fullName
        }
        boxesOrdered
        boxesAllocated
        boxesDelivered
        createdAt
        id
      }
    }
  }
}
    `;

/**
 * __useAllBoxOrders__
 *
 * To run a query within a React component, call `useAllBoxOrders` and pass it any options that fit your needs.
 * When your component renders, `useAllBoxOrders` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBoxOrders({
 *   variables: {
 *      isCompleted: // value for 'isCompleted'
 *   },
 * });
 */
export function useAllBoxOrders(baseOptions?: Apollo.QueryHookOptions<AllBoxOrders, AllBoxOrdersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBoxOrders, AllBoxOrdersVariables>(AllBoxOrdersDocument, options);
      }
export function useAllBoxOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBoxOrders, AllBoxOrdersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBoxOrders, AllBoxOrdersVariables>(AllBoxOrdersDocument, options);
        }
export type AllBoxOrdersHookResult = ReturnType<typeof useAllBoxOrders>;
export type AllBoxOrdersLazyQueryHookResult = ReturnType<typeof useAllBoxOrdersLazyQuery>;
export type AllBoxOrdersQueryResult = Apollo.QueryResult<AllBoxOrders, AllBoxOrdersVariables>;
export const AllBoxesDocument = gql`
    query AllBoxes($q: String, $sensorModelName: String, $distributorName: String, $isAllocated: Boolean, $isShipped: Boolean, $locationName: String) {
  allBoxes(
    first: 100
    q: $q
    sensorModelName: $sensorModelName
    orderBy: "-createdAt"
    distributorName: $distributorName
    isAllocated: $isAllocated
    isShipped: $isShipped
    locationName: $locationName
  ) {
    edges {
      node {
        id
        name
        createdAt
        isShipped
        allocatedDate
        boxOrder {
          id
        }
        location {
          id
          name
        }
        sensors {
          ...SensorInfo
        }
      }
    }
  }
}
    ${SensorInfo}`;

/**
 * __useAllBoxes__
 *
 * To run a query within a React component, call `useAllBoxes` and pass it any options that fit your needs.
 * When your component renders, `useAllBoxes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBoxes({
 *   variables: {
 *      q: // value for 'q'
 *      sensorModelName: // value for 'sensorModelName'
 *      distributorName: // value for 'distributorName'
 *      isAllocated: // value for 'isAllocated'
 *      isShipped: // value for 'isShipped'
 *      locationName: // value for 'locationName'
 *   },
 * });
 */
export function useAllBoxes(baseOptions?: Apollo.QueryHookOptions<AllBoxes, AllBoxesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBoxes, AllBoxesVariables>(AllBoxesDocument, options);
      }
export function useAllBoxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBoxes, AllBoxesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBoxes, AllBoxesVariables>(AllBoxesDocument, options);
        }
export type AllBoxesHookResult = ReturnType<typeof useAllBoxes>;
export type AllBoxesLazyQueryHookResult = ReturnType<typeof useAllBoxesLazyQuery>;
export type AllBoxesQueryResult = Apollo.QueryResult<AllBoxes, AllBoxesVariables>;
export const AllDistributorsDocument = gql`
    query AllDistributors {
  allDistributors(first: 1000) {
    edges {
      node {
        id
        name
        fullName
        createdAt
        updatedAt
        boxOrders(first: 100) {
          edges {
            node {
              boxesDelivered
              boxesAllocated
              requestedModel {
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllDistributors__
 *
 * To run a query within a React component, call `useAllDistributors` and pass it any options that fit your needs.
 * When your component renders, `useAllDistributors` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDistributors({
 *   variables: {
 *   },
 * });
 */
export function useAllDistributors(baseOptions?: Apollo.QueryHookOptions<AllDistributors, AllDistributorsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDistributors, AllDistributorsVariables>(AllDistributorsDocument, options);
      }
export function useAllDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDistributors, AllDistributorsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDistributors, AllDistributorsVariables>(AllDistributorsDocument, options);
        }
export type AllDistributorsHookResult = ReturnType<typeof useAllDistributors>;
export type AllDistributorsLazyQueryHookResult = ReturnType<typeof useAllDistributorsLazyQuery>;
export type AllDistributorsQueryResult = Apollo.QueryResult<AllDistributors, AllDistributorsVariables>;
export const AllSensorModelsDocument = gql`
    query AllSensorModels {
  allSensorModels(last: 100) {
    edges {
      node {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useAllSensorModels__
 *
 * To run a query within a React component, call `useAllSensorModels` and pass it any options that fit your needs.
 * When your component renders, `useAllSensorModels` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSensorModels({
 *   variables: {
 *   },
 * });
 */
export function useAllSensorModels(baseOptions?: Apollo.QueryHookOptions<AllSensorModels, AllSensorModelsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSensorModels, AllSensorModelsVariables>(AllSensorModelsDocument, options);
      }
export function useAllSensorModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSensorModels, AllSensorModelsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSensorModels, AllSensorModelsVariables>(AllSensorModelsDocument, options);
        }
export type AllSensorModelsHookResult = ReturnType<typeof useAllSensorModels>;
export type AllSensorModelsLazyQueryHookResult = ReturnType<typeof useAllSensorModelsLazyQuery>;
export type AllSensorModelsQueryResult = Apollo.QueryResult<AllSensorModels, AllSensorModelsVariables>;
export const AllSensorsDocument = gql`
    query AllSensors($q: String, $orderBy: String, $boxed: Boolean, $createdAtGt: DateTime, $first: Int = 1000, $offset: Int, $flags: [String], $currentFlag: [String], $serialNumber: String) {
  allSensors(
    first: $first
    orderBy: $orderBy
    q: $q
    boxed: $boxed
    createdAtGt: $createdAtGt
    offset: $offset
    flags: $flags
    currentFlag: $currentFlag
    serialNumber: $serialNumber
  ) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ...SensorInfo
      }
    }
  }
}
    ${SensorInfo}`;

/**
 * __useAllSensors__
 *
 * To run a query within a React component, call `useAllSensors` and pass it any options that fit your needs.
 * When your component renders, `useAllSensors` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSensors({
 *   variables: {
 *      q: // value for 'q'
 *      orderBy: // value for 'orderBy'
 *      boxed: // value for 'boxed'
 *      createdAtGt: // value for 'createdAtGt'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      flags: // value for 'flags'
 *      currentFlag: // value for 'currentFlag'
 *      serialNumber: // value for 'serialNumber'
 *   },
 * });
 */
export function useAllSensors(baseOptions?: Apollo.QueryHookOptions<AllSensors, AllSensorsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSensors, AllSensorsVariables>(AllSensorsDocument, options);
      }
export function useAllSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSensors, AllSensorsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSensors, AllSensorsVariables>(AllSensorsDocument, options);
        }
export type AllSensorsHookResult = ReturnType<typeof useAllSensors>;
export type AllSensorsLazyQueryHookResult = ReturnType<typeof useAllSensorsLazyQuery>;
export type AllSensorsQueryResult = Apollo.QueryResult<AllSensors, AllSensorsVariables>;
export const AssignBoxesToOrderDocument = gql`
    mutation AssignBoxesToOrder($boxOrder: ID!, $boxes: [BoxInput]) {
  assignBoxesToOrder(boxOrder: $boxOrder, boxes: $boxes) {
    ok
    error
  }
}
    `;
export type AssignBoxesToOrderMutationFn = Apollo.MutationFunction<AssignBoxesToOrder, AssignBoxesToOrderVariables>;

/**
 * __useAssignBoxesToOrder__
 *
 * To run a mutation, you first call `useAssignBoxesToOrder` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignBoxesToOrder` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignBoxesToOrder, { data, loading, error }] = useAssignBoxesToOrder({
 *   variables: {
 *      boxOrder: // value for 'boxOrder'
 *      boxes: // value for 'boxes'
 *   },
 * });
 */
export function useAssignBoxesToOrder(baseOptions?: Apollo.MutationHookOptions<AssignBoxesToOrder, AssignBoxesToOrderVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignBoxesToOrder, AssignBoxesToOrderVariables>(AssignBoxesToOrderDocument, options);
      }
export type AssignBoxesToOrderHookResult = ReturnType<typeof useAssignBoxesToOrder>;
export type AssignBoxesToOrderMutationResult = Apollo.MutationResult<AssignBoxesToOrder>;
export type AssignBoxesToOrderMutationOptions = Apollo.BaseMutationOptions<AssignBoxesToOrder, AssignBoxesToOrderVariables>;
export const CreateBoxDocument = gql`
    mutation CreateBox($name: String, $sensors: [SensorInput], $location: ID) {
  createBox(name: $name, sensors: $sensors, location: $location) {
    ok
    error
  }
}
    `;
export type CreateBoxMutationFn = Apollo.MutationFunction<CreateBox, CreateBoxVariables>;

/**
 * __useCreateBox__
 *
 * To run a mutation, you first call `useCreateBox` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBox` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBox, { data, loading, error }] = useCreateBox({
 *   variables: {
 *      name: // value for 'name'
 *      sensors: // value for 'sensors'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useCreateBox(baseOptions?: Apollo.MutationHookOptions<CreateBox, CreateBoxVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBox, CreateBoxVariables>(CreateBoxDocument, options);
      }
export type CreateBoxHookResult = ReturnType<typeof useCreateBox>;
export type CreateBoxMutationResult = Apollo.MutationResult<CreateBox>;
export type CreateBoxMutationOptions = Apollo.BaseMutationOptions<CreateBox, CreateBoxVariables>;
export const CreateBoxOrderDocument = gql`
    mutation CreateBoxOrder($boxesOrdered: Int!, $distributorId: ID!, $sensorModelId: ID!) {
  createBoxOrder(
    boxesOrdered: $boxesOrdered
    distributorId: $distributorId
    sensorModelId: $sensorModelId
  ) {
    ok
    error
  }
}
    `;
export type CreateBoxOrderMutationFn = Apollo.MutationFunction<CreateBoxOrder, CreateBoxOrderVariables>;

/**
 * __useCreateBoxOrder__
 *
 * To run a mutation, you first call `useCreateBoxOrder` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBoxOrder` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBoxOrder, { data, loading, error }] = useCreateBoxOrder({
 *   variables: {
 *      boxesOrdered: // value for 'boxesOrdered'
 *      distributorId: // value for 'distributorId'
 *      sensorModelId: // value for 'sensorModelId'
 *   },
 * });
 */
export function useCreateBoxOrder(baseOptions?: Apollo.MutationHookOptions<CreateBoxOrder, CreateBoxOrderVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBoxOrder, CreateBoxOrderVariables>(CreateBoxOrderDocument, options);
      }
export type CreateBoxOrderHookResult = ReturnType<typeof useCreateBoxOrder>;
export type CreateBoxOrderMutationResult = Apollo.MutationResult<CreateBoxOrder>;
export type CreateBoxOrderMutationOptions = Apollo.BaseMutationOptions<CreateBoxOrder, CreateBoxOrderVariables>;
export const CreateSensorFlagDocument = gql`
    mutation CreateSensorFlag($flag: SensorFlagEnum!, $serialNumber: String!, $comment: String) {
  createSensorFlag(flag: $flag, serialNumber: $serialNumber, comment: $comment) {
    ok
    error
    sensorFlag {
      id
      flag
      comment
      createdAt
    }
  }
}
    `;
export type CreateSensorFlagMutationFn = Apollo.MutationFunction<CreateSensorFlag, CreateSensorFlagVariables>;

/**
 * __useCreateSensorFlag__
 *
 * To run a mutation, you first call `useCreateSensorFlag` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorFlag` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorFlag, { data, loading, error }] = useCreateSensorFlag({
 *   variables: {
 *      flag: // value for 'flag'
 *      serialNumber: // value for 'serialNumber'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateSensorFlag(baseOptions?: Apollo.MutationHookOptions<CreateSensorFlag, CreateSensorFlagVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSensorFlag, CreateSensorFlagVariables>(CreateSensorFlagDocument, options);
      }
export type CreateSensorFlagHookResult = ReturnType<typeof useCreateSensorFlag>;
export type CreateSensorFlagMutationResult = Apollo.MutationResult<CreateSensorFlag>;
export type CreateSensorFlagMutationOptions = Apollo.BaseMutationOptions<CreateSensorFlag, CreateSensorFlagVariables>;
export const DecodeSensorSerialDocument = gql`
    query DecodeSensorSerial($sensorSerial: String!) {
  decodeSensorSerial(sensorSerial: $sensorSerial) {
    sensorSerial
    ok
    sensor {
      ...SensorInfo
    }
  }
}
    ${SensorInfo}`;

/**
 * __useDecodeSensorSerial__
 *
 * To run a query within a React component, call `useDecodeSensorSerial` and pass it any options that fit your needs.
 * When your component renders, `useDecodeSensorSerial` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecodeSensorSerial({
 *   variables: {
 *      sensorSerial: // value for 'sensorSerial'
 *   },
 * });
 */
export function useDecodeSensorSerial(baseOptions: Apollo.QueryHookOptions<DecodeSensorSerial, DecodeSensorSerialVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecodeSensorSerial, DecodeSensorSerialVariables>(DecodeSensorSerialDocument, options);
      }
export function useDecodeSensorSerialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecodeSensorSerial, DecodeSensorSerialVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecodeSensorSerial, DecodeSensorSerialVariables>(DecodeSensorSerialDocument, options);
        }
export type DecodeSensorSerialHookResult = ReturnType<typeof useDecodeSensorSerial>;
export type DecodeSensorSerialLazyQueryHookResult = ReturnType<typeof useDecodeSensorSerialLazyQuery>;
export type DecodeSensorSerialQueryResult = Apollo.QueryResult<DecodeSensorSerial, DecodeSensorSerialVariables>;
export const DeleteBoxDocument = gql`
    mutation DeleteBox($id: ID!) {
  deleteBox(id: $id) {
    ok
  }
}
    `;
export type DeleteBoxMutationFn = Apollo.MutationFunction<DeleteBox, DeleteBoxVariables>;

/**
 * __useDeleteBox__
 *
 * To run a mutation, you first call `useDeleteBox` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBox` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBox, { data, loading, error }] = useDeleteBox({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBox(baseOptions?: Apollo.MutationHookOptions<DeleteBox, DeleteBoxVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBox, DeleteBoxVariables>(DeleteBoxDocument, options);
      }
export type DeleteBoxHookResult = ReturnType<typeof useDeleteBox>;
export type DeleteBoxMutationResult = Apollo.MutationResult<DeleteBox>;
export type DeleteBoxMutationOptions = Apollo.BaseMutationOptions<DeleteBox, DeleteBoxVariables>;
export const DeleteBoxOrderDocument = gql`
    mutation DeleteBoxOrder($id: ID!) {
  deleteBoxOrder(id: $id) {
    ok
  }
}
    `;
export type DeleteBoxOrderMutationFn = Apollo.MutationFunction<DeleteBoxOrder, DeleteBoxOrderVariables>;

/**
 * __useDeleteBoxOrder__
 *
 * To run a mutation, you first call `useDeleteBoxOrder` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBoxOrder` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBoxOrder, { data, loading, error }] = useDeleteBoxOrder({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBoxOrder(baseOptions?: Apollo.MutationHookOptions<DeleteBoxOrder, DeleteBoxOrderVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBoxOrder, DeleteBoxOrderVariables>(DeleteBoxOrderDocument, options);
      }
export type DeleteBoxOrderHookResult = ReturnType<typeof useDeleteBoxOrder>;
export type DeleteBoxOrderMutationResult = Apollo.MutationResult<DeleteBoxOrder>;
export type DeleteBoxOrderMutationOptions = Apollo.BaseMutationOptions<DeleteBoxOrder, DeleteBoxOrderVariables>;
export const DeleteSensorFlagDocument = gql`
    mutation DeleteSensorFlag($flag: SensorFlagEnum!, $serialNumber: String!) {
  deleteSensorFlag(flag: $flag, serialNumber: $serialNumber) {
    ok
    error
  }
}
    `;
export type DeleteSensorFlagMutationFn = Apollo.MutationFunction<DeleteSensorFlag, DeleteSensorFlagVariables>;

/**
 * __useDeleteSensorFlag__
 *
 * To run a mutation, you first call `useDeleteSensorFlag` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSensorFlag` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSensorFlag, { data, loading, error }] = useDeleteSensorFlag({
 *   variables: {
 *      flag: // value for 'flag'
 *      serialNumber: // value for 'serialNumber'
 *   },
 * });
 */
export function useDeleteSensorFlag(baseOptions?: Apollo.MutationHookOptions<DeleteSensorFlag, DeleteSensorFlagVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSensorFlag, DeleteSensorFlagVariables>(DeleteSensorFlagDocument, options);
      }
export type DeleteSensorFlagHookResult = ReturnType<typeof useDeleteSensorFlag>;
export type DeleteSensorFlagMutationResult = Apollo.MutationResult<DeleteSensorFlag>;
export type DeleteSensorFlagMutationOptions = Apollo.BaseMutationOptions<DeleteSensorFlag, DeleteSensorFlagVariables>;
export const DistributorDocument = gql`
    query Distributor($distributor: ID!) {
  distributor(id: $distributor) {
    id
    name
    fullName
    createdAt
    updatedAt
    boxOrders(last: 100) {
      edges {
        node {
          id
          boxes {
            id
            name
            createdAt
            allocatedDate
            shippedDate
            location {
              id
              name
            }
            isShipped
            sensors {
              ...SensorInfo
            }
          }
        }
      }
    }
  }
}
    ${SensorInfo}`;

/**
 * __useDistributor__
 *
 * To run a query within a React component, call `useDistributor` and pass it any options that fit your needs.
 * When your component renders, `useDistributor` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributor({
 *   variables: {
 *      distributor: // value for 'distributor'
 *   },
 * });
 */
export function useDistributor(baseOptions: Apollo.QueryHookOptions<Distributor, DistributorVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Distributor, DistributorVariables>(DistributorDocument, options);
      }
export function useDistributorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Distributor, DistributorVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Distributor, DistributorVariables>(DistributorDocument, options);
        }
export type DistributorHookResult = ReturnType<typeof useDistributor>;
export type DistributorLazyQueryHookResult = ReturnType<typeof useDistributorLazyQuery>;
export type DistributorQueryResult = Apollo.QueryResult<Distributor, DistributorVariables>;
export const LastBoxDocument = gql`
    query LastBox {
  allBoxes(last: 1, orderBy: "createdAt") {
    edges {
      node {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
}
    `;

/**
 * __useLastBox__
 *
 * To run a query within a React component, call `useLastBox` and pass it any options that fit your needs.
 * When your component renders, `useLastBox` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastBox({
 *   variables: {
 *   },
 * });
 */
export function useLastBox(baseOptions?: Apollo.QueryHookOptions<LastBox, LastBoxVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastBox, LastBoxVariables>(LastBoxDocument, options);
      }
export function useLastBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastBox, LastBoxVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastBox, LastBoxVariables>(LastBoxDocument, options);
        }
export type LastBoxHookResult = ReturnType<typeof useLastBox>;
export type LastBoxLazyQueryHookResult = ReturnType<typeof useLastBoxLazyQuery>;
export type LastBoxQueryResult = Apollo.QueryResult<LastBox, LastBoxVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    isStaff
    username
    email
    primaryDistributor {
      name
    }
  }
}
    `;

/**
 * __useMe__
 *
 * To run a query within a React component, call `useMe` and pass it any options that fit your needs.
 * When your component renders, `useMe` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMe({
 *   variables: {
 *   },
 * });
 */
export function useMe(baseOptions?: Apollo.QueryHookOptions<Me, MeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Me, MeVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Me, MeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Me, MeVariables>(MeDocument, options);
        }
export type MeHookResult = ReturnType<typeof useMe>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<Me, MeVariables>;
export const NextBoxIdDocument = gql`
    query NextBoxId {
  nextBoxId
}
    `;

/**
 * __useNextBoxId__
 *
 * To run a query within a React component, call `useNextBoxId` and pass it any options that fit your needs.
 * When your component renders, `useNextBoxId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextBoxId({
 *   variables: {
 *   },
 * });
 */
export function useNextBoxId(baseOptions?: Apollo.QueryHookOptions<NextBoxId, NextBoxIdVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NextBoxId, NextBoxIdVariables>(NextBoxIdDocument, options);
      }
export function useNextBoxIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextBoxId, NextBoxIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NextBoxId, NextBoxIdVariables>(NextBoxIdDocument, options);
        }
export type NextBoxIdHookResult = ReturnType<typeof useNextBoxId>;
export type NextBoxIdLazyQueryHookResult = ReturnType<typeof useNextBoxIdLazyQuery>;
export type NextBoxIdQueryResult = Apollo.QueryResult<NextBoxId, NextBoxIdVariables>;
export const PatchBoxDocument = gql`
    mutation PatchBox($boxId: ID!, $input: InputPatchBoxMutation!) {
  patchBox(id: $boxId, input: $input) {
    ok
    error
  }
}
    `;
export type PatchBoxMutationFn = Apollo.MutationFunction<PatchBox, PatchBoxVariables>;

/**
 * __usePatchBox__
 *
 * To run a mutation, you first call `usePatchBox` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchBox` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchBox, { data, loading, error }] = usePatchBox({
 *   variables: {
 *      boxId: // value for 'boxId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatchBox(baseOptions?: Apollo.MutationHookOptions<PatchBox, PatchBoxVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchBox, PatchBoxVariables>(PatchBoxDocument, options);
      }
export type PatchBoxHookResult = ReturnType<typeof usePatchBox>;
export type PatchBoxMutationResult = Apollo.MutationResult<PatchBox>;
export type PatchBoxMutationOptions = Apollo.BaseMutationOptions<PatchBox, PatchBoxVariables>;
export const SensorDocument = gql`
    query Sensor($id: ID!) {
  sensor(id: $id) {
    ...SensorInfo
  }
}
    ${SensorInfo}`;

/**
 * __useSensor__
 *
 * To run a query within a React component, call `useSensor` and pass it any options that fit your needs.
 * When your component renders, `useSensor` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensor({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSensor(baseOptions: Apollo.QueryHookOptions<Sensor, SensorVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Sensor, SensorVariables>(SensorDocument, options);
      }
export function useSensorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Sensor, SensorVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Sensor, SensorVariables>(SensorDocument, options);
        }
export type SensorHookResult = ReturnType<typeof useSensor>;
export type SensorLazyQueryHookResult = ReturnType<typeof useSensorLazyQuery>;
export type SensorQueryResult = Apollo.QueryResult<Sensor, SensorVariables>;
export const SetSensorAsWaitingForCalibrationDocument = gql`
    mutation SetSensorAsWaitingForCalibration($sensor: String!) {
  setSensorAsWaitingForCalibration(sensor: $sensor) {
    ok
  }
}
    `;
export type SetSensorAsWaitingForCalibrationMutationFn = Apollo.MutationFunction<SetSensorAsWaitingForCalibration, SetSensorAsWaitingForCalibrationVariables>;

/**
 * __useSetSensorAsWaitingForCalibration__
 *
 * To run a mutation, you first call `useSetSensorAsWaitingForCalibration` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSensorAsWaitingForCalibration` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSensorAsWaitingForCalibration, { data, loading, error }] = useSetSensorAsWaitingForCalibration({
 *   variables: {
 *      sensor: // value for 'sensor'
 *   },
 * });
 */
export function useSetSensorAsWaitingForCalibration(baseOptions?: Apollo.MutationHookOptions<SetSensorAsWaitingForCalibration, SetSensorAsWaitingForCalibrationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSensorAsWaitingForCalibration, SetSensorAsWaitingForCalibrationVariables>(SetSensorAsWaitingForCalibrationDocument, options);
      }
export type SetSensorAsWaitingForCalibrationHookResult = ReturnType<typeof useSetSensorAsWaitingForCalibration>;
export type SetSensorAsWaitingForCalibrationMutationResult = Apollo.MutationResult<SetSensorAsWaitingForCalibration>;
export type SetSensorAsWaitingForCalibrationMutationOptions = Apollo.BaseMutationOptions<SetSensorAsWaitingForCalibration, SetSensorAsWaitingForCalibrationVariables>;
