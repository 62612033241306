// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowDownsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowDownsvgIcon(props: ArrowDownsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 13 13"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M6.5 9.879c.014 0 .028-.007.042-.008a.703.703 0 00.462-.2l.002-.003 5.329-5.33a.714.714 0 10-1.009-1.008L6.5 8.157 1.674 3.33A.714.714 0 10.665 4.338l5.329 5.329.002.003c.128.128.294.19.462.2.014.002.028.009.042.009z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowDownsvgIcon;
/* prettier-ignore-end */
