import pl from './translations/pl.json'
import en from './translations/en.json'

interface IObjectKeys {
  [key: string]: any
}

const messages: IObjectKeys = {
  pl,
  en,
  'nb-NO': en,
  'nn-NO': en,
  'en-GB': en,
  'en-US': en
}



export default messages;