// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type HealthR03SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function HealthR03SvgIcon(props: HealthR03SvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24.214 16.41"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M23.119 7.12a1.229 1.229 0 00-1.352 1.088c-.356 3.277-2.167 5.747-4.213 5.747s-3.857-2.471-4.213-5.749c-.005-.042-.017-.081-.026-.122.001-.049.004-.097-.001-.147C12.814 3.338 10.016 0 6.661 0S.508 3.338.007 7.936a1.227 1.227 0 102.44.266c.357-3.277 2.168-5.748 4.213-5.748 2.046 0 3.857 2.471 4.213 5.749.005.044.017.084.026.126 0 .047-.004.094.001.142.5 4.6 3.298 7.938 6.653 7.938s6.153-3.338 6.653-7.937a1.226 1.226 0 00-1.087-1.352z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default HealthR03SvgIcon;
/* prettier-ignore-end */
